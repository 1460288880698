import { Pipe, PipeTransform } from '@angular/core';
import { FindValueOperator } from 'rxjs/internal/operators/find';

@Pipe({
  name: 'randompage'
})
export class RandomPagePipe implements PipeTransform {



  transform(value: any, ...args: unknown[]): unknown {
    let pages = [];

    pages.push("http://democompany.com/about");
    pages.push("http://democompany.com");
    pages.push("http://democompany.com/privacy");
    pages.push("http://democompany.com/jobs");
    pages.push("http://democompany.com/jobs/consultant");
    pages.push("http://democompany.com/about/joe smith");
    pages.push("http://democompany.com/about/locations");
    pages.push("http://democompany.com/locations/north america");
    pages.push("http://democompany.com/marketing");
    pages.push("http://democompany.com/marketing/cases");
    pages.push("http://democompany.com/research");
    pages.push("http://democompany.com/research/people");
    pages.push("http://democompany.com/404");
    pages.push("http://democompany.com/history");
    pages.push("http://democompany.com/history/stories");
    pages.push("http://democompany.com/contact");
    pages.push("http://democompany.com/strategy");
    pages.push("http://democompany.com/work");
    pages.push("http://democompany.com/work/consulting");
    pages.push("http://democompany.com/work/advertising");    

    /*
    if (value) {
      if (value.referrer==='') {
        return 'Direct';
      } else if (new URL(value.referrer).hostname === new URL(value.page_url).hostname) {
        return 'Same site';
      } else if (new URL(value.referrer).hostname === 'google.com') {
        return 'Organic';        
      } else if (new URL(value.referrer).hostname === 'www.google.com') {
        return 'Organic';                
      } else if (new URL(value.referrer).hostname === 'search.aol.com') {
        return 'Organic';  
        
      } else {
        //return new URL(value.referrer).hostname;
        return 'Referral';
      }
    } else {
      return 'Direct';
    }
    */
    //return pages[(Math.random()*10-1)+1];
    return pages[Math.floor(Math.random() * 20)];

  }

}
