import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LeadsService } from '@app/_services/leads.service';
import { DataService } from '@app/_services/data.service';
import { map } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";

@Component({
  selector: 'app-snapshot',
  templateUrl: './snapshot.component.html'
})
export class SnapshotComponent {
  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();
  lastRange1;
  lastRange2;
  snapUsers: string;
  showAdvancedDatePicker: boolean;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 3 Month': [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };
  constructor(
    public leadsService: LeadsService,
    public dataService: DataService,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) {

    this.dataService.snapdata = {
      bouncerate: 0,
      bounces1: 0,
      bounces2: 0,
      validBounceValue: false,
      sessiondurationpercent: 0,
      sessionduration2 : 0,
      sessionduration1 : 0,
      validSessionDurationValue: false,
      session_count1 : 0,
      session_count2 : 0,
      sessionspercent : 0,
      validSessionCountValue: false,
      pagespersessionpercent : 0,
      pagespersession1 : 0,
      pagespersession2 : 0,
      validPagesPerSessionValue: false,
      sessions_per_user1 : 0,
      sessions_per_user2 : 0,
      sessionsperuser : 0,
      validSessionsPerUserValue: false,
      pageviewspercent : 0,
      page_count1 : 0,
      page_count2 : 0,
      validPageCountPercentValue: false,
      newuserspercent : 0,
      new_user_count1 : 0,
      new_user_count2 : 0,
      validNewUserPercentValue: false,
      userspercent : 0,
      user_count1 : 0,
      user_count2 : 0,
      validUserPercentValue: false
    }

  }

  ngOnInit() {
    this.dataService.daterangetype = 'Custom';
    this.dataService.compareto = 'None';

    if (!this.dataService.startSnapDate) {

      this.dataService.startSnapDate = moment().subtract(1, 'month');
      this.dataService.endSnapDate = moment();

      this.dataService.selectedSnapDates = { startDate: this.dataService.startSnapDate, endDate: this.dataService.endSnapDate };
    }


    if (!this.dataService.startSnapCompareDate) {

     // this.dataService.startSnapCompareDate = moment().subtract(2, 'months');
      //this.dataService.endSnapCompareDate = moment().subtract(1, 'months');

//      this.dataService.startSnapCompareDate = moment(undefined);
  //    this.dataService.endSnapCompareDate = moment(undefined);

      this.dataService.selectedSnapCompareDates = null;

      //this.dataService.selectedSnapCompareDates = { startDate: this.dataService.startSnapCompareDate, endDate: this.dataService.endSnapCompareDate };



    }




  }


  showForm() {
    if (this.showAdvancedDatePicker) {
      this.showAdvancedDatePicker = false;
    } else {
      this.showAdvancedDatePicker = true;
    }
}

clearClicked(event) {
  this.dataService.selectedSnapCompareDates = null;
  localStorage.removeItem('startSnapCompareDate') ;
  localStorage.removeItem('endSnapCompareDate') ;

}

rangeClicked(event) {
  console.log( event );

  this.lastRange1 = event.label;

}

  datesUpdated(event) {

    if (event.startDate) {

      this.dataService.setSnapDates(event, true);

      //======================= start set the other dates to match =========================//

      this.dataService.setDates();
      this.dataService.setSourceMediumDates(event);

      this.queryOptions.start = new Date(this.dataService.startDate);
      this.queryOptions.end = new Date(this.dataService.endDate);

      this.queryOptions.sortOrder = "page_count";
      this.queryOptions.sortDirection = "desc";

      this.queryOptions.page = 1;
      this.queryOptions.pageSize = 5;

      this.queryOptions.includeISPs = this.dataService.includeISPs;

      this.leadsService.getLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
          map(leads => {
                  this.dataService.companyLeads = leads;
                  this.dataService.loadLeads();

                  this.queryOptions.start = new Date(this.dataService.startSourceMediumDate);
                  this.queryOptions.end = new Date(this.dataService.endSourceMediumDate);

                  this.dataService.loadReferralChart();

                  this.dataService.setReferralDates(event);

                  this.queryOptions.start = new Date(this.dataService.startReferralDate);
                  this.queryOptions.end = new Date(this.dataService.endReferralDate);


                  this.queryOptions.sortOrder = "users";
                  this.queryOptions.sortDirection = "desc";

                  this.queryOptions.page = 1;
                  this.queryOptions.pageSize = 1000;

                  this.dataService.loadSourceMedium();
                  this.dataService.loadWebsiteMetrics();

                  // pages viewed by company
                  this.dataService.setPageReferralDates(event);


                  this.queryOptions.start = new Date(this.dataService.startPageReferralDate);
                  this.queryOptions.end = new Date(this.dataService.endPageReferralDate);

                  this.queryOptions.sortOrder = "page_count";
                  this.queryOptions.sortDirection = "desc";

                  this.queryOptions.page = 1;
                  this.queryOptions.pageSize = 1000;

                  this.dataService.loadCompanyBreakdown();

              }
          ),
          //takeUntil(this.leadSub)
      ).subscribe();

      this.dataService.loadMetrics();

      // change the warm leads dates

      this.dataService.startDate = event.startDate;
      this.dataService.endDate = event.endDate;

      this.dataService.selectedDates = { startDate: this.dataService.startDate, endDate: this.dataService.endDate };

      localStorage.setItem("startDate", this.dataService.startDate);
      localStorage.setItem("endDate", this.dataService.endDate);

      // was a range picked and an end date already picked?

      if (this.dataService.selectedSnapCompareDates) {
        if (this.lastRange1 === 'Last 7 Days') {
          this.dataService.startSnapCompareDate = moment().subtract(13, 'days');
          this.dataService.endSnapCompareDate = moment().subtract(7, 'days');

          localStorage.setItem('startSnapCompareDate', this.dataService.startSnapCompareDate) ;
          localStorage.setItem('endSnapCompareDate', this.dataService.endSnapCompareDate) ;

          this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }

        }


        if (this.lastRange1 === 'Last 30 Days') {
          this.dataService.startSnapCompareDate = moment().subtract(59, 'days');
          this.dataService.endSnapCompareDate = moment().subtract(30, 'days');

          localStorage.setItem('startSnapCompareDate', this.dataService.startSnapCompareDate) ;
          localStorage.setItem('endSnapCompareDate', this.dataService.endSnapCompareDate) ;

          this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }
        }

        if (this.lastRange1 === 'Today') {
          this.dataService.startSnapCompareDate = moment().subtract(1, 'days');
          this.dataService.endSnapCompareDate = moment().subtract(1, 'days');

          localStorage.setItem('startSnapCompareDate', this.dataService.startSnapCompareDate) ;
          localStorage.setItem('endSnapCompareDate', this.dataService.endSnapCompareDate) ;

          this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }
        }

        if (this.lastRange1 === 'Yesterday') {
          this.dataService.startSnapCompareDate = moment().subtract(2, 'days');
          this.dataService.endSnapCompareDate = moment().subtract(2, 'days');

          localStorage.setItem('startSnapCompareDate', this.dataService.startSnapCompareDate) ;
          localStorage.setItem('endSnapCompareDate', this.dataService.endSnapCompareDate) ;

          this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }
        }

        if (this.lastRange1 === 'This Month') {
          var d1 =  this.dataService.startSnapDate.subtract(1, 'month').add(1,'days');
          var d2 = this.dataService.endSnapDate.subtract(1, 'month').add(1,'days');

          var d3 = moment(d1.$d);
          var d4 = moment(d2.$d);

          //this.dataService.startSnapCompareDate = this.dataService.startSnapDate.subtract(1, 'month');
          //this.dataService.endSnapCompareDate = this.dataService.endSnapDate.subtract(2, 'month');

          this.dataService.startSnapCompareDate = d3;
          this.dataService.endSnapCompareDate = d4;

          localStorage.setItem('startSnapCompareDate', this.dataService.startSnapCompareDate) ;
          localStorage.setItem('endSnapCompareDate', this.dataService.endSnapCompareDate) ;

          this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }

        }

        if (this.lastRange1 === 'Last Month') {
          var d1 =  this.dataService.startSnapDate.subtract(1, 'month');
          var d2 = d1.add(1, 'month');

          var d3 = moment(d1.$d).add(1,'days');
          var d4 = moment(d2.$d);

          this.dataService.startSnapCompareDate = d3;
          this.dataService.endSnapCompareDate = d4;

          localStorage.setItem('startSnapCompareDate', this.dataService.startSnapCompareDate) ;
          localStorage.setItem('endSnapCompareDate', this.dataService.endSnapCompareDate) ;

          this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }

        }

        if (this.lastRange1 === 'Last 3 Month') {
          var d1 =  this.dataService.startSnapDate.subtract(3, 'month');
          var d2 = d1.add(3, 'month');

          var d3 = moment(d1.$d).add(1,'days');
          var d4 = moment(d2.$d);

          this.dataService.startSnapCompareDate = d3;
          this.dataService.endSnapCompareDate = d4;

          localStorage.setItem('startSnapCompareDate', this.dataService.startSnapCompareDate) ;
          localStorage.setItem('endSnapCompareDate', this.dataService.endSnapCompareDate) ;

          this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }

        }
      }

      this.lastRange1 = "";
      this.dataService.loadSnap();

    }
  }

  compareDatesUpdated(event) {
    //console.log({ event });


    if (event.startDate) {
      this.dataService.startSnapCompareDate = event.startDate;
      this.dataService.endSnapCompareDate = event.endDate;

      localStorage.setItem('startSnapCompareDate', event.startDate) ;
      localStorage.setItem('endSnapCompareDate', event.endDate) ;

      //this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) }
    } else {
      //this.dataService.selectedSnapCompareDates = null;
    }
    this.dataService.loadSnap();
  }


}
