import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { CompanyLeads, LeadBreakdown } from '@app/_models';
import { LeadViewQueryOptions } from '@app/_models';
import { DateRangeService } from './daterange.service';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})


export class OrgService{
    
    private url = '${baseUrl}/orgs';

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private http: HttpClient, private dateRangeService: DateRangeService){ }

    getOrgs(): Observable<any>{

        this.url = (`${baseUrl}/tracker/orgs`);

        //console.log('getOrgs(): ' + this.url);

        return this.http
            .get<any>(this.url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    //console.error(error);
                    return throwError(error);

                })
            );
    }

    getOrg(id): Observable<any>{

        this.url = (`${baseUrl}/tracker/org/${id}`);

        //console.log('getOrg(): ' + this.url);

        return this.http
            .get<any>(this.url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    //console.error(error);
                    return throwError(error);

                })
            );
    }

    create(params) {
        return this.http.post(`${baseUrl}/tracker/org`, params)
            .pipe(map((org: any) => {
                return org;
            }));
    }    

    update(id, params) {
        return this.http.put(`${baseUrl}/tracker/org/${id}`, params)
            .pipe(map((org: any) => {
                return org;
            }));
    }    

}