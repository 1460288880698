import { Component, Input } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { LeadsService, DataService } from '@app/_services';
import { RandomCompany } from '@app/random-company';
import { RandomCompanyService } from '@app/random-company.service';
import { map } from 'rxjs/operators';

@Component({
    selector: 'journey',
    templateUrl: 'journey.component.html',

  })
  export class JourneyComponent {

    @Input() isDemo: boolean = false
    @Input() selectedRandomCompany: RandomCompany;

    search = new UntypedFormControl();
    sessions: any;
    journey: any;
    showDetails = true;
    session: any;
    showSession =  false;
    itemsControl = new UntypedFormControl();
    itemsControl2 = new UntypedFormControl();
    constructor(
        public dataService: DataService,
        public leadsService: LeadsService,
        public randomService: RandomCompanyService
    ) { 
        
        this.sessions = this.dataService.sessions;
    }

    
    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(200),
        switchMap((res: string) => {
            if (!res) return of(this.sessions);
            res = res.toLowerCase();
            return of(
                this.sessions.filter(x => (x.status.toLowerCase().indexOf(res) >= 0 || x.session_id.toLowerCase().indexOf(res) >= 0))
            );
        })
    );

  
    
    ngOnInit(): void { }
    
    showTheDetails() {
        this.showDetails = !this.showDetails;
        
    }

    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));

    }

      selectionChange(option: any) {
      }

      async itemSelected(session) {
          this.session = session.session_id;

          this.refresh();
      }

      async refresh() {
        await this.leadsService.getJourney(this.dataService.currentOrg.org_id, this.session).pipe(
            map(results => {
        
              this.dataService.journey = results.journey;
              this.showSession = true;
            })
          ).subscribe()
      }

      back() {
          this.showSession = false;
      }

      currentjourney() {
          alert(document.cookie['rr2021sid']);
      }

    isPageReferral(element, index, array) { 
        return (element.page_title === this); 
     } 

    close() {
        this.dataService.dialogRef.close();
    }

}