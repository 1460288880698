

<div class="snapcard snapcard-{{class}} table-card" *ngIf="compareDates">

    <div class="snapcardRow">
        <div class="snapcardTitle"><h3 class="snapcard-title">{{title}}</h3></div>
        <div class="snapcardTooltip">
            <div class="toolTipWrap">
                <span class="ttip" matTooltip="{{ tooltip }}" matTooltipPosition="above"><i class="fa-solid fa-circle-info"></i></span>
            </div>
        </div>
    </div>
    <h3 class="snapcard-foot">{{footer}}</h3>
    <div class="row align-items-center mt-2">
        <div class="col-auto snapcard-arrow-icon" *ngIf="class === 'down'">
            <i class="fa-solid fa-down-long snap-down-arrow"></i>
        </div>
        <div class="col-auto snapcard-arrow-icon" *ngIf="class === 'up'">
            <i class="fa-solid fa-up-long snap-up-arrow"></i>
        </div>        
        <div class="col-auto snapcard-arrow-icon" *ngIf="class === 'upred'">
            <i class="fa-solid fa-up-long snap-up-arrow-red"></i>
        </div>        
        <div class="col-auto snapcard-arrow-icon" *ngIf="class === 'downgreen'">
            <i class="fa-solid fa-down-long snap-down-arrow-green"></i>
        </div>        
        
        <div class="col">
            <h3 class="snapcard-body text-center">{{body}}</h3>
        </div>
    </div>
</div>

<div class="snapcard snapcard table-card" *ngIf="!compareDates">

    <div class="snapcardRow">
        <div class="snapcardTitle"><h3 class="snapcard-title">{{title}}</h3></div>
        <div class="snapcardTooltip">
            <div class="toolTipWrap">
                <span class="ttip" matTooltip="{{ tooltip }}" matTooltipPosition="above"><i class="fa-solid fa-circle-info"></i></span>
            </div>
        </div>
    </div>

    <h3 class="snapcard-foot-large">{{footer2}}</h3>
</div>

