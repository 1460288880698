<div class="dashintro">
    <div class="row justify-content-between align-items-center">
        <div class="col">
            <h2>{{header_company_name}}</h2>
        </div>
        
        <div class="col-auto">
            <div class="dateControl d-flex align-items-center">
            </div>
        </div>
    </div>
</div>
