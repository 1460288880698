import { NgModule } from "@angular/core";
import { CleanReferralPipe } from '@app/pipes/cleanreferral.pipe';

@NgModule({
    imports: [],
    exports: [CleanReferralPipe],
    declarations: [
        CleanReferralPipe
    ]
})
export class CleanReferralPipeModule {

}
