<div class="modal fade campaign-modal auth-modal" tabindex="-1" role="dialog" [ngClass]="{'show': isAuthOpen}" *ngIf="isAuthOpen">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <a class="close-btn" aria-label="Close" (click)="closeModal()">
                <span>Close</span><i class="icon-close fas fa-xmark"></i>
            </a>
            <div class="modal-title">
                Account Authorize
            </div>
            <div class="modal-info">
                Pop-up to sign in via LinkedIn. Disclaimer to opt in to share account information. Use LinkedIn login design for pop-up.
            </div>
            <div class="btn-container">
                <button class="btn-common btn-login" (click)="openStartModal()">Login / Continue</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade campaign-modal start-modal" tabindex="-1" role="dialog" [ngClass]="{'show': isStartOpen}" *ngIf="isStartOpen">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <a class="close-btn" aria-label="Close" (click)="closeModal()">
                <span>Close</span><i class="icon-close fas fa-xmark"></i>
            </a>
            <div class="modal-title">
                Start a {{platformId}} campaign
            </div>
            <div class="modal-info">
                <div class="campaign-info">
                    <label class="campaign-name">Name the campaign</label>
                    <input type="text" class="form-control" name="campaign_name" id="campaign_name" placeholder="Example_Name" [ngClass]="{'required': isNameRequired}" (change)="onChangeHandler($event)" [(ngModel)]="campaignName">
                    <div *ngIf="isNameRequired" class="text-danger">
                        Campaign name is required.
                    </div>

                    <label class="campaign-page">Add a page from your website</label>
                    <input type="text" class="form-control" name="campaign_page" placeholder="https://fifthcolor.com/" [(ngModel)]="pageUrl">
                </div>

                <p class="or-tag">OR</p>

                <div class="campaign-select">
                    <label class="campaign-select-label">Select a page from your website</label>
                    <div class="accountDD dropdown">
                        <button class="aDDbtn aDDbtnBlue btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{selectedPage}}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li *ngFor="let page of pages">
                                <a class="dropdown-item" (click)="changePage(page)">{{page}}</a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="campaign-radio-container">
                    <label class="label-timeframe">Select timeframe</label>
                    <mat-radio-group class="campaign-radio-group" [(ngModel)]="selectedTime">
                        <ng-container *ngFor="let option of timeOptions">
                            <mat-radio-button class="radio-button" [value]="option" [color]="color" (click)="changeTimeOption(option)">
                                {{ option > 0 ? option + ' days' : 'Continuous' }}
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </div>

                <div class="campaign-radio-container" *ngIf="platformId === 'meta'">
                    <label class="label-timeframe">Does your ad promote any of the following?</label>
                    <mat-radio-group class="campaign-radio-group" [(ngModel)]="selectedPromoteSetting">
                        <ng-container *ngFor="let option of promoteSettings">
                            <mat-radio-button class="radio-button" [value]="option" [color]="color" (click)="changePromoteSetting(option)">
                                {{ option }}
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </div>

                <div class="campaign-radio-container">
                    <label class="label-ad">Send my ad to...</label>
                    <mat-radio-group class="campaign-radio-group" [(ngModel)]="selectedSetting">
                        <ng-container *ngFor="let option of sendSettings">
                            <mat-radio-button class="radio-button" [value]="option" [color]="color" (click)="changeSetting(option)">
                                {{ option == 0 ? 'Any company (recommended)' : option == 1 ? 'Any visitor' : 'Custom Settings' }}
                            </mat-radio-button>
                        </ng-container>
                    </mat-radio-group>
                </div>
            </div>
            <p class="disclaimer">Disclaimer</p>
            <div class="btn-container">
                <button class="btn-common btn-start" (click)="startCampaign()">Start</button>
            </div>
        </div>
    </div>
</div>
