export class Lead{
    page_count: number;
    id: number;
    company_id: number;
    name: string;
    session_count: number;
    employees: string;
    revenue: string;
    region: string;
    city: string;
    isisp: number;
    contact_first_name: string;
    contact_last_name: string;
    contact_title: string;
    contact_department: string;
    contact_email: string;
    contact_phone: string;
    street:string;
    pobox: string;
    country: string;
    postal: string;
    
  
}