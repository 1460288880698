import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {

    if (value) {
      
      var value2:any = value;

      value2 = value2*1000;

      var hoursamt = moment.utc(value2).format("HH");
      var hours = moment.utc(value2).format("H") + " hrs";

      var minutesamt = moment.utc(value2).format("mm");
      var minutes = moment.utc(value2).format("m") + " mins";
      
      var secondsamt = moment.utc(value2).format("ss");
      var seconds = moment.utc(value2).format("s") + " secs";

      value = `${hoursamt!='00'?hours:''} ${minutesamt!='00'?minutes:''} ${secondsamt!='00'?seconds:''}`
      
      return value
    } 

  }

}
