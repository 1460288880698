<div class="modal fade upload-modal" tabindex="-1" role="dialog" [ngClass]="{'show': isOpen}" *ngIf="isOpen">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <a class="close-btn" aria-label="Close" (click)="closeModal()">
                <span>Close</span><i class="icon-close fas fa-xmark"></i>
            </a>

            <div class="main-container">
                <div class="upload-btn">
                    <input type="file" (change)="onFileSelected($event)" id="fileInput" multiple>
                    <label for="fileInput" class="btn-common btn-default">Upload a file</label>
                </div>

                <div class="select-pane">
                    <div class="modal-title">
                        Select a file
                    </div>
                    <label class="text-error sub-label" *ngIf="!isFileValidated">Minimum size should be 1200px X 627px</label>
                    <label class="sub-label font-italic font-gray">Image must be a JPG, PNG, or GIF; up to 5GB</label>

                    <div class="select-card row">
                        <div *ngFor="let image of images" class="uploaded-image col-4">
                            <mat-checkbox class="image-check" [color]="color" [checked]="image.checked" (click)="toggleCheckbox(image)"></mat-checkbox>
                            <img [alt]="image.name" [src]="apiUrl + '/show_image?src=' + image.src" (click)="toggleCheckbox(image)" />
                            <button class="icon-remove" (click)="remove(image)">
                                <i class="fas fa-xmark"></i>
                            </button>
                        </div>

                        <div class="empty" *ngIf="images.length === 0">
                            No images found!
                        </div>
                    </div>
                </div>

                <div class="action-pane">
                    <div class="image-pane">
                        <div class="selected-image" *ngFor="let image of selectedImages">
                            <img [alt]="image.name" [src]="apiUrl + '/show_image?src=' + image.src" />
                            <label class="font-light-gray font-italic">{{image.name}}</label>
                        </div>
                    </div>

                    <div class="action-btn" *ngIf="selectedImages.length > 0">
                        <button class="btn-common btn-primary btn-select" (click)="selecteImage()">Select</button>
                    </div>
                </div>
            </div>


        </div>
    </div>
  </div>
