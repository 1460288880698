import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/_services';
import { LeadViewQueryOptions } from '@app/_models/lead-view-query-opts';


@Component({
  selector: 'app-org-map',
  templateUrl: './org-map.component.html'
})
export class OrgMapComponent {

  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

  constructor(public dataService: DataService
  ) {

   this.dataService.loadMetrics();

  }

}
