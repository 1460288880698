<div class="dashintro">  <!-- Move this to component -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				Campaign Creator
                <p class="campaign-name-title" *ngIf="campaignName">{{ "Linkedin: " + campaignName }}</p>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid campaign-container">
    <div class="row" *ngIf="!campaignName">
        <div class="col-lg-4">
            <div class="card">
                <div class="logo-div">
                    <img src="/assets/icons/RR_Linkedin_Icon.svg" alt="Linkedin Logo">
                </div>
                <h4 class="card-title">Linkedin</h4>

                <div [ngClass]="{'hidden': isAdInfoShow}">
                    <div class="budget-info-bar" [ngClass]="{'hidden': !isConnected}">
                        <div>
                            Daily budget: <span class="font-bold">{{ formattedBudget }}</span>
                        </div>
                        <div>
                            Threshold: <span class="font-bold">{{ formattedThreshold }}</span> <button style="border: unset;"><i class="fas fa-gear font-gray"></i></button>
                        </div>
                    </div>

                    <table class="table table-responsive campaign-table" [ngClass]="{'hidden': !isConnected}">
                        <thead>
                            <tr>
                                <td scope="col" class="col-8 font-light-gray">Status</td>
                                <td scope="col" class="font-light-gray">Off/On</td>
                                <td scope="col" class="font-light-gray">Edit</td>
                                <td scope="col" class="font-light-gray">Delete</td>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container>
                                <tr *ngFor="let ad of linkedinAds; index as i">
                                    <td>
                                        <div class="fa-icon">
                                            <i class="fas fa-circle" [ngClass]="{
                                                'font-success': ad['status'] === 'active',
                                                'font-warning': ad['status'] === 'warning',
                                                'font-error': ad['status'] === 'archived',
                                            }"></i>
                                        </div>
                                        <span class="font-bold">{{ ad['name'] }}</span>
                                    </td>
                                    <td class="action">
                                        <div class="form-check form-switch d-flex justify-content-center">
                                            <input class="form-check-input" id="switch-1" type="checkbox" [ngModel]="ad['status'] === 'active'" (ngModelChange)="updateAdStatus(ad, $event)">
                                        </div>
                                    </td>
                                    <td class="action" (click)="editCustomSetting(i)"><i class="font-gray fas fa-gear"></i></td>
                                    <td class="action"><i class="font-gray fas fa-trash-can"></i></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <div class="budget-container px-4" *ngIf="linkedinId" [ngClass]="{'hidden': isConnected}">
                        <p class="font-bold title">Budget</p>
                        <div class="budget-subcontainer mb-3">
                            <p>Threshold amount<span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                            <div class="price-input">
                                <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="linkedinThreshold">
                                <span class="font-gray font-bold">USD</span>
                            </div>
                        </div>
                        <a href="https://www.reportrover.com/support/" class="font-bold font-light-gray font-italic budget-info">
                            How is my budget spent?
                        </a>
                        <div class="budget-subcontainer mt-4">
                            <p>Linkedin maximum daily budget for all companies (pages)
                                <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                            <div class="price-input">
                                <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="linkedinBudget">
                                <span class="font-gray font-bold">USD</span>
                            </div>
                        </div>
                        <p class="font-bold font-light-gray font-italic budget-info">
                            Divided by X number of pages = $00.00 per ad maximum daily budget
                        </p>
                        <div class="btn-campaign">
                            <button class="btn-common btn-link" (click)="storeBudget('linkedin')">Add a campaign</button>
                        </div>
                    </div>

                    <div class="btn-campaign" [ngClass]="{'hidden': !isConnected}">
                        <button class="btn-common btn-link" (click)="storeBudget('linkedin')">+ Add a campaign</button>
                    </div>
                </div>

                <div class="ad-select-pane" [ngClass]="{'hidden': !isAdInfoShow}">
                    <div class="row">
                        <h4 class="font-bold">Add your Linkedin Info</h4>
                        <div class="col-lg-12 company-select">
                            <h5>
                                Select your Ad Account ID<span class="ttip ms-4" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span>
                            </h5>
                            <section class="company-multi-select">
                                <mat-form-field>
                                    <div class="search-icon">
                                        <i class="fas fa-search"></i>
                                    </div>
                                    <mat-select [formControl]="adAccountCtrl" placeholder="Search ad account...">
                                        <ngx-mat-select-search [formControl]="adAccountFilterCtrl" placeholderLabel="Search"></ngx-mat-select-search>
                                        <mat-option *ngFor="let account of filteredAdAccount | async" [value]="account">
                                            {{account.name}}
                                        </mat-option>
                                        <mat-option *ngIf="(filteredAdAccount | async)?.length === 0" disabled>
                                            No options found
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </section>
                        </div>

                        <div class="ad-select-bottom">
                            <div class="btn-save mt-2 mb-4">
                                <button class="btn-common" (click)="saveAdAccountInfo()">Next</button>
                            </div>

                            <div class="font-gray text-center">
                                Need help? <a href="https://www.reportrover.com/support/" target="__blank" class="font-gray">See our Support Page</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="before-login" [ngClass]="{'hidden': linkedinId}">
                    <p class="font-bold text-center mt-4">Ready to start building a campaign?</p>

                    <div class="btn-campaign mt-4">
                        <a href="https://www.reportrover.com/support/" target="__blank" class="btn-common btn-link">Create your Ads Manager Account</a>
                    </div>

                    <div class="font-gray text-center link-div">
                        Already have an account? <a (click)="linkedinLogin()">Link Account</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card">
                <div class="logo-div">
                    <img src="/assets/icons/RR_Meta_Icon.svg" alt="Meta Logo">
                </div>
                <h4 class="card-title">Meta</h4>

                <div class="budget-info-bar" [ngClass]="{'hidden': !fbToken}">
                    <div>
                        Daily budget: <span class="font-bold">$00.00</span>
                    </div>
                    <div>
                        Threshold: <span class="font-bold">$00.00</span> <span class="me-0 ms-0" (click)="openBudgetModal()">
                            <i class="fas fa-gear font-gray" style="cursor: pointer;" ></i>
                        </span>
                    </div>
                </div>

                <table class="table table-responsive campaign-table" [ngClass]="{'hidden': !fbToken}">
                    <thead>
                        <tr>
                            <td scope="col" class="col-8 font-light-gray">Status</td>
                            <td scope="col" class="font-light-gray">Off/On</td>
                            <td scope="col" class="font-light-gray">Edit</td>
                            <td scope="col" class="font-light-gray">Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-circle font-success"></i>
                                    </div>
                                    <span class="font-bold">Rename-ad</span>
                                </td>
                                <td>
                                    <div class="form-check form-switch d-flex justify-content-center">
                                        <input class="form-check-input" type="checkbox" [ngModel]="true" (ngModelChange)="updateAdStatus()">
                                    </div>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-circle font-success"></i>
                                    </div>
                                    <span class="font-bold">Example_name</span>
                                </td>
                                <td>
                                    <div class="form-check form-switch d-flex justify-content-center">
                                        <input class="form-check-input" type="checkbox" [ngModel]="true" (ngModelChange)="updateAdStatus()">
                                    </div>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <div class="btn-campaign" *ngIf="fbToken">
                    <button class="btn-common btn-link" (click)="openStartModal('meta')">Add a campaign</button>
                </div>

                <!-- <div class="budget-container px-4" *ngIf="fbToken">
                    <p class="font-bold title">Budget</p>
                    <div class="budget-subcontainer mb-3">
                        <p>Threshold amount<span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                        <div class="price-input">
                            <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="fbThreshold">
                            <span class="font-gray font-bold">USD</span>
                        </div>
                    </div>
                    <a href="https://www.reportrover.com/support/" class="font-bold font-light-gray font-italic budget-info">
                        How is my budget spent?
                    </a>
                    <div class="budget-subcontainer mt-4">
                        <p>Meta maximum daily budget for all companies (pages)<span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                        <div class="price-input">
                            <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="fbBudget">
                            <span class="font-gray font-bold">USD</span>
                        </div>
                    </div>
                    <p class="font-bold font-light-gray font-italic budget-info">
                        Divided by X number of pages = $00.00 per ad maximum daily budget
                    </p>
                    <div class="btn-campaign">
                        <button class="btn-common btn-link" (click)="openStartModal()">Add a campaign</button>
                    </div>
                </div> -->

                <div class="before-login" [ngClass]="{'hidden': fbToken}">
                    <p class="font-bold text-center mt-4">Ready to start building a campaign?</p>

                    <div class="btn-campaign mt-4">
                        <a href="https://www.reportrover.com/support/" target="__blank" class="btn-common btn-link">Create your Ads Manager Account</a>
                    </div>

                    <div class="font-gray text-center link-div">
                        Already have an account? <a (click)="fbLogin()">Link Account</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="card google-card">
                <div class="logo-div">
                    <img src="/assets/icons/RR_Google_Icon.svg" alt="Google Logo">
                </div>
                <h4 class="card-title">Google</h4>

                <div class="budget-info-bar" style="display: none;">
                    <div>
                        Daily budget: <span class="font-bold">$00.00</span>
                    </div>
                    <div>
                        Threshold: <span class="font-bold">$00.00</span> <i class="fas fa-gear font-gray"></i>
                    </div>
                </div>

                <table class="table table-responsive campaign-table hidden">
                    <thead>
                        <tr>
                            <td scope="col" class="col-8 font-light-gray">Status</td>
                            <td scope="col" class="font-light-gray">Edit</td>
                            <td scope="col" class="font-light-gray">Control</td>
                            <td scope="col" class="font-light-gray">Delete</td>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-circle font-success"></i>
                                    </div>
                                    <span class="font-bold">Rename-ad</span>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-pause"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-circle font-success"></i>
                                    </div>
                                    <span class="font-bold">Example_name</span>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-pause"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-triangle-exclamation font-warning"></i>
                                    </div>
                                    <span class="font-bold">Services-print</span>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-play"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-circle font-success"></i>
                                    </div>
                                    <span class="font-bold">Contact</span>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-pause"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-exclamation font-error"></i>
                                    </div>
                                    <span class="font-bold">Work</span>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-play"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-circle font-success"></i>
                                    </div>
                                    <span class="font-bold">Work-carbliss</span>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-pause"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="fa-icon">
                                        <i class="fas fa-circle font-success"></i>
                                    </div>
                                    <span class="font-bold">Work-miesfelds</span>
                                </td>
                                <td><i class="font-gray fas fa-gear"></i></td>
                                <td><i class="font-gray fas fa-pause"></i></td>
                                <td><i class="font-gray fas fa-trash-can"></i></td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <div class="budget-container" *ngIf="googleAuthId">
                    <p class="font-bold title">Budget</p>
                    <div class="budget-subcontainer mb-3">
                        <p>Threshold amount<span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                        <div class="price-input">
                            <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="googleThreshold">
                            <span class="font-gray font-bold">USD</span>
                        </div>
                    </div>
                    <a href="https://www.reportrover.com/support/" class="font-bold font-light-gray font-italic budget-info">
                        How is my budget spent?
                    </a>
                    <div class="budget-subcontainer mt-4">
                        <p>Google maximum daily budget for all companies (pages)<span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                        <div class="price-input">
                            <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="googleBudget">
                            <span class="font-gray font-bold">USD</span>
                        </div>
                    </div>
                    <p class="font-bold font-light-gray font-italic budget-info">
                        Divided by X number of pages = $00.00 per ad maximum daily budget
                    </p>
                    <div class="btn-campaign">
                        <button class="btn-common btn-link" (click)="storeBudget('google')">Add a campaign</button>
                    </div>
                </div>

                <div class="before-login" [ngClass]="{'hidden': googleAuthId}">
                    <p class="font-bold text-center mt-4">Ready to start building a campaign?</p>

                    <div class="btn-campaign mt-4">
                        <a href="https://www.reportrover.com/support/" target="__blank" class="btn-common btn-link">Create your Ads Manager Account</a>
                    </div>

                    <div class="font-gray text-center link-div">
                        Already have an account? <button class="font-gray" #loginRef>Link Account</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card custom-setting" *ngIf="campaignName">
    <!-- <div class="card custom-setting"> -->
        <h4 class="card-title">Custom Settings</h4>
        <div class="campaign-name-label font-light-gray">{{campaignName ? campaignName : "Example_name"}}</div>
        <div class="target-company-label">Target Company Criteria</div>

        <div class="setting-pane closed" *ngIf="isRevenueSaved">
            <div class="setting-title">
                <div class="title">
                    <h3>Revenue</h3>
                    <button (click)="showRevenue()">
                        <i class="fas fa-gear"></i>
                    </button>
                </div>
                <p class="sub-title">
                    <span *ngFor="let item of selectedRevenues">{{ item }}</span>
                    <span *ngIf="selectedRevenues.length === 0">Any Revenue</span>
                </p>
            </div>
        </div>

        <div class="setting-pane" *ngIf="!isRevenueSaved">
            <div class="setting-title">
                <div class="title">
                    <h3>Revenue</h3>
                    <button (click)="saveRevenue()">Save</button>
                </div>
            </div>
            <div class="setting-content">
                <p>Include companies with the following revenue</p>
                <label class="font-light-gray">Select all that apply</label>
                <section class="revenue-checkbox setting-checkbox">
                    <mat-checkbox *ngFor="let option of revenues" [color]="color" [(ngModel)]="option.checked" (change)="revenueChange()">{{option.name}}</mat-checkbox>
                </section>
            </div>
        </div>

        <div class="setting-pane closed" *ngIf="isEmployeeSaved">
            <div class="setting-title">
                <div class="title">
                    <h3>Number of Employees</h3>
                    <button (click)="showEmployee()">
                        <i class="fas fa-gear"></i>
                    </button>
                </div>
                <p class="sub-title">
                    <span *ngFor="let item of selectedEmployees">{{ item }}</span>
                    <span *ngIf="selectedEmployees.length === 0">Any Employee</span>
                </p>
            </div>
        </div>

        <div class="setting-pane" *ngIf="!isEmployeeSaved">
            <div class="setting-title">
                <div class="title">
                    <h3>Number of employees</h3>
                    <button (click)="saveEmployee()">Save</button>
                </div>
            </div>
            <div class="setting-content">
                <p>Include companies with the following number of employees</p>
                <label class="font-light-gray">Select all that apply</label>
                <section class="employee-checkbox setting-checkbox">
                    <mat-checkbox *ngFor="let option of employees" [color]="color" [(ngModel)]="option.checked" (change)="employeeChange()">{{option.name}}</mat-checkbox>
                </section>
            </div>
        </div>

        <div class="setting-pane closed" *ngIf="isIndustrySaved">
            <div class="setting-title">
                <div class="title">
                    <h3>Industry</h3>
                    <button (click)="showIndustry()">
                        <i class="fas fa-gear"></i>
                    </button>
                </div>
                <p class="sub-title">
                    <span *ngFor="let item of industryMultiCtrl.value">{{ item.name }}</span>
                    <span *ngIf="industryMultiCtrl.value.length === 0">Any Industry</span>
                </p>
            </div>
        </div>

        <div class="setting-pane" *ngIf="!isIndustrySaved">
            <div class="setting-title">
                <div class="title">
                    <h3>Industry</h3>
                    <button (click)="saveIndustry()">Save</button>
                </div>
            </div>
            <div class="setting-content">
                <div class="label-ad">
                    <label>Include companies with in the following industries</label>
                    <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
                </div>
                <label class="font-light-gray">Select all that apply</label>

                <section class="industry-multi-select">
                    <mat-form-field>
                        <div class="search-icon">
                            <i class="fas fa-search"></i>
                        </div>
                        <mat-select [formControl]="industryMultiCtrl" placeholder="Search industries" [multiple]="true" #multiSelect>
                            <ngx-mat-select-search [formControl]="industryMultiFilterCtrl" placeholderLabel="Search"></ngx-mat-select-search>
                            <mat-option *ngFor="let industry of filteredIndustriesMulti | async" [value]="industry">
                                {{industry.name}}
                            </mat-option>
                            <mat-option *ngIf="(filteredIndustriesMulti | async)?.length === 0" disabled>
                                No options found
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </section>

                <!-- <section class="industry-checkbox setting-checkbox">
                    <mat-checkbox [color]="color">Industry1</mat-checkbox>
                    <mat-checkbox [color]="color">Industry2</mat-checkbox>
                    <mat-checkbox [color]="color">Industry3</mat-checkbox>
                    <mat-checkbox [color]="color">Industry4</mat-checkbox>
                    <mat-checkbox [color]="color">Industry5</mat-checkbox>
                    <mat-checkbox [color]="color">Industry6</mat-checkbox>
                </section> -->
            </div>
        </div>

        <div class="setting-radio-container">
            <div class="label-ad">
                <label>Launch ad following approval (select one)</label>
                <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray"></i></span>
            </div>
            <mat-radio-group class="setting-radio-group" [(ngModel)]="selectedAdSetting">
                <ng-container *ngFor="let option of adSettings">
                    <mat-radio-button class="radio-button" [value]="option" [color]="color" (click)="changeSetting(option)">
                        {{ option == 0 ? 'Automatically' : 'After an email approval' }}
                    </mat-radio-button>
                </ng-container>
            </mat-radio-group>
        </div>

        <div class="btn-container">
            <button class="btn-common btn-back btn-default" (click)="openStartModal();">Back</button>
            <button class="btn-common btn-save btn-primary" (click)="saveSetting()">Save</button>
        </div>
    </div>

    <div class="row" *ngIf="selectedSetting == 0">
    </div>
</div>

<app-authorization-modal [(isStartOpen)]="isStartModalOpen" [(platformId)]="platformId" (isStartOpenChange)="onStartOpenChange($event)"></app-authorization-modal>

<app-budget-modal [(isBudgetModalOpen)]="isBudgetOpen" (isBudgetModalOpenChange)="onBudgetModalOpenChange($event)"></app-budget-modal>
