import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { PageVisits } from "@app/_models";
import { LeadsService } from "@app/_services/leads.service";
import { DataService } from "@app/_services/data.service";
import { AccountService } from "@app/_services/account.service";
import { CookieService } from "ngx-cookie-service";
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { fromEvent } from "rxjs";
@Component({
  selector: "app-company-breakdown",
  templateUrl: "./company-breakdown.component.html"
})

export class CompanyBreakdownComponent implements OnInit, AfterViewInit {
  loading = false;
  pageVisits: PageVisits[];
  itemsperpage: number;
  currentpage: number;
  totalrecords: number;
  pageTitle: string;
  referrals: any;
  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();
  searchValue = "";

  @ViewChild("searchInput") searchElementRef: ElementRef;

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ],
    "Last 3 Month": [
      moment()
        .subtract(3, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ]
  };

constructor(
  private leadsService: LeadsService,
  public dataService: DataService,
  private accountService: AccountService,
  private cookieService: CookieService,
  public dialog: MatDialog,
  private elRef: ElementRef
) {

  this.queryOptions.page = 1;
  this.queryOptions.pageSize = 10000;
  this.queryOptions.sortDirection = "desc";

}

ngOnInit(): void {
  this.itemsperpage = 10;
  this.currentpage = 1;
  this.queryOptions.sortOrder = "users";

  if (!this.dataService.startReferralDate) {

    this.dataService.startReferralDate = moment().subtract(6, "days");
    this.dataService.endReferralDate = moment();

    this.dataService.selectedReferralDates = { startDate: this.dataService.startReferralDate, endDate: this.dataService.endReferralDate };
  }


  this.queryOptions.sortDirection = "desc";
  this.queryOptions.start = new Date(this.dataService.startReferralDate);
  this.queryOptions.end = new Date(this.dataService.endReferralDate);


  // get the referrals

  this.dataService.loadSourceMedium();
  this.dataService.loadWebsiteMetrics();

  this.queryOptions.sortDirection = "desc";
// get the breakdown

//    this.dataService.loadCompanyBreakdown();
}

ngAfterViewInit(): void {
  var that = this;
  var term: string;
  var objValue: string;

  fromEvent(this.searchElementRef.nativeElement, "input").subscribe(
    (x) => {
      this.dataService.sourceMediumResults = Array.from(this.dataService.sourceMediumResultsOrig);
      this.dataService.sourceMediumResults = this.dataService.sourceMediumResults.filter((obj)=> {
          return Object.keys(obj).reduce((acc, curr)=> {
              term = this.searchValue.toLowerCase();

              if (!obj[curr]) {
                obj[curr] = "";
              }

              if (curr === "bounces" || curr === "count" || curr === "sessions" || curr === "users" || curr === "newusers") {
                  objValue = obj[curr].toString();
                  return acc || objValue.includes(term);
              } else {
                  objValue = obj[curr].toLowerCase();
                  return acc || objValue.includes(term);
              }

          }, false);
      });
    }
  );
}



  delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  handlePageChange(event) {
   // this.queryOptions.page = event;
    // this.queryOptions.pageSize = this.pageSize;

    // this.refreshLeads(this.queryOptions);
    this.currentpage = event;
  }

  /*
  oldshowReferrals(pageTitle) {

    this.pageTitle = pageTitle;
    this.referrals = this.dataService.referrals.filter(this.isPageReferral, pageTitle);


   var x = document.getElementById("referralSpan"+pageTitle);
   if (x.style.display === "none") {
     x.style.display = "block";
   } else {
     x.style.display = "none";
   }
  }

  isPageReferral(element, index, array) {
    return (element.page_title === this);
 }
*/

/*
 async showCompaniesJourney(page) {
  //debugger;
  this.loading = true;

  if (this.dataService.currentOrg.org_id==='demo') {
    this.dataService.pageUrl = page.pageurl2;
  } else {
    this.dataService.pageUrl = page.pageurl;
  }

  this.queryOptions.start = new Date(this.dataService.startDate);
  this.queryOptions.end = new Date(this.dataService.endDate);

  await this.leadsService.getCompaniesJourney(this.dataService.currentOrg.org_id, page.pageurl, this.queryOptions).pipe(
    map(metrics => {
      this.dataService.journies = metrics.metrics;
      this.loading = false;
      this.dataService.dialogRef = this.dialog.open(CompanyJourneyComponent, {
          height: '90%',
          width: '90%',
      });
    })
  ).subscribe()


  this.dataService.dialogRef.afterClosed().subscribe(result => {

  });



 }
*/
 datesUpdated(event) {

      if (event.startDate) {

        this.dataService.setReferralDates(event);


        this.queryOptions.start = new Date(this.dataService.startReferralDate);
        this.queryOptions.end = new Date(this.dataService.endReferralDate);

        this.queryOptions.sortOrder = "users";
        this.queryOptions.sortDirection = "desc";

        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 1000;

        this.dataService.loadSourceMedium();
        this.dataService.loadWebsiteMetrics();

        // set referral chart
        this.dataService.setSourceMediumDates(event);

        this.queryOptions.start = new Date(this.dataService.startSourceMediumDate);
        this.queryOptions.end = new Date(this.dataService.endSourceMediumDate);

        this.dataService.loadReferralChart();

        this.dataService.setSnapDates(event, true);
        this.dataService.setPageReferralDates(event);
        this.dataService.setDates();

         // pages viewed by company
         this.queryOptions.start = new Date(this.dataService.startPageReferralDate);
         this.queryOptions.end = new Date(this.dataService.endPageReferralDate);

         this.queryOptions.sortOrder = "page_count";
         this.queryOptions.sortDirection = "desc";

         this.queryOptions.page = 1;
         this.queryOptions.pageSize = 1000;

         this.dataService.loadCompanyBreakdown();





      }


 }


}
