import { Component, OnInit, Input } from '@angular/core';
import { CompanyInfo } from '@app/_models';
import { DataService } from '@app/_services';
import { LeadsService } from '../../_services/leads.service';
import { AccountService } from '../../_services';
import { LeadViewQueryOptions } from '../../_models';
import moment, { Moment } from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { RandomCompanyService } from '@app/random-company.service';
import { CompanyLeads } from '../../_models';
import { Observable, Subject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { element } from 'protractor';

@Component({
  selector: 'app-lead-breakdown',
  templateUrl: './lead-breakdown.component.html'
})
export class LeadBreakdownComponent implements OnInit {


  constructor(
    public dataService: DataService,
    private cookieService: CookieService,
    public randomService: RandomCompanyService,
    private accountService: AccountService,
    private leadsService: LeadsService,

  ) {

  }
  loading = false;
  itemsperpage: number;
  currentpage: number;
  totalrecords: number;

  account = this.accountService.accountValue;
  companyLeads: CompanyLeads;
  pageSize = 25;
  page = 1;
  totalPages: number;
  offset: number;
  companyLeads$: Observable<CompanyLeads>;

  searchValue = '';

  private leadSub = new Subject();

  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 3 Month': [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
};

ngOnInit(): void {
    this.currentpage = 1;
    this.itemsperpage = 25;

    this.queryOptions.start = new Date(this.dataService.startDate);
    this.queryOptions.end = new Date(this.dataService.endDate);

    let term: string;
    let objValue: string;

    // check localStorage 
    this.dataService.lead = JSON.parse(localStorage.getItem('lead'));

    this.loadSessions();
    
    document.getElementById('searchInput2').addEventListener('input', (e) => {

      this.dataService.sessions = Array.from(this.dataService.sessionsOrig);
      this.dataService.sessions = this.dataService.sessions.filter((obj)=>{
        return Object.keys(obj).reduce((acc, curr)=>{
            term = e.currentTarget['value'].toLowerCase();

            if (!obj[curr]) {
              obj[curr] = '';
            }

            if (curr === 'session_start' || curr === 'session_end' || curr==='page_visits') {
                objValue = obj[curr].toString();
                return acc || objValue.includes(term);
            } else {
                objValue = obj[curr].toLowerCase();
                return acc || objValue.includes(term);
            }

        }, false);
    });
  });

  // this.getRandomCompanies();



}

  loadSessions() {
    this.queryOptions.sortOrder = 'session_start';
    this.queryOptions.sortDirection = 'asc';
    this.queryOptions.pageSize = 10000;

    this.leadsService.getSessionsJourney(this.dataService.currentOrg.org_id, this.dataService.lead.id, this.queryOptions).pipe(
      map(sessions => {

        this.dataService.sessions = sessions.sessions.filter(session => session.page_visits !== 0); // Filter out sessions with page_visits equal to 0
        this.dataService.sessionsOrig = Array.from(this.dataService.sessions);
      }),
      takeUntil(this.leadSub)
    ).subscribe();




    this.queryOptions.pageSize = 10000;
  }



  toggleDisplay(i, session) {
    debugger;
    const ele = document.getElementById(`row${i}`) as HTMLInputElement;
    const ele2 = document.getElementById(`${i}_arrow`) as HTMLInputElement;
    if (ele.style.display === '') {
     ele.style.display = 'none';
     ele2.className = 'arrow-down';
    } else {
     ele.style.display = '';
     ele2.className = 'arrow-up';
     this.getSessionDetails(i, ele, session);

   }

 }

 getSessionDetails(i, ele, session){
   // ele.innerHTML = `<table border=1><tr><td>${i}</td></tr></table>`
   var retVal = '';

   this.leadsService.getEventsBySession(i).pipe(
     map(data => {

       retVal =  `<td colspan=5><table class="table table-yellow" cellspacing="0">`;
       retVal =  retVal + `<tr class="session-journey-sub"><td>Page Start</td><td>Page Duration</td><td>Page Visited</td></tr>`;
 
       var counter = 0;
       var date1;
       var date2;
       data.events.forEach(element => {
         counter++;
          const tdate = new Date(element.timestamp);

          element.timestamp =  moment(tdate).format('MMM D, YYYY, h:mm:ss A');
          debugger;
          var value2:any;

          


          if (data.events[counter]) {         

            date1 = new Date(data.events[counter].timestamp);
            date2 = new Date(element.timestamp);

            //date1 = moment(date1);
            //date2 = moment(date2);

            value2 = (date1- date2)/1000;
          }  else {
            //value2 = new Date(session.enddate).toISOString().getSeconds() - new Date(element.timestamp).getSeconds();
            date1 = new Date(session.session_end);
            date2 = new Date(element.timestamp);
            
            //date1 = moment(date1);
            //date2 = moment(date2);

            value2 = (date1- date2)/1000;
          }
          
          value2 = value2*1000;

          var hoursamt = moment.utc(value2).format("HH");
          var hours = moment.utc(value2).format("H") + " hrs";
    
          var minutesamt = moment.utc(value2).format("mm");
          var minutes = moment.utc(value2).format("m") + " mins";
          
          var secondsamt = moment.utc(value2).format("ss");
          var seconds = moment.utc(value2).format("s") + " secs";
    
          element.duration = `${hoursamt!='00'?hours:''} ${minutesamt!='00'?minutes:''} ${secondsamt!='00'?seconds:''}`

          

         retVal = retVal + `<tr><td class="col-2">${element.timestamp}</td><td class="col-2">${element.duration}</td><td class="col-4" style="text-align: left">${element.page_title}<br/><a href="${element.page_url}">${element.page_url}</a></td></tr>`;
       });

       retVal = retVal + `</table></td>`;


       ele.innerHTML = retVal;

     }),
     takeUntil(this.leadSub)
   ).subscribe();

   

 }


  handlePageChange(event) {
    this.queryOptions.page = event;
    this.queryOptions.pageSize = this.pageSize;
    this.currentpage = event;
    // this.loadSessionsrefreshLeads(this.queryOptions.sortOrder);
  }

itemsPerPageChange(event) {
    this.itemsperpage = event;
    this.queryOptions.page = 1;
    this.loadSessions();
  }

sortLeads(sortOrder) {
    // is this a new sort order?
    if (this.queryOptions.sortOrder === sortOrder) {
       if (this.queryOptions.sortDirection === 'asc') {
           this.queryOptions.sortDirection = 'desc';
       } else {
           this.queryOptions.sortDirection = 'asc';
       }
     } else {
         this.queryOptions.sortDirection = 'desc';
     }
    this.queryOptions.sortOrder = sortOrder;
/*
    $(`#company_employees_sort`).removeClass('btn-sort');
    $(`#company_revenue_sort`).removeClass('btn-sort');
    $(`#page_count_sort`).removeClass('btn-sort');
    $(`#session_count_sort`).removeClass('btn-sort');

    $(`#company_employees_sort`).removeClass('btn-sort-asc');
    $(`#company_revenue_sort`).removeClass('btn-sort-asc');
    $(`#page_count_sort`).removeClass('btn-sort-asc');
    $(`#session_count_sort`).removeClass('btn-sort-asc');

     // $(`#company_employees_sort`).addClass('btn-hollow');
     // $(`#company_revenue_sort`).addClass('btn-hollow');
     // $(`#page_count_sort`).addClass('btn-hollow');
     // $(`#session_count_sort`).addClass('btn-hollow');
    $('.sort-active').removeClass('sort-active');

    if (this.queryOptions.sortDirection === 'desc') {
         $(`#${sortOrder}_sort`).addClass('btn-sort');
         $(`#${sortOrder}_sort`).addClass('sort-active');
         if (sortOrder === 'company_employees' || sortOrder === 'company_revenue') {
           this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(' ')[0].replace(',', '')) < parseInt(b[sortOrder].split(' ')[0].replace(',', ''))) ? 1 : -1);
         } else {
           this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] < b[sortOrder]) ? 1 : -1);
         }

     } else {
         $(`#${sortOrder}_sort`).addClass('btn-sort-asc');
         $(`#${sortOrder}_sort`).addClass('sort-active');
         if (sortOrder === 'company_employees' || sortOrder === 'company_revenue') {
           this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(' ')[0].replace(',', '')) > parseInt(b[sortOrder].split(' ')[0].replace(',', ''))) ? 1 : -1);
         } else {
           this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] > b[sortOrder]) ? 1 : -1);
         }
     }
    $(`#${sortOrder}_sort`).removeClass('btn-hollow');
*/

   }

datesUpdated(event) {
    // console.log({ event });

    if (event.startDate) {

        this.dataService.startDate = event.startDate;
        this.dataService.endDate = event.endDate;

        localStorage.setItem('startDate', event.startDate) ;
        localStorage.setItem('endDate', event.endDate) ;

        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);

        this.queryOptions.sortOrder = 'page_count';
        this.queryOptions.sortDirection = 'desc';

        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 10;

        this.loadSessions();


    }
//        localStorage.setItem('selectedDates', this.selectedDates);

}
}
