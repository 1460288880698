import { Pipe, PipeTransform } from '@angular/core';
import { CompanyLeads, Lead } from './_models';

@Pipe({
  name: 'leadSort'
})
export class LeadSortPipe implements PipeTransform {

  transform(value: Lead[], args: string): Lead[] {
    /* Only process if we have records */
    if(value && value.length > 0){

      return value.sort((a: Lead, b: Lead) => {
        
        if(a[args] > b[args]){
          return -1;
        }else if(b[args] > a[args]){
          return 1;
        }else{
          return 0;
        }
      });
    }

    return [];
  }
}
