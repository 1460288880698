import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";
import { registerLicense } from "@syncfusion/ej2-base";


// Registering Syncfusion license key
//registerLicense('ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0VhUX5Xc3dUQGNUVUQ=');
//registerLicense('@32312e312e3335d57VbVUEdALj9KjUymmcgvgUEThz9aRkLSd57y5JaBU=');
registerLicense("Mgo+DSMBaFt+QHFqVk9rXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQlliTX9Rc0RiW3lfdHA=;Mgo+DSMBPh8sVXJ1S0d+X1hPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSXpSckRhXH5fcnJWQGQ=;ORg4AjUWIQA/Gnt2VFhhQlJNfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0FiW3pfcXZTQ2VY;MTcyODQ2NEAzMjMxMmUzMTJlMzMzOUhsQytibmZSczBmcTNaUnNtN0RMTXVhaVF4Z0NEUVFHV2NYYjI2UmNtY3M9;MTcyODQ2NUAzMjMxMmUzMTJlMzMzOVJIc1FycHpCTmpHamNIaW1TSHhiaVVJMjhpWFF0V3hVYlRRODRxTGNRK009;NRAiBiAaIQQuGjN/V0d+XU9Hf1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TckRnWH1bcXVXQWNYVQ==;MTcyODQ2N0AzMjMxMmUzMTJlMzMzOVJHbkg5UWhSNm9OMFB0YjV6bGVyeEdUVlZkemZFWnQyNjB6cVN1K20wYWs9;MTcyODQ2OEAzMjMxMmUzMTJlMzMzOUM4ck1vWlltTGFQWVJabXpiOHFhL2dEQm8zVTRnR0RqOFhkcWhEU1kweVk9;Mgo+DSMBMAY9C3t2VFhhQlJNfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5Xd0FiW3pfcXZdRGVY;MTcyODQ3MEAzMjMxMmUzMTJlMzMzOU9ZVDlGY2tYSVAwYSs3QVRhYkxEdzNxMUUvSWx0Qkk3WkVDcVo3ZUcxTlE9;MTcyODQ3MUAzMjMxMmUzMTJlMzMzOWRsQVlHbVhPbTU1VVovSEV1RWVyNXorQ1pCNmpxTm44Z1VPL1lvbnNIKzA9;MTcyODQ3MkAzMjMxMmUzMTJlMzMzOVJHbkg5UWhSNm9OMFB0YjV6bGVyeEdUVlZkemZFWnQyNjB6cVN1K20wYWs9");

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
