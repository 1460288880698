import { Component, Input } from '@angular/core';

@Component({
  selector: 'dash-intro',
  templateUrl: './dash-intro.component.html',
  styleUrls: ['./dash-intro.component.scss']
})
export class DashIntroComponent {
  @Input() header_company_name: string;
}
