import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-snaptile',
  templateUrl: './snaptile.component.html'
})
export class SnaptileComponent {
  @Input() title: string;
  @Input() class: string;
  @Input() body: string;
  @Input() footer: string;
  @Input() footer2: string;
  @Input() tooltip: string;
  @Input() compareDates: string;
}
