<div >

  <div class="row">
    <div class="col-10" >
      <mat-form-field style="width:90%" autocomplete="off" *ngIf="!showBreakdown">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" autocomplete="off" type="search">
      </mat-form-field>
      <!--<mat-form-field style="width:90%" autocomplete="off" *ngIf="showBreakdown">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search2" autocomplete="off" type="search">
      </mat-form-field>-->      
    </div>
    <div class="col-2">
      <div class="cursorPointer" (click)="close()">
        <i class='fas fa-window-close' style='font-size:24px;color:red; float: right;'></i>
      </div>
    </div>

  
  </div>



  <div class="row" *ngIf="!showBreakdown">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h2 mat-dialog-title>Companies - {{dataService.pageUrl}}</h2>
        <mat-selection-list #items [multiple]="false">
          <div class="row">
            <div class="col-3">Company</div>
            
            <div class="col-2">View Count</div>
            <div class="col-2">Page Visits</div>
            <div class="col-2">Avg Duration</div>
            <div class="col-2">Last Activity</div>
            <div class="col-1"></div>
          </div>
          <hr>
          <mat-list-option class="popRow" *ngFor="let item of $search|async"  [value]="item" [selected]="itemsControl.value &&itemsControl.value?.indexOf(item)>=0" style="height: auto">
              <div class="row">
                <div class="col-3">{{item['name']}}</div>
                <div class="col-2">{{item['page_count']}}</div>
                <div class="col-2">{{item['page_visits']}}</div>
                <div class="col-2">{{item['duration']/item['page_count'] | duration}}</div>
                <div class="col-2">{{item['last_activity'] | date: 'short'}}</div>
                <div class="col-1">
                  <a (click)="showJourneyBreakdown(item)" class="content_img" data-toggle="tooltip" title="Company Journey Breakdown">
                      <i class='fa fa-route' style='font-size:20px;color:gray' ></i>
                  </a>
                </div>
              </div>

          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>
  </div>

  <div class="row" *ngIf="showBreakdown">
    <!--{{dataService.currentCompanyJourney.company_id}}<br>
    {{dataService.companyJourneyData.page_url}}-->

    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <div class="row">
          <div class="col-8">
            <h2 mat-dialog-title>{{dataService.pageUrl}}</h2>
          </div>
          <div class="col-2">
            <a class="content_img" data-toggle="tooltip" title="Details">
              <div class="cursorPointer" (click)="showTheDetails()" *ngIf="!showDetails">
                <i class='fa fa-eye' style='font-size:16px;color:gray; float: right'></i>
              </div>
              <div class="cursorPointer" (click)="showTheDetails()" *ngIf="showDetails">
                <i class='fa fa-eye' style='font-size:16px;color:blue; float: right'></i>
              </div>
            </a> 
          </div>
          <div class="col-2">
          </div>
        </div>

        <mat-selection-list dense #items2 [multiple]="false">
          <div class="row">
            <div class="col-10">Referrer</div>
            <div class="col-2">Time</div>
          </div>
          <hr>
          <mat-list-option class="popRow" *ngFor="let item of $search2|async"  [value]="item" [selected]="items2Control.value &&items2Control.value?.indexOf(item)>=0" style="height: auto">
            <div class="row">
              <div class="col-10">
                <h4><strong>Referrer:</strong><br>{{item['referrer']}}</h4>
              </div>
              <div class="col-2">
                <h4><strong>Time:</strong><br>{{item['timestamp'] | date: 'short'}}</h4>
              </div>
          
              <div class="col-12" *ngIf="showDetails">
                <div class="row">
                  <div class="col" *ngIf="item['params'][0]">
                    <p><strong>Source:</strong><br>{{item['params'][0].split('=')[1]}}</p>
                  </div> 
                  <div class="col" *ngIf="item['params'][1]">
                    <p><strong>Medium:</strong><br>{{item['params'][1].split('=')[1]}}</p>
                  </div>
                  <div class="col" *ngIf="item['params'][2]">
                    <p><strong>Campaign:</strong><br>{{item['params'][2].split('=')[1]}}</p>
                  </div>
                  <div class="col" *ngIf="item['params'][3]">
                    <p><strong>ID:</strong><br>{{item['params'][3].split('=')[1]}}</p>
                  </div>
                  <div class="col" *ngIf="item['params'][4]">
                    <p><strong>Term:</strong><br>{{item['params'][4].split('=')[1]}}</p>
                  </div>
                  <div class="col" *ngIf="item['params'][5]">
                    <p><strong>GCL ID:</strong><br>{{item['params'][5].split('=')[1]}}</p>
                  </div>
                </div>
              </div>
              <!-- <div class="col-12"><hr></div> -->
            </div>


          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>    
  </div>

  


</div>
