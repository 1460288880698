import { Component } from '@angular/core';
import { UntypedFormControl } from "@angular/forms";
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { LeadsService, DataService } from '@app/_services';
import { map } from 'rxjs/operators';
import { DurationPipeModule } from '@app/pipes/durationpipe.module';
@Component({
    selector: 'companyjourney',
    templateUrl: 'companyjourney.component.html',

  })
  export class CompanyJourneyComponent {
    search = new UntypedFormControl();
    search2 = new UntypedFormControl();
    journies: any;
    urls: any;
    journey: any;
    showBreakdown = false;
    showDetails = true;
    session: any;
    showSession =  false;
    itemsControl = new UntypedFormControl();
    items2Control = new UntypedFormControl();
    constructor(
        public dataService: DataService,
        public leadsService: LeadsService
    ) { 
        
        this.journies = this.dataService.journies;
    }

    
    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(200),
        switchMap((res: string) => {
            if (!res) return of(this.journies);
            res = res.toLowerCase();
            return of(
                this.journies.filter(x => (x.name.toLowerCase().indexOf(res) >= 0))
            );
        })
    );

    $search2 = this.search2.valueChanges.pipe(
        startWith(null),
        debounceTime(200),
        switchMap((res: string) => {
            if (!res) return of(this.urls);
            res = res.toLowerCase();
            return of(
                this.urls.filter(x => (x.page_url.toLowerCase().indexOf(res) >= 0))
            );
        })
    );

    
    ngOnInit(): void {
        

    }

    async showJourneyBreakdown(compJourney) {


        this.dataService.currentCompanyJourney = compJourney;

        var pageUrlArr;
        var pageurl;

        var params;


        pageUrlArr = compJourney.page_url.split("/?");
        pageurl = pageUrlArr[0];







    await this.leadsService.getCompanyJourneyBreakdown(this.dataService.currentOrg.org_id, pageurl, compJourney.company_id, this.dataService.queryOptions).pipe(
        map(results => {
            //debugger;
            //this.dataService.companyJourneyData = results.metrics;
            this.urls = results.metrics;

            // split the parameters
            this.urls.forEach(element => {
                params = element.page_url.split('/?');
                if (params[1]) {
                    params = params[1].split('&');
                    //   params.forEach(ele => {
                    //     ele = ele.split('=')[0];
                    //})
                    element.params = params;
                } else {
                    element.params = [];
                }
                
            });

            this.showBreakdown = true;
        })
        ).subscribe()

    }



    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));

    }

      selectionChange(option: any) {

        //let value = this.itemsControl.value || [];
        //if (option.selected) value.push(option.value);
        //else value = value.filter((x: any) => x != option.value);
        //this.itemsControl.setValue(value);
      }

      async itemSelected(session) {
          this.session = session.session_id;

          this.refresh();
      }

      async refresh() {
        await this.leadsService.getJourney(this.dataService.currentOrg.org_id, this.session).pipe(
            map(results => {
              this.dataService.journey = results.journey;
              this.showSession = true;
            })
          ).subscribe()
      }

      back() {
          this.showSession = false;
      }

      currentjourney() {
          //alert(document.cookie['rr2021sid']);
      }

    isPageReferral(element, index, array) { 
        return (element.page_title === this); 
     } 

    close() {
        this.dataService.dialogRef.close();
    }

    showTheDetails() {
        this.showDetails = !this.showDetails;
    }

}