import { RandomCompany } from "./random-company";

export const RandomCompanies: RandomCompany[] =
[ 
{ name: 'Broadcom', city: 'San Jose', state: 'California' },
{ name: 'ASML', city: 'Veldhoven', state: 'Netherlands' },
{ name: 'ICBC', city: 'Vancouver', state: 'British Columbia' },
{ name: 'Toyota', city: 'Plano', state: 'Texas' },
{ name: 'Walt Disney', city: 'Orlando', state: 'Florida' },
{ name: 'Astrazeneca', city: 'Wilmington', state: 'Delaware' },
{ name: 'Danaher', city: 'Washington', state: 'D.C.' },
{ name: 'Oracle', city: 'Austin', state: 'Texas' },
{ name: 'Cisco', city: 'San Jose', state: 'California' },
{ name: 'CATL', city: 'Arnstadt', state: 'Germany' },
{ name: 'Accenture', city: 'Dublin', state: 'Ireland' },
{ name: 'McDonalds', city: 'Chicago', state: 'Illinois' },
{ name: 'Shell', city: 'Houston', state: 'Texas' },
{ name: 'LOréal', city: 'New York', state: 'New York' },
{ name: 'Adobe', city: 'San Jose', state: 'California' },
{ name: 'Abbott Laboratories', city: 'Chicago', state: 'Illinois' },
{ name: 'Verizon', city: 'New York', state: 'New York' },
{ name: 'Novartis', city: 'Basel', state: 'Switzerland' },
{ name: 'T-Mobile US', city: 'Bellevue', state: 'Washington' },
{ name: 'United Parcel Service', city: 'Atlanta', state: 'Georgia' },
{ name: 'Salesforce', city: 'San Francisco', state: 'California' },
{ name: 'Nextera Energy', city: 'Juno Beach', state: 'Florida' },
{ name: 'Nike', city: 'Beaverton', state: 'Oregon' },
{ name: 'Wells Fargo', city: 'San Francisco', state: 'California' },
{ name: 'Comcast', city: 'Philadelphia', state: 'Pennslyvania' },
{ name: 'QUALCOMM', city: 'San Diego', state: 'California' },
{ name: 'Bristol-Myers Squibb', city: 'New York', state: 'New York' },
{ name: 'Texas Instruments', city: 'Dallas', state: 'Texas' },
{ name: 'Prosus', city: 'Heerengracht', state: 'Amsterdam' },
{ name: 'China Construction Bank', city: 'Xicheng District, Beijing', state: 'China' },
{ name: 'Tata Consultancy Services', city: 'Maharashtra', state: 'India' },
{ name: 'Philip Morris', city: 'Richmon', state: 'Virginia' },
{ name: 'Morgan Stanley', city: 'New York', state: 'New York' },
{ name: 'AMD', city: 'Orlando', state: 'Florida' },
{ name: 'Union Pacific Corporation', city: 'Omaha', state: 'Nebraska' },
{ name: 'Intel', city: 'Santa Clara', state: 'California' },
{ name: 'Charles Schwab', city: 'New York', state: 'New York' },
{ name: 'Raytheon Technologies', city: 'Waltham', state: 'Massachusetts' },
{ name: 'PetroChina', city: 'Beijing', state: 'China' }
];