import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/_helpers";
import { LayoutComponent } from "@app/account/layout.component";
import { DashboardComponent } from "../leads/dashboard";
import { LeadBreakdownComponent } from "../leads/lead-breakdown";
import { WebsitePerformanceComponent } from "../leads/websiteperformance/website-performance.component";
import { LeadViewComponent } from "../leads/lead-view";
import { SimpleDownloadComponent } from "../leads/download/simpledownload.component";
import { AdperformanceComponent } from "../leads/adperformance/adperformance.component";
import { CampaignCreatorComponent } from "./campaign-creator/campaign-creator.component";
import { AdCreator1Component } from "./campaign-creator/ad-creator1/ad-creator1.component";
import { AdCreator2Component } from "./campaign-creator/ad-creator2/ad-creator2.component";
import { AdCreatorCompleteComponent } from "./campaign-creator/ad-creator-complete/ad-creator-complete.component";

const routes: Routes = [
    {
        path: "",

        canActivate: [AuthGuard],
        children: [

            { path: "dashboard", component: DashboardComponent },
            { path: "lead-view", component: LeadViewComponent },
            { path: "lead-breakdown", component: LeadBreakdownComponent },
            { path: "website-performance", component: WebsitePerformanceComponent},
            { path: "simpledownload", component: SimpleDownloadComponent },
            { path: "campaign-performance", component: AdperformanceComponent},
            { path: "campaign-creator", component: CampaignCreatorComponent},
            { path: "campaign-creator/first-step", component: AdCreator1Component },
            { path: "campaign-creator/second-step", component: AdCreator2Component },
            { path: "campaign-creator/complete", component: AdCreatorCompleteComponent },

        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LeadsRoutingModule {

}
