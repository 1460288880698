import { Setting } from '../models/setting.model';
import { SettingAction, SettingActionType } from '../actions/settings.actions';

const initialState: Setting = {
    id: 0,
    linkedinAdId: 0,
    launchPreference: 0,
    revenue: [],
    employees: [],
    industries: [],
    introText: '',
    image: '',
    headline: '',
    destinationUrl: '',
    callToAction: '',
    logo: '',
    companyName: '',
    followerCount: 0,
};

export function SettingReducer(
    state: Setting = initialState,
    action: SettingAction
) {
    switch (action.type) {
        case SettingActionType.ADD_ITEM:
            return  {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}