<!--<div class="row justify-content-between align-items-center">
    <div class="col"></div>
    <div class="col-auto">
        <div class="dateControl d-flex align-items-center">

        </div>
    </div>
</div>-->

<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    Report Rover&#8482; Lead View
</div>
<div class="container-fluid">
    <div class="row" class="">

        <div class="col-lg-12">
            <div class="card table-card">
                <div class="container-fluid">
                    <div class="row mb-4 justify-content-between">
                        <div class="col-7">
                            <h3 class="card-title">RECENT LEADS</h3>
                        </div>


                        <div class="col-auto">

                            <div class="d-flex align-items-right justify-content-end">
                                <span class="center">Date Range</span>
                                <span class="center">
                                    <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                        <span>
                                            <input class="no-border" opens="left" type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                            [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                        </span>
                                    </button>
                                </span>
                            </div>
                        </div>

                        <div class="col-auto" >

                            <div class="input-group">
                                <div class="form-outline">
                                    <input type="search" #searchInputLeadView id="searchInputLeadView" class="form-control btn-light rr_search" placeholder="Search" [(ngModel)]="searchValue" />
                                </div>
                                <!--<button type="button" class="btn btn-light">
                                    <i class="fas fa-search"></i>
                                </button>-->
                            </div>

                        </div>

                    </div>

                    <div class="row mb-0">
                        <div class="col-6">
                            <div class="row rr_pager" *ngIf="dataService.companyLeads.results.length > 0">
                                <div class="col-auto">
                                    View:
                                </div>
                                <div [ngClass]="itemsperpage === 25 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'"  (click)="itemsPerPageChange(25)">
                                    25
                                </div>
                                <div [ngClass]="itemsperpage === 50 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'" (click)="itemsPerPageChange(50)">
                                    50
                                </div>
                                <div [ngClass]="itemsperpage === 100 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'"  (click)="itemsPerPageChange(100)">
                                    100
                                </div>
                            </div>
                        </div>

                        <div class="col-6">

                            <div class="d-flex align-items-right justify-content-end">
                                <div class="me-5">
                                    <mat-checkbox [checked]="dataService.includeISPs" (change)="markISP($event)" >Include ISPs?</mat-checkbox>
                                    <span class="ttip ms-2" matTooltip="ISPs are Internet Service Providers that provide individuals and organizations access to the internet and other related services. ISPs. An individual person looking at a webpage cannot be identified because of the privacy of that individual and their ISP."><i class="fa fa-circle-info"></i></span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row" style="height: 10px"></div>

                    <table class="table table-striped table-borderless table-hover stickyths" *ngIf="dataService.companyLeads.results.length > 0">
                        <thead>
                            <tr>
                                <td scope="col">Company Name</td>
                                <td scope="col">City</td>
                                <td scope="col">State</td>
                                <!-- <td scope="col"></td> -->
                                <td scope="col">
                                    <span>Employees</span>
                                </td>
                                <td scope="col">
                                    <span>Revenue</span>
                                </td>
                                <td scope="col">
                                    <a class="sort-btn btn-sort"  id="session_count_sort" (click)="sortLeads('session_count')">
                                        <span>Sessions</span>
                                    </a>
                                </td>
                                <td scope="col">
                                    <a class="sort-btn btn-sort sort-active" id="page_count_sort" (click)="sortLeads('page_count')">
                                        <span>Page Views</span>
                                    </a>
                                </td>
                                <!--<td class="align-middle" scope="col">User Journey</td>-->
                            </tr>
                        </thead>
                        <tbody>
                            <!--<tr
                                *ngFor="let lead of companyLeads.results | leadSort:'page_count' | paginate: { itemsPerPage: companyLeads.limit, currentPage: companyLeads.page, totalItems: companyLeads.count }"
                                [class.is-isp]="lead.isisp"
                                routerLink="/lead-breakdown"
                            >-->
                            <!--<tr *ngFor="let lead of dataService.companyLeads.results | paginate: { itemsPerPage: dataService.companyLeads.limit, currentPage: dataService.companyLeads.page, totalItems: dataService.companyLeads.count }" -->
                            <tr *ngFor="let lead of dataService.companyLeads.results | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }"  [class.is-isp]="lead.isisp" >
                                {{ randomService.pickRandomCompany() }}

                                <td *ngIf="dataService.currentOrg.org_id !== 'demo'"  (click)="leadBreakdown(lead)"> {{ lead.company_name }} </td>
                                <td *ngIf="dataService.currentOrg.org_id === 'demo'" (click)="leadBreakdownDemo(lead, randomService.lastSelectedCompany.name )">{{ randomService.lastSelectedCompany.name }}</td>
                                <td *ngIf="dataService.currentOrg.org_id !== 'demo'" (click)="leadBreakdown(lead)">{{ lead.company_city }}</td>
                                <td *ngIf="dataService.currentOrg.org_id === 'demo'" (click)="leadBreakdownDemo(lead, randomService.lastSelectedCompany.name )">{{ randomService.lastSelectedCompany.city }}</td>
                                <td *ngIf="dataService.currentOrg.org_id !== 'demo'" (click)="leadBreakdown(lead)">{{ lead.company_region }}</td>
                                <td *ngIf="dataService.currentOrg.org_id === 'demo'" (click)="leadBreakdownDemo(lead, randomService.lastSelectedCompany.name )">{{ randomService.lastSelectedCompany.state }}</td>

                                <td (click)="leadBreakdown(lead)"> {{ lead.company_employees }} </td>
                                <td (click)="leadBreakdown(lead)"> {{ lead.company_revenue }} </td>
                                <td (click)="leadBreakdown(lead)"> {{ lead.session_count }} </td>
                                <td (click)="leadBreakdown(lead)"> {{ lead.page_count }} </td>
                                <!--<td>
                                    <a (click)="showJourney(lead)" class="content_img" data-toggle="tooltip" title="Session Journey">
                                        <i class='fa fa-route' style='font-size:20px;color:gray' ></i>
                                    </a>
                                </td> -->
                                <!-- <td><img class="moreicon" src="/assets/icons/RR_Link_Icon_Light_BluePurple.svg" alt="view more" (click)="leadBreakdown(lead)"></td> -->
                            </tr>
                            <!--<tr *ngFor="let leads of companyLeads.results | leadSort: 'page_count' | paginate: { itemsPerPage: companyLeads.limit, currentPage: companyLeads.page, totalItems: companyLeads.count, id: 'pn2' }"
                                [class.is-isp]="lead.isisp"
                                routerLink="/lead-breakdown"
                            >
                                <td> {{ lead.company_name }} </td>
                                <td> {{ lead.company_city }} </td>
                                <td> {{ lead.company_region }} </td>
                                <td> {{ lead.company_employees }} </td>
                                <td> {{ lead.company_revenue }} </td>
                                <td> {{ lead.session_count }} </td>
                                <td> {{ lead.page_count }} </td>
                                <td><img class="moreicon" src="/assets/icons/RR_Link_Icon_Light_BluePurple.svg" alt="view more"></td>
                            </tr>-->
                        </tbody>
                    </table>

                    <table class="table table-striped table-borderless"  *ngIf="dataService.companyLeads.results.length === 0">
                        <thead>
                            <tr>
                                <td scope="col-12">
                                    No data available for those dates
                                </td>

                            </tr>

                        </thead>


                    </table>

                    <!--<div class="cardFoot">-->
                    <div class="row mt-10 align-items-right justify-content-end" *ngIf="dataService.companyLeads.results.length > 0">
                        <div class="col-3">
                            <div class="row rr_pager">
                                <div class="col-auto">
                                    View:
                                </div>
                                <div [ngClass]="itemsperpage === 25 ? 'col-auto rr_pager rr_pager_active':'col-auto rr_pager rr_pager_inactive'"  (click)="itemsPerPageChange(25)">
                                    25
                                </div>
                                <div [ngClass]="itemsperpage === 50 ? 'col-auto rr_pager rr_pager_active':'col-auto rr_pager rr_pager_inactive'" (click)="itemsPerPageChange(50)">
                                    50
                                </div>
                                <div [ngClass]="itemsperpage === 100 ? 'col-auto rr_pager rr_pager_active':'col-auto rr_pager rr_pager_inactive'"  (click)="itemsPerPageChange(100)">
                                    100
                                </div>
                            </div>
                        </div>
                        <div class="col-4"></div>
                        <div class="col-5 align-items-right justify-content-end" style="display: flex">
                            <pagination-controls  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mb-4">
    <div class="row">
        <div class="col-4 d-flex left-org-map">
            <app-org-map></app-org-map>
        </div>
        <div class="col-8 pie-charts">
            <app-circle-chart></app-circle-chart>
        </div>
    </div>
</div>


<!--<div class="card table-card position-relative pieBreakdown">
    <span class="ttip ttip-topright" matTooltip="The chart below displays the distribution of website visitors by industry."><i class="fa fa-circle-info"></i></span>
    <app-circle-chart></app-circle-chart>
</div>
<app-org-map></app-org-map>-->
