import { Component, OnInit } from "@angular/core";
import { DataService } from "@app/_services";
import { LeadsService } from "@app/_services/leads.service";
import * as Highcharts from "highcharts";
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";
import moment, { Moment } from "moment";
import more from "highcharts/highcharts-more";
import { map } from 'rxjs/operators';

more(Highcharts);

@Component({
  selector: "referral-circle-chart",
  templateUrl: "./referral-chart.component.html"
})
export class ReferralChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  tab = 0;
  chartOptions: any;
  chartType = "Referral";


  // domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ],
    "Last 3 Month": [
      moment()
        .subtract(3, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ]
};
  constructor(public dataService: DataService,
              public leadsService: LeadsService
    ) {



  }

  ngOnInit() {
    if (!this.dataService.startSourceMediumDate) {

      this.dataService.startSourceMediumDate = moment().subtract(6, "days");
      this.dataService.endSourceMediumDate = moment();

      this.dataService.selectedSourceMediumDates = { startDate: this.dataService.startSourceMediumDate, endDate: this.dataService.endSourceMediumDate };
    }

    this.loadChart();

  }

  loadChart() {

    this.dataService.loadReferralChart();

  }

  datesUpdated(event) {
    if (event.startDate) {


      this.dataService.setSourceMediumDates(event);


      this.queryOptions.start = new Date(this.dataService.startSourceMediumDate);
      this.queryOptions.end = new Date(this.dataService.endSourceMediumDate);

      this.dataService.loadReferralChart();

      //======================= start set the other dates to match =========================//
      this.dataService.setSnapDates(event, true);
      this.dataService.setReferralDates(event);
      this.dataService.setPageReferralDates(event);
      this.dataService.setDates();

      this.queryOptions.start = new Date(this.dataService.startDate);
      this.queryOptions.end = new Date(this.dataService.endDate);

      this.queryOptions.sortOrder = "page_count";
      this.queryOptions.sortDirection = "desc";

      this.queryOptions.page = 1;
      this.queryOptions.pageSize = 5;

      this.queryOptions.includeISPs = this.dataService.includeISPs;

      this.leadsService.getLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
          map(leads => {
                  this.dataService.companyLeads = leads;
                  this.dataService.loadLeads();

                  this.queryOptions.start = new Date(this.dataService.startReferralDate);
                  this.queryOptions.end = new Date(this.dataService.endReferralDate);

                  this.queryOptions.sortOrder = "users";
                  this.queryOptions.sortDirection = "desc";

                  this.queryOptions.page = 1;
                  this.queryOptions.pageSize = 1000;

                  this.dataService.loadSourceMedium();
                  this.dataService.loadWebsiteMetrics();

                  // pages viewed by company
                  this.queryOptions.start = new Date(this.dataService.startPageReferralDate);
                  this.queryOptions.end = new Date(this.dataService.endPageReferralDate);

                  this.queryOptions.sortOrder = "page_count";
                  this.queryOptions.sortDirection = "desc";

                  this.queryOptions.page = 1;
                  this.queryOptions.pageSize = 1000;

                  this.dataService.loadCompanyBreakdown();

              }
          ),
          //takeUntil(this.leadSub)
      ).subscribe();



    }
//        localStorage.setItem('selectedDates', this.selectedDates);

}


}
