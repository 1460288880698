import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


enum DateRangeSelection{
    Last30Days,
    Last60Days,
    Last6Months,
    Last1Year,
    Custom 
}

@Injectable({
    providedIn: 'root'
})
export class DateRangeService{

    constructor(){ }

    parseDate(theDate): string{
        let dateStr = '';

        dateStr = 
        (
            theDate.getFullYear() + 
                '-' +
            (parseInt(theDate.getMonth(),0)+1).toString() +             
                '-' + 
            theDate.getDate()
        );
        
        return dateStr;
    }

    /*getRanges(): DateRanges{
        // Build Date Objects 
        this.dateRange.todaysDate = new Date();
        this.dateRange.back30Days = new Date( new Date().getDate() - 30 );
        this.dateRange.back60Days = new Date( new Date().getDate() - 60 );
        this.dateRange.back6Months = new Date( new Date().getMonth() - 6 );
        this.dateRange.back1Year = new Date( new Date().getFullYear() - 1 ); 

        // Build user readable ranges 
        this.dateRange.back30Days_Range = 
        (
            'Last 30 Days ( ' + 
            this.parseDate(this.dateRange.todaysDate) + 
            ' - ' + 
            this.parseDate(this.dateRange.back30Days) + 
            ' )'
        );
        this.dateRange.back30Days_Val = 1;

        this.dateRange.back60Days_Range = 
        (
            'Last 60 Days ( ' + 
            this.parseDate(this.dateRange.todaysDate) + 
            ' - ' + 
            this.parseDate(this.dateRange.back60Days) + 
            ' )'
        );
        this.dateRange.back60Days_Val = 2;

        this.dateRange.back6Months_Range = 
        (
            'Last 6 Months ( ' + 
            this.parseDate(this.dateRange.todaysDate) + 
            ' - ' + 
            this.parseDate(this.dateRange.back6Months) + 
            ' )'
        );
        this.dateRange.back6Months_Val = 3;

        this.dateRange.back1Year_Range = 
        (
            'Last Year ( ' + 
            this.parseDate(this.dateRange.todaysDate) + 
            ' - ' + 
            this.parseDate(this.dateRange.back1Year) + 
            ' )'
        );
        this.dateRange.back1Year_Val = 4;

        this.dateRange.custom_val = 999;

        return this.dateRange;
    }*/
}

