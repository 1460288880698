import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
  name: 'referralsource'
})
export class ReferralSourcePipe implements PipeTransform {
 /*
  transform(value: any, ...args: unknown[]): unknown {
    if (value) {
      if (value.referral_source==='organic') {
        return `${value.referral_source}/${value.referral_medium}`;

      } else if (value.referral_source==='referral')  {
        return `${value.referral_medium}`;

      } else if (value.referral_source==='same site')  {
        return `${value.referral_source}`;

      } else if (value.referral_source==='direct')  {
        return `${value.referral_source}`;

      } else if (value.referral_source==='error')  {
        return ``;
        
      } else {
        return `${value.referral_source}`;
      }
    } else {
      return '';
    }
 */
  transform(value: any, ...args: unknown[]): unknown {
    if (value) {
      if (value.referrer==='') {
        return 'Direct';
      } else if (value.page_url.includes('?utm_source')) {
        debugger;

        const urlparams = new URLSearchParams(value.page_url.split('?')[1]);
        urlparams.forEach((value2, key) => {
          console.log(value2, key);
        });

        return urlparams.get('utm_source') + "/" + urlparams.get('utm_medium');


      } else if (new URL(value.referrer).hostname === new URL(value.page_url).hostname) {
        return 'Same site';
      } else if (new URL(value.referrer).hostname === 'google.com') {
        return 'Organic';        
      } else if (new URL(value.referrer).hostname === 'www.google.com') {
        return 'Organic';                
      } else if (new URL(value.referrer).hostname === 'search.aol.com') {
        return 'Organic';  
      } else if (new URL(value.referrer).hostname === 'www.bing.com') {
        return 'Organic';          
      } else if (new URL(value.referrer).hostname === 'search.yahoo.com') {
        return 'Organic';                  
        
      } else {
        //return new URL(value.referrer).hostname;
        return 'Referral';
        //return value.referrer;
      }
    } else {
      return 'Direct';
    }

  }

}
