import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { environment } from '@environments/environment';

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: 'root',
})
export class NotificationService{
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient){ }

    sendNotification(body): Observable<any>{
        var url = `${baseUrl}/sendmail`;

   
        //console.log('sendNotification(): ' + url);

        return this.httpClient
            .put<any>(url, body)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    //console.error(error);
                    return throwError(error);
                })
            );
    }

    sendInvite(org, account): Observable<any>{
        var url = `${baseUrl}/sendinvite`;

        //console.log('sendInvitation(): ' + url);

        return this.httpClient
            .post<any>(url, {'orgid': org, 'userid': account.id, 'email': account.email })
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    //console.error(error);
                    return throwError(error);
                })
            );
    }    
}