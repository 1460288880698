<div class="dashintro">  <!-- Move this to component -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				My Website's Performance / Metric
			</div>
		</div>
		<div class="row">
			<h6>{{dataService.currentOrg.name}}</h6>
		</div>
	</div>
</div>

<div class="container-fluid mb-4">
	<div class="row justify-content-center">
		<div class="col-xxl-8 col-lg-12">
			<app-snapshot></app-snapshot>
		</div>
		<div class="col-xxl-4 mt-xxl-0 mt-lg-4 col-lg-7 d-flex">
			<referral-circle-chart style="max-width: 100%;width:100%"></referral-circle-chart>

		</div>
	</div>
</div>

<div class="container-fluid">
	<div class="row">
		<div class="col-lg-12">
			<div class="card table-card tab-card">
				<ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
					<li class="nav-item" role="presentation">
						<button class="nav-link active rr-nav-link" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">SOURCE/MEDIUM BREAKDOWN</button>
					</li>
					<li class="nav-item" role="presentation">
						<button class="nav-link rr-nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">PAGES VIEWED BY COMPANY</button>
					</li>
				</ul>
				<div class="tab-content" id="myTabContent">
					<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<app-company-breakdown></app-company-breakdown>
					</div>
					<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
						<app-wspage-breakdown></app-wspage-breakdown>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<!--

<app-company-chart></app-company-chart>
<app-snapshot></app-snapshot>
<app-company-breakdown></app-company-breakdown>
-->


