<div class="dashintro">  <!-- Move this to component -->
    Report Rover&#8482;  Data Download
</div>
<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card table-card">
                <div class="row align-items-center mb-4">


                    <div class="col-lg-2">
                        <h3 class="card-title">DATA DOWNLOAD</h3> 
                    </div>
                    <div class="col">
                        
                        <div class="d-flex align-items-center justify-content-end">
                            <div class="me-5">
                                
                            </div>
                            <span class="center">Dates:</span>
                            <span class="center">
                                <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                    <span>
                                        <input class="no-border" opens="left" type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedAllDataDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                        [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>  
                    <!--<div class="col-lg-6">
                        <div class="exportButton" style="float: right">
                            <button class="export btn-common" >
                                <span>Export</span>
                                <img class="img-fluid" src="/assets/icons/RR_Export_Icon_White.svg" alt="Export">
                            </button>
                        </div>
                    </div>-->
                </div>
                <div class="row" style="width:100%; height: 55vh;"> 
                    <ejs-grid #grid id='Grid' [dataSource]='vData' [allowSorting]="true" [allowFiltering]="false" [enableVirtualization]='true' [enableColumnVirtualization]='false' [editSettings]='editSettings' [toolbar]='toolbar' height='100%' width='100%' [allowPaging]='false' [allowExcelExport]='true' (toolbarClick)='toolbarClick($event)' (dataBound)='hide()'>
                        <e-columns>

                            <e-column field='name' headerText='Company Name' width='140'></e-column>
                            <e-column field='city' headerText='City' width='100'></e-column>
                            <e-column field='region' headerText='State' width='100'></e-column>
                            <e-column field='employees' headerText='Employees' width='10'></e-column>
                            <e-column field='revenue' headerText='Revenues' width='10'></e-column>
                            <e-column field='timestamp' type="date"  headerText='Page Start'  format="M/d/y hh:mm a" width='200'></e-column>
                            <e-column field='duration' headerText='Duration' width='160'></e-column>
                            <e-column field='page_title' headerText='Page Visited' width='260'></e-column>
                            <e-column field='page_url' headerText='Page URL' width='260'></e-column>
                            <e-column field='referrer' headerText='Referral Source' width='160'>

                                <ng-template #template let-data>

                                {{ data | referralsource }}
                    
                            </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>
    </div>
</div>