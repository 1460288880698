import { NgModule } from "@angular/core";
import { RandomPagePipe } from '@app/pipes/randompage.pipe';

@NgModule({
    imports: [],
    exports: [RandomPagePipe],
    declarations: [
        RandomPagePipe
    ]
})
export class RandomPagePipeModule {

}