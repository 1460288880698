import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
//import { AuthGuard } from "./_helpers";
// import { DashboardComponent } from "./leads/dashboard";
/*

import { LeadBreakdownComponent } from './leads/lead-breakdown';

import { WebsitePerformanceComponent } from './leads/websiteperformance/website-performance.component';
import { LeadViewComponent } from './leads/lead-view';
import { SimpleDownloadComponent } from './leads/download';

import { OverviewComponent } from '@app/admin/overview.component';
import { ListComponent } from '@app/admin/accounts/list.component';
import { AddEditComponent } from '@app/admin/accounts/add-edit.component';

import { OrgListComponent } from '@app/admin/organizations/list.component';
import { OrgAddEditComponent } from '@app/admin/organizations/add-edit.component';


import { LayoutComponent } from '@app/profile/layout.component';
import { DetailsComponent } from '@app/profile/details.component';
import { UpdateComponent } from '@app/profile/update.component';


const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);

const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);
*/

const accountModule: any = () => import("./account/account.module").then(x => x.AccountModule);
const leadsModule: any = () => import("./leads/leads.module").then(x => x.LeadsModule);

const routes: Routes = [
/*
  { path: "leads/dashboard", component: DashboardComponent, canActivate: [AuthGuard] },

  { path: 'leads/lead-view', component: LeadViewComponent, canActivate: [AuthGuard] },
    { path: 'leads/simpledownload', component: SimpleDownloadComponent, canActivate: [AuthGuard] },
    { path: 'leads/lead-breakdown', component: LeadBreakdownComponent, canActivate: [AuthGuard] },
    { path: 'leads/website-performance', component: WebsitePerformanceComponent, canActivate: [AuthGuard] },

*/
{ path: "account", loadChildren: accountModule },
{ path: "leads", loadChildren: leadsModule },

    // otherwise redirect to home
    { path: "",   redirectTo: "leads/dashboard", pathMatch: "full" },
    { path: "**", redirectTo: "account/login", pathMatch: "full" }
];

@NgModule({

    imports: [RouterModule.forRoot(routes, { enableTracing: false /*, relativeLinkResolution: 'legacy'*/ })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
