import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { debounceTime, switchMap, startWith } from "rxjs/operators";
import { of } from "rxjs";
import { DataService } from '@app/_services';

@Component({
    selector: 'referers',
    templateUrl: 'referer.component.html',

  })
  export class RefererComponent {
    search = new UntypedFormControl();
    referrals: any;
    itemsControl = new UntypedFormControl();
    constructor(
        public dataService: DataService 
    ) { 
        
        this.referrals = this.dataService.referrals;



        //this.delay(15000);
    }

    
    $search = this.search.valueChanges.pipe(
        startWith(null),
        debounceTime(200),
        switchMap((res: string) => {
            if (!res) return of(this.referrals);
            res = res.toLowerCase();
            return of(
                this.referrals.filter(x => (x.referrer.toLowerCase().indexOf(res) >= 0 || x.page_url.toLowerCase().indexOf(res) >= 0 || x.name.toLowerCase().indexOf(res) >= 0))
            );
        })
    );
    
    ngOnInit(): void {
        

    }


    delay(ms: number)
    {
        return new Promise(resolve => setTimeout(resolve, ms));

    }

      selectionChange(option: any) {
        //let value = this.itemsControl.value || [];
        //if (option.selected) value.push(option.value);
        //else value = value.filter((x: any) => x != option.value);
        //this.itemsControl.setValue(value);
      }

    isPageReferral(element, index, array) { 
        return (element.page_title === this); 
     } 

    close() {
        this.dataService.dialogRef.close();
    }

}