import { Component, EventEmitter, Input, Output, ViewChildren, QueryList, OnInit } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { ThemePalette } from '@angular/material/core';
import { CampaignService } from '@app/_services';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';

interface ImageInfo {
  name: string,
  src: string,
  urn: string,
  checked: boolean,
}

@Component({
  selector: 'app-file-upload-modal',
  templateUrl: './file-upload-modal.component.html',
  styleUrls: ['./file-upload-modal.component.scss']
})
export class FileUploadModalComponent implements OnInit {
  @Input() isOpen: boolean;
  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() imageSelected = new EventEmitter<ImageInfo>();
  @ViewChildren('checkbox') checkboxes: QueryList<MatCheckbox>;

  public apiUrl: string = environment.apiUrl;
  public images: ImageInfo[] = [];
  public selectedImages: ImageInfo[] = [];

  color: ThemePalette = "primary";
  isFileValidated = true;

  constructor(private campaignService: CampaignService) {}

  ngOnInit() {
    const userId = localStorage.getItem('loggedin_user_id');
    this.campaignService.getUploadedFiles(userId)
      .subscribe((data: any) => {
        this.getImageInfo(data.results);
      });
  }

  getImageInfo(data: any) {
    data.forEach((result) => {
      result.checked = false;
      result.src = result['src'];
    });

    this.images = data;
  }

  closeModal() {
    this.isOpenChange.emit(false);
  }

  selecteImage() {
    const accessCode = localStorage.getItem('linkedin-access-code');

    this.campaignService.uploadFileIntoLinkedin(this.selectedImages[0].src, accessCode)
      .pipe(first())
      .subscribe({
        next: (result) => {
          const image: ImageInfo = {
            name: this.selectedImages[0].name,
            src: this.selectedImages[0].src,
            urn: result['result'],
            checked: true
          };

          this.isOpenChange.emit(false);
          this.imageSelected.emit(image);
        },
        error: (err) => {
          console.log('Error', err);
        }
      });
  }

  toggleCheckbox(image: ImageInfo) {
    if (!image.checked) {
      this.images.forEach(img => img.checked = false);
      image.checked = true;
      this.selectedImages = [image];
    } else {
      image.checked = false;
      this.selectedImages = [];
    }
  }

  remove(image: ImageInfo) {
    const index = this.images.findIndex(img => img === image);
    if (index !== -1) {
        this.images.splice(index, 1);
        // If the removed image was selected, remove it from the selectedImages array
        const selectedIndex = this.selectedImages.findIndex(selectedImg => selectedImg === image);
        if (selectedIndex !== -1) {
            this.selectedImages.splice(selectedIndex, 1);
        }
    }
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file: File = files[i];
        const fileReader = new FileReader();

        fileReader.onload = () => {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;

            const minWidth = 1200;
            const minHeight = 627;

            if (width < minWidth || height < minHeight) {
              this.isFileValidated = false;
            } else {
              this.isFileValidated = true;
              this.uploadFile(file);
            }
          }

          img.src = fileReader.result as any;
        }

        fileReader.readAsDataURL(file);
      }
    }
  }

  uploadFile(file: File) {
    const userId = localStorage.getItem('loggedin_user_id');

    this.campaignService.uploadFile(file, userId)
      .pipe(first())
      .subscribe({
        next: (res) => {
          const image: ImageInfo = {
            name: res['name'],
            src: res['src'],
            urn: '',
            checked: false
          };

          this.images.push(image);
        },
        error: () => {
          console.log('Error file uploading');
        }
      });
  }
}
