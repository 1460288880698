import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { LeadsService } from "../../_services/leads.service";
import { CompanyLeads } from "../../_models";
import { AccountService } from "../../_services";
import { LeadViewQueryOptions } from "../../_models";
import { DataService } from "@app/_services";
import { Observable, Subscription, Subject } from "rxjs";
import { takeUntil, map } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";
// import { NgxPaginationModule } from 'ngx-pagination';
import { Router, ActivatedRoute } from "@angular/router";
import moment, { Moment } from "moment";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { JourneyComponent } from "@app/_components/journey.component";
import { RandomCompany } from "@app/random-company";
import { RandomCompanyService } from "@app/random-company.service";
import { fromEvent } from "rxjs";

@Component({
  templateUrl: "./lead-view.component.html",
  providers: [LeadsService, RandomCompanyService],
})

export class LeadViewComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  itemsperpage: number;
  currentpage: number;
  totalrecords: number;

  account = this.accountService.accountValue;
  companyLeads: CompanyLeads;
  pageSize: number = 25;
  page: number = 1;
  totalPages: number;
  offset: number;
  companyLeads$: Observable<CompanyLeads>;
  randomCompanies: RandomCompany[];
  lastSelectedCompany: RandomCompany;
  searchValue = "";

  @ViewChild("searchInputLeadView") searchElementRef: ElementRef;

  private leadSub = new Subject();


  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ],
    "Last 3 Month": [
      moment()
        .subtract(3, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ]
};

  constructor(private accountService: AccountService,
      private leadsService: LeadsService,
      public dataService: DataService,
      public randomService: RandomCompanyService,
      private route: ActivatedRoute,
      private router: Router,
      public dialog: MatDialog
    ) {

      this.queryOptions.page = 1;
      this.queryOptions.pageSize = 10000;
      this.queryOptions.sortDirection = "desc";
      this.queryOptions.includeISPs = false;
  }



  ngAfterViewInit(): void {
    var that = this;
    let term: string;
    let objValue: string;
    fromEvent(this.searchElementRef.nativeElement, "input").subscribe(
      (x) => {
        this.dataService.companyLeads.results = Array.from(this.dataService.companyLeadsOrig);
        this.dataService.companyLeads.results = this.dataService.companyLeads.results.filter((obj)=> {
            return Object.keys(obj).reduce((acc, curr)=> {
                term = this.searchValue.toLowerCase();

                if (!obj[curr]) {
                  obj[curr] = "";
                }

                if (curr === "page_count" || curr === "session_count") {
                    objValue = obj[curr].toString();
                    return acc || objValue.includes(term);
                } else if (curr === "isisp" || curr === "company_id") {
                    return acc;
                } else {
                    objValue = obj[curr].toLowerCase();
                    return acc || objValue.includes(term);
                }

            }, false);
        });
      }
    );
  }



  ngOnInit(): void {
    this.currentpage = 1;
    this.itemsperpage = 25;

    this.queryOptions.start = new Date(this.dataService.startDate);
    this.queryOptions.end = new Date(this.dataService.endDate);

    this.loadLeads();

    // this.getRandomCompanies();
  }

//   getRandomCompanies(){
//     //this.randomCompanies = this.randomService.getCompanies();
//     this.pickRandomCompany();
// }

// pickRandomCompany(){
//     //generate a random number from 0 - companyNameFinalIndex
//     //set lastSelectedCompany = the selected company
//     //Math.Random() * (max - min) + min; --> Generates a number >= min and < max
//     let min = Math.ceil(0);
//     let max = Math.floor(this.randomCompanies.length);
//     let pickedNum = Math.floor(Math.random() * (max - min) + min); //0's here for illustrative purposes
//     this.lastSelectedCompany = this.randomCompanies[pickedNum];
// }

// getRandomCompany(): RandomCompany{
//     return this.lastSelectedCompany;
// }

  loadLeads() {
    this.queryOptions.sortOrder = "page_count";
    this.queryOptions.sortDirection = "desc";
    this.queryOptions.pageSize = 10000;
    this.queryOptions.includeISPs =  this.dataService.includeISPs;
    this.leadsService.getLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
      map(leads => {
        console.log(leads);
        this.dataService.companyLeads = leads;
        this.dataService.companyLeadsOrig = Array.from(this.dataService.companyLeads.results);

        this.dataService.loadIndustryMetrics();
        this.dataService.loadRevenueMetrics();
        this.dataService.loadEmployeeMetrics();

        this.dataService.loadMetrics();

        }),
      takeUntil(this.leadSub)
    ).subscribe();

    this.queryOptions.pageSize = 10000;
  }

  sortLeads(sortOrder) {
   // is this a new sort order?
   if (this.queryOptions.sortOrder === sortOrder) {
      if (this.queryOptions.sortDirection === "asc") {
          this.queryOptions.sortDirection = "desc";
      } else {
          this.queryOptions.sortDirection = "asc";
      }
    } else {
        this.queryOptions.sortDirection = "desc";
    }
    this.queryOptions.sortOrder = sortOrder;

    document.getElementById(`company_employees_sort`).classList.remove("btn-sort");
    document.getElementById(`company_revenue_sort`).classList.remove("btn-sort");
    document.getElementById(`page_count_sort`).classList.remove("btn-sort");
    document.getElementById(`session_count_sort`).classList.remove("btn-sort");

    document.getElementById(`company_employees_sort`).classList.remove("btn-sort-asc");
    document.getElementById(`company_revenue_sort`).classList.remove("btn-sort-asc");
    document.getElementById(`page_count_sort`).classList.remove("btn-sort-asc");
    document.getElementById(`session_count_sort`).classList.remove("btn-sort-asc");


    document.getElementById(`sort_active`).classList.remove("sort-active");

    if (this.queryOptions.sortDirection === "desc") {
        document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort");
        document.getElementById(`${sortOrder}_sort`).classList.add("sort-active");
        if (sortOrder==="company_employees" || sortOrder==="company_revenue") {
          this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) < parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1);
        } else {
          this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] < b[sortOrder]) ? 1 : -1);
        }

    } else {
      document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort");
      document.getElementById(`${sortOrder}_sort`).classList.add("sort-active");
        if (sortOrder==="company_employees" || sortOrder==="company_revenue") {
          this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) > parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1);
        } else {
          this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] > b[sortOrder]) ? 1 : -1);
        }
    }
    document.getElementById(`${sortOrder}_sort`).classList.remove("btn-hollow");

  }

  itemsPerPageChange(event) {
    this.itemsperpage = event;
    this.queryOptions.page = 1;
    this.loadLeads();
  }

  handlePageChange(event) {
    this.queryOptions.page = event;
    this.queryOptions.pageSize = this.pageSize;
    this.currentpage = event;
   // this.refreshLeads(this.queryOptions.sortOrder);
  }

  async leadBreakdown(lead) {
    /* Get the full lead */
    this.leadsService.getLead(lead.company_id).subscribe(fulllead => {
        this.dataService.lead = fulllead.results;

        localStorage.setItem("lead", JSON.stringify(this.dataService.lead));
        this.router.navigate(["/leads/lead-breakdown"], { relativeTo: this.route });
    });
  }

  ngOnDestroy(): void {
    this.leadSub.next();
    this.leadSub.complete();
  }

  datesUpdated(event) {
    // console.log({ event });

    if (event.startDate) {

        this.dataService.startDate = event.startDate;
        this.dataService.endDate = event.endDate;

        localStorage.setItem("startDate", event.startDate) ;
        localStorage.setItem("endDate", event.endDate) ;

        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);

        this.queryOptions.sortOrder = "page_count";
        this.queryOptions.sortDirection = "desc";

        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 10;

        this.queryOptions.includeISPs =  this.dataService.includeISPs;

        this.leadsService.getLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
            map(leads => {
                    this.dataService.companyLeads = leads;
                }
            ),
            takeUntil(this.leadSub)
        ).subscribe();


        this.dataService.loadMetrics();
        this.dataService.loadIndustryMetrics();
        this.dataService.loadRevenueMetrics();
        this.dataService.loadEmployeeMetrics();

        this.dataService.setPageReferralDates(event);

        // update source/medium
        this.dataService.setReferralDates(event);

        // set referral chart
        this.dataService.setSourceMediumDates(event);

        this.dataService.setSnapDates(event, true);

    }
//        localStorage.setItem('selectedDates', this.selectedDates);

}


  markISP(obj: MatCheckboxChange) {
    if (obj.checked) {
      this.dataService.includeISPs = true;
    } else {
        this.dataService.includeISPs = false;
    }

    this.dataService.loadLeads();
    this.dataService.loadMetrics();
    this.dataService.loadIndustryMetrics();


  }


  async showJourney(lead) {
    this.loading = true;
    this.dataService.lead = lead;

    let dataArr = [];

    this.queryOptions.start = new Date(this.dataService.startDate);
    this.queryOptions.end = new Date(this.dataService.endDate);

    await this.leadsService.getSessionsByDate(this.dataService.currentOrg.org_id, lead.company_id, this.queryOptions).pipe(
      map(results => {

        this.dataService.sessions = results.sessions;

        this.loading = false;
        this.dataService.dialogRef = this.dialog.open(JourneyComponent, {
            height: "90%",
            width: "98%",
        });

      })
    ).subscribe();




      this.dataService.dialogRef.afterClosed().subscribe(result => {

      });

    }



}

