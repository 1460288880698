import { NgModule } from "@angular/core";
import { DurationPipe2 } from '@app/pipes/duration.pipe2';

@NgModule({
    imports: [],
    exports: [DurationPipe2],
    declarations: [
        DurationPipe2
    ]
})
export class DurationPipe2Module {

}