
            <div class="row align-items-center mb-4 pt-3 justify-content-between">
                <div class="col-7">
                    <h3 class="card-title mb-0">SOURCE/MEDIUM BREAKDOWN</h3>
                </div>


                <div class="col-auto">

                    <div class="d-flex align-items-right justify-content-end">
                        <span class="center">Date Range</span>
                        <span class="center">
                            <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                <span>
                                    <input class="no-border" opens="left" type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedReferralDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                    [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                </span>
                            </button>
                        </span>
                    </div>
                </div>

                <div class="col-auto" >

                    <div class="input-group">
                        <div class="form-outline">
                            <div class="searchBox">
                                <input type="search" #searchInput id="searchInput" class="form-control btn-light rr_search" placeholder="Search" [(ngModel)]="searchValue" />
                                <div class="search-icon">
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <table class="table table-striped table-borderless" *ngIf="dataService.sourceMediumResults.length > 0">
                <thead>
                    <tr>
                        <td scope="col-4"></td>
                        <td scope="col-1">
                            <a class="sort-btn btn-sort sort-active" id="users_sort" (click)="dataService.sortSourceMedium('users')">
                                <span>Users</span>
                            </a>
                        </td>
                        <td scope="col-1">
                            <a class="sort-btn btn-sort" id="new_users_sort" (click)="dataService.sortSourceMedium('new_users')">
                                <span>New Users</span>
                            </a>
                        </td>
                        <td scope="col-1">
                            <a class="sort-btn btn-sort" id="sessions_sort" (click)="dataService.sortSourceMedium('sessions')">
                                <span>Sessions</span>
                            </a>
                        </td>
                        <td scope="col-1">
                            <a class="sort-btn btn-sort" id="bounces_sort" (click)="dataService.sortSourceMedium('bounces')">
                                <span>Bounce Rate</span>
                            </a>
                        </td>
                        <td scope="col-2">
                            <!--<a class="sort-btn btn-sort" id="pages_per_session_sort" (click)="dataService.sortWebsiteMetrics('pages_per_session')">-->
                                <span>Pages / Session</span>
                            <!--</a>-->
                        </td>
                        <td scope="col-2">
                            <!--<a class="sort-btn btn-sort" id="avg_session_duration_sort" (click)="dataService.sortWebsiteMetrics('avg_session_duration')">-->
                                <span>Avg. Session Duration</span>
                            <!--</a>-->
                        </td>
                    </tr>
                    <tr class="src-md-bd-hl">
                        <td scope="col-4">Source/Medium</td>
                        <td scope="col-1" style='font-size:1.6em'>{{dataService.websiteMetrics.users}}</td>
                        <td scope="col-1" style='font-size:1.6em'>{{dataService.websiteMetrics.newusers}}</td>
                        <td scope="col-1" style='font-size:1.6em'>{{dataService.websiteMetrics.sessions}}</td>
                        <td scope="col-1" style='font-size:1.6em'>{{dataService.websiteMetrics.bounces / dataService.websiteMetrics.sessions | percent}}</td>
                        <td scope="col-2" style='font-size:1.6em'>{{dataService.websiteMetrics.pages / dataService.websiteMetrics.sessions | number}}</td>
                        <td scope="col-2" style='font-size:1.6em'>{{dataService.websiteMetrics.duration / dataService.websiteMetrics.sessions | duration}}</td>

                        <!--
                        <td scope="col" style='font-size:2em;color:gray'>{{dataService.websiteMetrics.users}}<br/><span style='font-size: .3em; color:gray'>% of Total: 100.00% (330)</span></td>
                        <td scope="col" style='font-size:2em;color:gray'>{{dataService.websiteMetrics.newusers}}<br/><span style='font-size: .3em; color:gray'>% of Total: 100.00% (299)</span></td>
                        <td scope="col" style='font-size:2em;color:gray'>{{dataService.websiteMetrics.sessions}}<br/><span style='font-size: .3em; color:gray'>% of Total: 100.00% (356)</span></td>
                        <td scope="col" style='font-size:2em;color:gray'>{{dataService.websiteMetrics.bounces / dataService.websiteMetrics.sessions | percent}}<br/><span style='font-size: .3em; color:gray'>Avg. for View: 63/20%</span></td>
                        <td scope="col" style='font-size:2em;color:gray'>{{dataService.websiteMetrics.pages / dataService.websiteMetrics.sessions | number}}<br/><span style='font-size: .3em; color:gray'>Avg. for View 2.11</span></td>
                        <td scope="col" style='font-size:2em;color:gray'>{{dataService.websiteMetrics.duration / dataService.websiteMetrics.sessions | duration}}<br/><span style='font-size: .3em; color:gray'>Avg. for View: 00:00:58</span></td>
-->
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let page of dataService.sourceMediumResults | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }">
                        <td class="col-4">{{page | cleanreferral}}</td>
                        <!--<td class="col-4">{{page.referral_source.replace('http://','').replace('https://','')}}/{{page.referral_medium}}</td>-->



                        <td class="col-1">
                            {{page.users}}<!-- <br/>{{page.referral_source}}/{{page.referral_medium}}-->
                        </td>

                        <td class="col-1">
                            {{page.newusers}}
                        </td>
                        <td class="col-1">
                            {{page.sessions}}
                        </td>
                        <td class="col-1">
                            {{page.bounces / page.sessions | percent}}
                           <!-- {{page.bounces}} - {{page.sessions}}-->

                        </td>
                        <td class="col-2">
                            {{page.pages/page.sessions | number}}
                        </td>
                        <td class="col-2">
                            {{page.duration/page.sessions | duration}}
                        </td>

                    </tr>


                </tbody>
            </table>

            <table class="table table-striped table-borderless"  *ngIf="dataService.sourceMediumResults.length === 0">
                <thead>
                    <tr>
                        <td scope="col-12">
                            No data available for those dates
                        </td>

                    </tr>

                </thead>


            </table>

            <div class="cardFoot" *ngIf="dataService.sourceMediumResults.length > 0">
                <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
            </div>
