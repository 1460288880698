import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/_services';
import { LeadsService } from '@app/_services/leads.service';
import * as Highcharts from 'highcharts';
import { LeadViewQueryOptions } from '@app/_models/lead-view-query-opts';
import { map } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import more from 'highcharts/highcharts-more';

more(Highcharts);

@Component({
  selector: 'app-circle-chart',
  templateUrl: './circle-chart.component.html'
})
export class CircleChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  tab = 0;
  chartOptions: any;
  chartType = "INDUSTRY";


  //domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();


  constructor(public dataService: DataService,
              public leadsService: LeadsService
    ) {

  //  this.loadChart();


  }

  loadChart() {

    this.dataService.loadIndustryMetrics();
    this.dataService.loadRevenueMetrics();
    this.dataService.loadEmployeeMetrics();
  }


  top5() {

    this.dataService.top5only = !this.dataService.top5only;
    this.dataService.loadIndustryMetrics();
  }


  top5revenue() {

    this.dataService.top5revenueonly = !this.dataService.top5revenueonly;
    this.dataService.loadRevenueMetrics();
  }

  top5employee() {

    this.dataService.top5employeeonly = !this.dataService.top5employeeonly;
    this.dataService.loadEmployeeMetrics();
  }

  selectedTabValue(event) {
    this.tab = event.index;
    this.chartType = event.tab.textLabel.replace(" Breakdown", "").toUpperCase();

  }

}
