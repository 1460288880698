<div class="modal fade notification-modal" tabindex="-1" role="dialog" [ngClass]="{'show': isBudgetModalOpen}" *ngIf="isBudgetModalOpen">
  <div class="modal-dialog" role="document">
      <div class="modal-content">
          <a class="close-btn" aria-label="Close" (click)="closeModal()">
              <span>Close</span><i class="icon-close fas fa-xmark"></i>
          </a>
          <div class="modal-title">
              <span class="title">Budget</span>
          </div>
          <div class="modal-info">
              <div class="budget-subcontainer mb-3">
                  <p>Threshold amount<span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                  <div class="price-input">
                      <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="linkedinThreshold">
                      <span class="font-gray font-bold">USD</span>
                  </div>
              </div>
              <a href="https://www.reportrover.com/support/" class="font-bold font-light-gray font-italic budget-info">
                  How is my budget spent?
              </a>
              <div class="budget-subcontainer mt-4">
                  <p>Meta maximum daily budget for all companies (pages)
                      <span class="ttip" matTooltip="Tooltip here..."><i class="fas fa-circle-info font-light-gray icon-info"></i></span></p>
                  <div class="price-input">
                      <input type="text" class="form-control" placeholder="$000.00" [(ngModel)]="linkedinBudget">
                      <span class="font-gray font-bold">USD</span>
                  </div>
              </div>
              <p class="font-bold font-light-gray font-italic budget-info">
                  Divided by X number of pages = $00.00 per ad maximum daily budget
              </p>
              <p class="font-italic font-light-gray">Disclaimer</p>
              <div class="btn-campaign">
                  <button class="btn-common btn-link" (click)="storeBudget('meta')">Save</button>
              </div>
          </div>
      </div>
  </div>
</div>
