import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Account } from '../_models';

@Injectable({
    providedIn: 'root'
})
export class LoginService{
    private theUrl = '${baseUrl}/login';
    
    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient){}

    login(data): Observable<Account>{
        return this.httpClient
            .post<Account>(this.theUrl, data)
                  .pipe();
    }
}