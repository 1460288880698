import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/_services';
import { LeadsService } from '@app/_services/leads.service';
import * as Highcharts from 'highcharts';
import { LeadViewQueryOptions } from '@app/_models/lead-view-query-opts';
import { map } from 'rxjs/operators';
import moment, { Moment } from 'moment';

@Component({
  selector: 'app-company-chart',
  templateUrl: './company-chart.component.html'
})
export class CompanyChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chardata: any[] = [];
  chartOptions: any;

  multi: any[];
  view: any[] = [ 1800, 300];

  // options
  legend: boolean = true;
  legendPosition: string = 'Bottom';
  showLabels: boolean = true;
  animations: boolean = true;
  xAxis: boolean = true;
  gradient: boolean = true;
  yAxis: boolean = true;
  showYAxisLabel: boolean = false;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Date';
  yAxisLabel: string = 'Metrics';
  timeline: boolean = true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  chartMetric1: string = 'Page Views';
  chartMetric2: string = 'Pages Per Visit';

  //domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 3 Month': [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
};

  constructor(public dataService: DataService, 
              public leadsService: LeadsService
    ) {

    this.loadMetrics();
    



  }

  onSelect(data): void {
    //console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    //console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    //console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  changeMetric1(metric) {
    this.chartMetric1 = metric;
    this.getChart();
  }

  changeMetric2(metric) {
    this.chartMetric2 = metric;
    this.getChart();
  }

  
  loadMetrics() {
    this.queryOptions.sortOrder = 'view_count';
    this.queryOptions.sortDirection = 'asc';

    /* Get the top page views */
    let dataArr = [];

    this.queryOptions.start = new Date(this.dataService.startDate);
    this.queryOptions.end = new Date(this.dataService.endDate);

    this.leadsService.getOrgmetricsByDate(this.dataService.currentOrg.org_id, this.queryOptions).pipe(
      map(views => {


        //dataArr = views.reports[0].data.rows;
        views.metrics.forEach(element => {
          dataArr.push({
            page: element.page_title, 
            view_count: element.page_count,
            pages_per_visit: Number(element.page_visits),
            avg_duration: Number(element.avgduration),
            new_visits: Number(element.new_visits),
            thedate: element.thedate.substr(5,2) + '/' + element.thedate.substr(8,2)
          });
        });


        this.dataService.pageMetrics = dataArr;
        this.getChart();
      })
    ).subscribe();

  }

  getChart() {


    var cats = [];
    this.dataService.chartData = [];
    this.dataService.chartData2 = [];
    this.dataService.pageMetrics.forEach(element => {
      cats.push(element.thedate);
      if (this.chartMetric1 === 'Page Views') {
        this.dataService.chartData.push(element.view_count);
      }
      if (this.chartMetric1 === 'Pages Per Visit') {
        this.dataService.chartData.push(element.pages_per_visit);
      }
      if (this.chartMetric1 === 'Average Duration') {
        this.dataService.chartData.push(element.avg_duration);
      }
      if (this.chartMetric1 === 'New Visits') {
        this.dataService.chartData.push(element.new_visits);
      }

      if (this.chartMetric2 === 'Page Views') {
        this.dataService.chartData2.push(element.view_count);
      }
      if (this.chartMetric2 === 'Pages Per Visit') {
        this.dataService.chartData2.push(element.pages_per_visit);
      }
      if (this.chartMetric2 === 'Average Duration') {
        this.dataService.chartData2.push(element.avg_duration);
      }
      if (this.chartMetric2 === 'New Visits') {
        this.dataService.chartData2.push(element.new_visits);
      }

    });
  
    Highcharts.setOptions({
      //colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
      colors: ['#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
    });

    this.chartOptions = {
      series: [{
        name: this.chartMetric1,
        data: this.dataService.chartData,
      },{
        name: this.chartMetric2,
        //type: 'column',
        data: this.dataService.chartData2,
      } ],
      chart: {
        type: "areaspline",
        borderColor: '#EBBA95',
        borderRadius: 10,
        borderWidth: 2,
        zoomType: 'x',
        plotBorderWidth: 1,
        spacingTop: 60,
        //backgroundColor: '#2c3257',
        backgroundColor: {
          linearGradient: [0, 0, 200, 500, 500],
          stops: [
              [0, '#2c1987'],
              [1, '#2c3257']
          ]   
        },
      },
      xAxis: {
        categories: cats
      },
     
      labels: {
        align: 'right',
        x: -10,
        y: 0
      },
      legend:{
        align: 'center',
        verticalAlign: 'bottom',
        floating: false,
        backgroundColor: '#FFFFFF',   

   },
      title: {
        text: "",
      },
      subtitle: {
        text: ''
      }
    };
  }

  datesUpdated(event) {
    //debugger;
    //console.log({ event });

    if (event.startDate) {
    
        this.dataService.startDate = event.startDate;
        this.dataService.endDate = event.endDate;

        localStorage.setItem('startDate', event.startDate) ;
        localStorage.setItem('endDate', event.endDate) ;

        this.loadMetrics();
        this.dataService.loadCompanyBreakdown();
        this.dataService.loadBreakdown();

    }

  }
}
