import { Action } from '@ngrx/store';
import { LinkedinAd } from '../models/linkedinAd.model';

export enum LinkedinAdActionType {
  ADD_ITEM = '[LinkedinAd] Add linkedinAd',
  REMOVE_ITEM = '[LinkedinAd] Remove linkedinAd',
}

export class AddLinkedinAdAction implements Action {
  readonly type = LinkedinAdActionType.ADD_ITEM;
  constructor(public payload: LinkedinAd) {}
}

export class RemoveLinkedinAdAction implements Action {
    readonly type = LinkedinAdActionType.REMOVE_ITEM;
    constructor() {}
  }

export type LinkedinAdAction = AddLinkedinAdAction | RemoveLinkedinAdAction;