import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { take, takeUntil, first, map, filter } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { AccountService } from '@app/_services';
import { CampaignService } from '@app/_services';
import { LinkedIn } from 'ng2-cordova-oauth/core';
import { OauthBrowser } from 'ng2-cordova-oauth/platform/browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import { Campaign } from '@app/store/models/campaign.model';
import { LinkedinAd } from '@app/store/models/linkedinAd.model';
import { Setting } from '@app/store/models/setting.model';
import { AppState } from '@app/store/models/state.model';
import { AddCampaignAction } from '@app/store/actions/campaigns.actions';
import { AddLinkedinAdAction, RemoveLinkedinAdAction } from '@app/store/actions/linkedinAds.actions';
import { AddSettingAction } from '@app/store/actions/settings.actions';

import { environment } from '@environments/environment';

interface Industry {
  id: number;
  name: string;
}

interface AdAccount {
  id: number;
  name: string;
  companyUrn: string;
}

interface CheckBoxType {
  name: string;
  checked: boolean;
}
@Component({
  selector: 'app-campaign-creator',
  templateUrl: './campaign-creator.component.html',
  styleUrls: ['./campaign-creator.component.scss'],
})
export class CampaignCreatorComponent implements OnInit, AfterViewInit, OnDestroy {
  campaigns$: Observable<Campaign>;
  linkedinAds$: Observable<LinkedinAd>;
  settings$: Observable<Setting>;

  isStartModalOpen: boolean = false;
  isBudgetOpen: boolean = false;
  selectedSetting: number = 0;
  campaignName: string = '';
  individualAdId: number = 0;
  settingId: number = 0;

  auth2: any;
  @ViewChild('loginRef') loginElement: ElementRef;
  googleAuthId: string = '';

  private oauth: OauthBrowser = new OauthBrowser();
  private linkedinProvider: LinkedIn = new LinkedIn({
    clientId: environment.linkedinClientId,
    appScope: ["r_basicprofile","email","profile","r_organization_admin", "w_member_social", "rw_organization_admin", "w_organization_social", "rw_ads", "r_ads_reporting"],
    redirectUri: "https://dev.reportrover.com/leads/campaign-creator",
    responseType: 'code',
    state: this.generateState(16)
  });

  linkedinToken: string = '';
  linkedinAccessCode: string = '';
  linkedinId: string = '';
  fbToken: string = '';

  isConnected: boolean = false;
  isAdInfoShow: boolean = false;

  platform: string = '';
  platformId: string = '';

  revenues: CheckBoxType[] = [];

  selectedRevenues: string[] = [];
  isRevenueSaved: boolean = false;

  employees: CheckBoxType[] = [];

  selectedEmployees: string[] = [];
  isEmployeeSaved: boolean = false;

  adSettings: Array<number> = [0, 1];
  selectedAdSetting: number = 0;

  color: ThemePalette = 'primary';

  industries: Industry[] = [];
  adAccounts: AdAccount[] = [];

  protected selectedIndustries: Industry[];

  protected googleBudget: number = 0;
  protected googleThreshold: number = 0;
  protected linkedinBudget: number = 0;
  protected linkedinThreshold: number = 0;
  public formattedBudget: string = '$00.00';
  public formattedThreshold: string = '$00.00';
  protected fbBudget: number = 0;
  protected fbThreshold: number = 0;

  linkedinAds: [] = [];

  public industryMultiCtrl: FormControl = new FormControl();
  public industryMultiFilterCtrl: FormControl = new FormControl();
  public filteredIndustriesMulti: ReplaySubject<Industry[]> = new ReplaySubject<
    Industry[]
  >(1);
  public isIndustrySaved: boolean = false;

  public adAccountCtrl: FormControl = new FormControl();
  public adAccountFilterCtrl: FormControl = new FormControl();
  public filteredAdAccount: ReplaySubject<AdAccount[]> = new ReplaySubject<
    AdAccount[]
  >(1);

  @ViewChild('multiSelect') multiSelect: MatSelect;

  protected _onDestroy = new Subject<void>();

  constructor (
    private router: Router,
    private cdr: ChangeDetectorRef,
    private accountService: AccountService,
    private campaignService: CampaignService,
    private http: HttpClient,
    private store: Store<AppState>
  ) {}

  ngOnInit() {
    this.googleAuthSDK();

    const googleId = localStorage.getItem('google-auth-id');
    if (googleId) {
      this.platform = 'google';
      this.accountService.getPlatformAuthStatus(googleId, 'google')
        .subscribe((data: any) => {
          if (data.id > 0) {
            this.googleAuthId = data.platform_id;
            this.googleBudget = data.daily_budget;
            this.googleThreshold = data.budget_threshold;
          }
        });
    }

    const linkedinAccessCode = localStorage.getItem('linkedin-access-code');
    if (linkedinAccessCode) {
      this.platform = 'linkedin';
      this.accountService.getLinkedinProfile(linkedinAccessCode)
      .subscribe((data: any) => {
        this.accountService.getPlatformAuthStatus(data.id, 'linkedin')
          .subscribe((data: any) => {
            if (data.id > 0) {
              this.isAdInfoShow = data.ad_account_id > 0 ? false : true;
              this.isConnected = true;
              this.linkedinId = data.platform_id;
              this.linkedinBudget = data.daily_budget;
              this.linkedinThreshold = data.budget_threshold;
              this.formattedBudget = '$' + Number(data.daily_budget).toFixed(2);
              this.formattedThreshold = '$' + Number(data.budget_threshold).toFixed(2);

              this.campaignService.getIndividualAds(data.platform_id)
                .subscribe((data: any) => {
                  this.linkedinAds = data.results;
                });
            }
          });
      });
    }

    this.campaignService.getRevenues()
      .subscribe((data: any) => {
        data.results.map((result) => {
          result.checked = false;
        });

        this.revenues = data.results;
      });

    this.campaignService.getEmployees()
      .subscribe((data: any) => {
        data.results.map((result) => {
          result.checked = false;
        });

        this.employees = data.results;
      });

    this.campaignService.getIndustries()
      .subscribe((data: any) => {
        this.industries = data.results;

        // load the initial bank list
        this.filteredIndustriesMulti.next(this.industries.slice());

        // listen for search field value changes
        this.industryMultiFilterCtrl.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterIndustriesMulti();
          });
      });

    if (linkedinAccessCode) {
      this.campaignService.getAdAccounts(linkedinAccessCode)
        .subscribe((data: any) => {
          this.adAccounts = data.results;
          this.filteredAdAccount.next(this.adAccounts.slice());
          this.adAccountFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
              this.filterAdAccount();
            });
        });
    }

    // this.store.pipe(select('campaigns')).subscribe((data: Campaign) => {
    //   console.log(data);
    // });

    this.store.pipe(select('linkedinAds')).subscribe((data: LinkedinAd) => {
      this.campaignName = data.name;
      this.selectedSetting = data.adPreference;
      this.individualAdId = data.id;
    });

    this.store.pipe(select('settings')).subscribe((data: Setting) => {
      this.settingId = data.id;
      this.selectedAdSetting = data.launchPreference;

      this.revenues.forEach((revenue) => {
        if (data.revenue.includes(revenue.name)) {
          revenue.checked = true;
        }
      });
      this.revenueChange();

      this.employees.forEach((employee) => {
        if (data.employees.includes(employee.name)) {
          employee.checked = true;
        }
      });
      this.employeeChange();

      this.industryMultiCtrl.setValue(data.industries);
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected setInitialValue() {
    this.filteredIndustriesMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.multiSelect.compareWith = (a: Industry, b: Industry) =>
          a && b && a.id === b.id;
      });
  }

  protected filterIndustriesMulti() {
    if (!this.industries) {
      return;
    }
    // get the search keyword
    let search = this.industryMultiFilterCtrl.value;
    if (!search) {
      this.filteredIndustriesMulti.next(this.industries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredIndustriesMulti.next(
      this.industries.filter(
        (industry) => industry.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  protected filterAdAccount() {
    if (!this.adAccounts) {
      return;
    }
    // get the search keyword
    let search = this.adAccountFilterCtrl.value;
    if (!search) {
      this.filteredAdAccount.next(this.adAccounts.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the AdAccounts
    this.filteredAdAccount.next(
      this.adAccounts.filter(
        (account) => account.name.toLowerCase().indexOf(search) > -1
      )
    );
  }

  callLogin() {
    this.auth2.attachClickHandler(this.loginElement?.nativeElement, {},
      (googleAuthUser: any) => {
        const profile = googleAuthUser.getBasicProfile();
        const token = googleAuthUser.getAuthResponse().id_token;
        this.googleAuthId = profile.getId();
        localStorage.setItem('google-auth-id', this.googleAuthId);
        const name = profile.getName();
        const email = profile.getEmail();
        this.platform = 'google';

        this.accountService.addCampaignPlatform(this.googleAuthId, 'google')
          .pipe(first())
          .subscribe({
            next: (res: string) => {
              console.log(res);
            },
            error: () => {
              console.log('Error');
            }
          });

        this.cdr.detectChanges();
      }, (error: any) => {
        alert(JSON.stringify(error, undefined, 2));
      });
  }

  googleAuthSDK() {
    (<any>window)['googleSDKLoaded'] = () => {
      (<any>window)['gapi'].load('auth2', () => {
        this.auth2 = (<any>window)['gapi'].auth2.init({
          client_id: environment.googleClientId,
          plugin_name:'login',
          cookiepolicy: 'single_host_origin',
          scope: 'profile email'
        });
        this.callLogin();
      });
    }

    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement('script');
      js.id = id;
      js.src = "https://apis.google.com/js/platform.js?onload=googleSDKLoaded";
      fjs?.parentNode?.insertBefore(js, fjs);
    }(document, 'script', 'google-jssdk'));
  }

  linkedinLogin() {
    debugger;
    this.oauth.logInVia(this.linkedinProvider).then((success) => {
        this.linkedinToken = success['code'];
        localStorage.setItem('linkedin-auth-code', this.linkedinToken);

        this.getAccessToken();
    }, (error) => {
        console.log(error);
        console.log(JSON.stringify(error));
    });
  }

  fbLogin() {
    this.fbToken = "abcd1234";
  }

  getAccessToken() {
    const headers = new HttpHeaders({
      'Content-Type': `application/x-www-form-urlencoded`,
    });

    const body = new URLSearchParams();
    body.set('grant_type', 'authorization_code');
    body.set('code', this.linkedinToken);
    body.set('client_id', environment.linkedinClientId);
    body.set('client_secret', environment.linkedinClientSecret);
    body.set('redirect_uri', 'https://dev.reportrover.com/leads/campaign-creator');

    this.http.post('https://api.linkedin.com/oauth/v2/accessToken', body.toString(), { headers: headers })
      .subscribe((response: any) => {
        // Handle the response data here
        this.linkedinAccessCode = response.access_token;
        localStorage.setItem('linkedin-access-code', response.access_token);

        this.getLinkedinProfile(response.access_token);
      }, error => {
        console.error('Error fetching organization info:', error);
      });
  }

  getLinkedinProfile(accessCode: string) {
    this.accountService.getLinkedinProfile(accessCode)
      .subscribe((data: any) => {
        this.linkedinId = data.id;
        this.platform = 'linkedin';

        this.accountService.addCampaignPlatform(data.id, 'linkedin')
          .pipe(first())
          .subscribe({
            next: (res: string) => {
              this.isAdInfoShow = res[0]['ad_account_id'] > 0 ? false : true;

              this.campaignService.getAdAccounts(accessCode)
                .subscribe((data: any) => {
                  this.adAccounts = data.results;
                  this.filteredAdAccount.next(this.adAccounts.slice());
                  this.adAccountFilterCtrl.valueChanges
                    .pipe(takeUntil(this._onDestroy))
                    .subscribe(() => {
                      this.filterAdAccount();
                    });
                });
            },
            error: () => {
              console.log('Error');
            }
          });
      });
  }

  storeBudget(platform: string) {
    const platformId = platform === 'google' ? this.googleAuthId : this.linkedinId;
    const budget = platform === 'google' ? this.googleBudget : this.linkedinBudget;
    const threshold = platform === 'google' ? this.googleThreshold : this.linkedinThreshold;
    this.platformId = platformId;

    this.campaignService.storeBudget(platformId, platform, budget, threshold)
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          this.store.dispatch(new AddCampaignAction({
            platformId: platformId,
            dailyBudget: budget,
            threshold: threshold,
            platform: platform
          }));

          this.store.dispatch(new RemoveLinkedinAdAction());
        },
        error: () => {
          console.log('Error');
        }
      });

    this.openStartModal();
  }

  openStartModal(platformId: string = 'linkedin') {
    this.platformId = platformId;
    this.isStartModalOpen = true;
    this.campaignName = '';
    this.cdr.detectChanges();
  }

  onStartOpenChange(isOpen: boolean) {
    this.isStartModalOpen = isOpen;
    this.cdr.detectChanges();
  }

  openBudgetModal() {
    this.isBudgetOpen = true;
    this.cdr.detectChanges();
  }

  onBudgetModalOpenChange(isOpen: boolean) {
    this.isBudgetOpen = isOpen;
    this.cdr.detectChanges();
  }

  changeSetting(option: number) {
    this.selectedAdSetting = option;
  }

  saveSetting() {
    const selectedIndustryIds = this.industryMultiCtrl.value.map((industry) => {
      return industry.id;
    });

    const formData = {
      'linkedin_ad_id': this.individualAdId,
      'launch_preference': this.selectedAdSetting,
      'revenue': JSON.stringify(this.selectedRevenues),
      'employees_number': JSON.stringify(this.selectedEmployees),
      'industries': JSON.stringify(selectedIndustryIds),
    };

    if (this.settingId > 0) {
      this.campaignService.updateCustomSetting(this.settingId, formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            if (res['affectedRows'] > 0) {
              this.store.dispatch(new AddSettingAction({
                id: this.settingId,
                linkedinAdId: this.individualAdId,
                launchPreference: this.selectedAdSetting,
                revenue: this.selectedRevenues,
                employees: this.selectedEmployees,
                industries: this.industryMultiCtrl.value
              }));

              this.router.navigate(["/leads/campaign-creator/first-step"]);
            }
          },
          error: () => {
            console.log('Error');
          }
        });
    } else {
      this.campaignService.insertCustomSetting(formData)
        .pipe(first())
        .subscribe({
          next: (res) => {
            if (res['insertId'] > 0) {
              this.store.dispatch(new AddSettingAction({
                id: res['insertId'],
                linkedinAdId: this.individualAdId,
                launchPreference: this.selectedAdSetting,
                revenue: this.selectedRevenues,
                employees: this.selectedEmployees,
                industries: this.industryMultiCtrl.value
              }));

              this.router.navigate(["/leads/campaign-creator/first-step"]);
            }
          },
          error: () => {
            console.log('Error');
          }
        });
    }
  }

  editCustomSetting(index: number) {
    const data = this.linkedinAds[index];

    this.campaignService.getCustomSetting(data['id'])
      .subscribe((setting: any) => {
        const filteredIndustries = this.industries.filter((industry) => {
          return JSON.parse(setting.results.industries).includes(industry.id);
        });
        this.industryMultiCtrl.setValue(filteredIndustries);

        this.store.dispatch(new AddSettingAction({
          id: setting.results.id,
          linkedinAdId: setting.results.linkedin_ad_id,
          launchPreference: setting.results.launch_preference,
          revenue: JSON.parse(setting.results.revenue),
          employees: JSON.parse(setting.results.employees_number),
          industries: this.industryMultiCtrl.value,
          introText: setting.results.intro_text,
          image: setting.results.image,
          headline: setting.results.headline,
          destinationUrl: setting.results.destination_url,
          callToAction: setting.results.call_to_action
        }));
      })

    this.store.dispatch(new AddLinkedinAdAction({
      id: data['id'],
      name: data['name'],
      pageUrl: data['page_url'],
      timeframe: data['timeframe'],
      adPreference: data['ad_preference'],
      platformid: data['platform_id']
    }));
  }

  revenueChange() {
    this.selectedRevenues = this.revenues
      .filter((revenue) => revenue.checked)
      .map((revenue) => revenue.name);
  }

  employeeChange() {
    this.selectedEmployees = this.employees
      .filter((employee) => employee.checked)
      .map((employee) => employee.name);
  }

  saveRevenue() {
    this.isRevenueSaved = true;
  }

  showRevenue() {
    this.isRevenueSaved = false;
  }

  saveEmployee() {
    this.isEmployeeSaved = true;
  }

  showEmployee() {
    this.isEmployeeSaved = false;
  }

  saveIndustry() {
    this.isIndustrySaved = true;
  }

  showIndustry() {
    this.isIndustrySaved = false;
  }

  saveAdAccountInfo() {
    this.campaignService.storeAdAccountInfo(this.linkedinId, 'linkedin', this.adAccountCtrl.value.id, this.adAccountCtrl.value.reference)
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          if (res['affectedRows'] > 0) {
            this.isAdInfoShow = false;
          }
        },
        error: () => {
          console.log('Error');
        }
      });
  }

  updateAdStatus(ad: any, isActive: boolean) {
    this.campaignService.changeLinkedinCampaignStatus(ad.id, ad.linkedin_campaign_id, localStorage.getItem('linkedin-access-code'), isActive ? 'ACTIVE' : 'ARCHIVED')
      .pipe(first())
      .subscribe({
        next: (res: string) => {
          ad.status = isActive ? 'active' : 'archived';
        },
        error: () => {
          console.error('Error');
        }
      });
  }

  showBudgetModal() {
    console.log('here');
  }

  private generateState(length: number): string {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
}
