<div class="dashintro" *ngIf="account">  <!-- Move this to component -->
    Report Rover&#8482; Dashboard
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card table-card">
                <div class="row align-items-center mb-4">


                    <div class="col-lg-2">
                        <h3 class="card-title">TOP 5 WARM LEADS</h3>
                    </div>
                    <div class="col">

                        <div class="d-flex align-items-center justify-content-end">

                            <span class="center">Date Range: </span>
                            <span class="center">
                                <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                    <span>
                                        <input class="no-border" opens="left" type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                        [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>
                    <div class="dashboard-isp">
                        <mat-checkbox [checked]="dataService.includeISPs" (change)="markISP($event)">Include ISPs?</mat-checkbox>
                        <span class="ttip ms-4" matTooltip="ISPs are Internet Service Providers that provide individuals and organizations access to the internet and other related services. ISPs. An individual person looking at a webpage cannot be identified because of the privacy of that individual and their ISP."><i class="fa fa-circle-info"></i></span>
                    </div>
                    <!--<div class="col-lg-6">
                        <div class="exportButton" style="float: right">
                            <button class="export btn-common" >
                                <span>Export</span>
                                <img class="img-fluid" src="/assets/icons/RR_Export_Icon_White.svg" alt="Export">
                            </button>
                        </div>
                    </div>-->
                </div>
                <div class="row" >
                    <table class="table table-striped table-borderless table-hover" *ngIf="dataService.companyLeads.results.length > 0">
                        <thead class="rr_tableheaders">
                            <tr>
                                <td scope="col">Company Name</td>
                                <td scope="col">City</td>
                                <td scope="col">State</td>
                                <td scope="col">
                                    <a class="sort-btn btn-sort"  id="company_employees_sort" (click)="sortLeads('company_employees')">
                                        <span>Employees</span>
                                    </a>
                                </td>
                                <td scope="col">
                                    <a class="sort-btn btn-sort"  id="company_revenue_sort" (click)="sortLeads('company_revenue')">
                                        <span>Revenue</span>
                                    </a>
                                </td>
                                <td scope="col">
                                    <a class="sort-btn btn-sort"  id="session_count_sort" (click)="sortLeads('session_count')">
                                        <span>Sessions</span>
                                    </a>
                                </td>
                                <td scope="col">
                                    <a class="sort-btn btn-sort sort-active" id="page_count_sort" (click)="sortLeads('page_count')">
                                        <span>Page Views</span>
                                    </a>
                                </td>
                                <!--<td class="align-middle" scope="col">User Journey</td>-->
                                <!-- <td class="align-middle" scope="col">View</td> -->
                            </tr>
                        </thead>
                        <tbody style="font-family: Arial, Helvetica, sans-serif; font-size: 1em; font-weight: 500;">
                            <tr *ngFor="let lead of dataService.companyLeads.results | paginate: { itemsPerPage: 5, currentPage: 1 };" [class.is-isp]="lead.isisp">
                                {{ randomService.pickRandomCompany() }}

                                <td *ngIf="dataService.currentOrg.org_id !== 'demo'" (click)="leadBreakdown(lead)"> {{ lead.company_name }} </td>
                                <td *ngIf="dataService.currentOrg.org_id === 'demo'" (click)="leadBreakdownDemo(lead, randomService.lastSelectedCompany.name )">{{ randomService.lastSelectedCompany.name }}</td>
                                <td *ngIf="dataService.currentOrg.org_id !== 'demo'" (click)="leadBreakdown(lead)">{{ lead.company_city }}</td>
                                <td *ngIf="dataService.currentOrg.org_id === 'demo'" (click)="leadBreakdownDemo(lead, randomService.lastSelectedCompany.name )">{{ randomService.lastSelectedCompany.city }}</td>
                                <td *ngIf="dataService.currentOrg.org_id !== 'demo'" (click)="leadBreakdown(lead)">{{ lead.company_region }}</td>
                                <td *ngIf="dataService.currentOrg.org_id === 'demo'" (click)="leadBreakdownDemo(lead, randomService.lastSelectedCompany.name )">{{ randomService.lastSelectedCompany.state }}</td>
                                <!-- <td (click)="leadBreakdown(lead)"> {{ lead.company_city }} </td> -->
                                <!-- <td (click)="leadBreakdown(lead)"> {{ lead.company_region }} </td> -->
                                <td (click)="leadBreakdown(lead)"> {{ lead.company_employees }} </td>
                                <td (click)="leadBreakdown(lead)"> {{ lead.company_revenue }} </td>
                                <td (click)="leadBreakdown(lead)"> {{ lead.session_count }} </td>
                                <td (click)="leadBreakdown(lead)"> {{ lead.page_count}} </td>
                            <!-- <td>
                                    <a (click)="showJourney(lead, randomService.lastSelectedCompany)" class="content_img" data-toggle="tooltip" title="Session Journey">
                                        <i class='fa fa-route' style='font-size:20px;color:gray' ></i>
                                    </a>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>

                    <table class="table table-striped table-borderless"  *ngIf="dataService.companyLeads.results.length === 0">
                        <thead>
                            <tr>
                                <td scope="col-12">
                                    No data available for those dates
                                </td>

                            </tr>

                        </thead>


                    </table>
                </div>
                <div class="cardFoot" *ngIf="dataService.companyLeads.results.length > 0">
                    <a class="btn-common btn-wide" routerLink="/leads/lead-view" >View All Leads</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="container-fluid mb-4">
    <div class="row">
        <!-- <div class="col-4 d-flex left-org-map">
            <app-org-map></app-org-map>
        </div> -->
        <div class="col-12">
            <app-snapshot></app-snapshot>
        </div>
    </div>
</div>

<div class="container-fluid mb-4">
    <div class="row">
        <div class="col-12">
            <app-adpreview></app-adpreview>
        </div>
    </div>
</div>
