<div class="row" style="margin-bottom: 10px;">
    <div class="col-lg-12">  
        <div class="card table-card">
            <div class="row mb-4">
            
                <div class="col-lg-6">
                    <div class="d-flex align-items-center">
                        <h3 class="card-title">Website Metrics</h3>
                        <span class="ttip ms-2" matTooltip="The graph below compares two metrics within a specific date range. The chart can be used to measure gaps in your websites performance or trends in web-site visitors.">
                            <i class="fa fa-circle-info"></i>
                        </span>
                    </div>
                </div>
                <div class="col-lg-6">
                    <span class="d-flex justify-content-end">
                        <span class="center">Dates</span>
                        <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                            <span><input class="no-border" type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                    [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" /></span>
                        </button>
                    </span>
                </div>
               
            </div>

            <div class="row mb-4 justify-content-center align-items-center">
                <div class="col-auto">
                    <div class="chartDD dropdown">
                        <button class="cDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{chartMetric1}}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" (click)="changeMetric1('Page Views')" >Page Views</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric1('Pages Per Visit')">Pages Per Visit</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric1('Average Duration')">Average Duration</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric1('New Visits')">New Visits</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-auto">
                    <p class="comparedTo">Compared to:</p>
                </div>
                <div class="col-lg-auto">
                    <div class="chartDD dropdown">
                        <button class="cDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{chartMetric2}}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" (click)="changeMetric2('Page Views')" >Page Views</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric2('Pages Per Visit')">Pages Per Visit</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric2('Average Duration')">Average Duration</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric2('New Visits')">New Visits</a></li>
                        </ul>
                    </div>
                </div>      
        
            </div>

            <!-- <div class="row justify-content-between align-items-center">
                <div class="col-lg-6 center">
                    <div class="chartDD ms-3 dropdown" style="top: 70px; z-index: 1">
                        <button class="cDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{chartMetric1}}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" (click)="changeMetric1('Page Views')" >Page Views</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric1('Pages Per Visit')">Pages Per Visit</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric1('Average Duration')">Average Duration</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric1('New Visits')">New Visits</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 center">
                    <div class="chartDD ms-3 dropdown" style="top: 70px; z-index: 1">
                        <button class="cDDbtn btn btn-common btn-white dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <span>{{chartMetric2}}</span>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            <li><a class="dropdown-item" (click)="changeMetric2('Page Views')" >Page Views</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric2('Pages Per Visit')">Pages Per Visit</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric2('Average Duration')">Average Duration</a></li>
                            <li><a class="dropdown-item" (click)="changeMetric2('New Visits')">New Visits</a></li>
                        </ul>
                    </div>
                </div>      
          
            </div> -->
        


            <div class="areachart-wrapper">
                <highcharts-chart *ngIf="chartOptions"
                [Highcharts]="Highcharts"
                [options]="chartOptions"
                style="width: 100%; height: 40vh; display: block;">
                </highcharts-chart>

                <!--    <ngx-charts-line-chart
                [scheme]="colorScheme"
                [legend]="legend"
                [legendPosition]="legendPosition"
                [showXAxisLabel]="showXAxisLabel"
                [showYAxisLabel]="showYAxisLabel"
                [gradient]="gradient"
                [xAxis]="xAxis"
                [yAxis]="yAxis"
                [xAxisLabel]="xAxisLabel"
                [yAxisLabel]="yAxisLabel"
                [timeline]="timeline"
                [results]="multi"
                (select)="onSelect($event)"
                (activate)="onActivate($event)"
                (deactivate)="onDeactivate($event)"
                
                >
                </ngx-charts-line-chart>-->
            </div>
        </div>
    </div>
</div>


