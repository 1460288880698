import { NgModule } from "@angular/core";
import { DurationPipe } from '@app/pipes/duration.pipe';

@NgModule({
    imports: [],
    exports: [DurationPipe],
    declarations: [
        DurationPipe
    ]
})
export class DurationPipeModule {

}