


<!--<div class="row justify-content-between align-items-center">
    <div class="col"></div>
    <div class="col-auto">
        <div class="dateControl d-flex align-items-center">

        </div>
    </div>
</div>-->
<div class="dashintro">  <!-- Move this to component -->
	<div class="row">
		<div class="col-12">
			{{dataService.lead.name}}
		</div>
	</div>
</div>

<div class="container-fluid">
    <div class="row">

        <div class="col-lg-12">
            <div class="card table-card">
                <div class="row mb-3 breadcrumbs-wrap">
                    <h6><a style="color: #FFB23E" routerLink="/leads/lead-view">Lead View</a> > {{dataService.lead.name}}</h6>
                </div>
                <div class="row mb-4 justify-content-between">
                    <div class="col-7">
                        <h3 class="card-title">Session Journey</h3>
                    </div>


                    <div class="col-auto">

                        <div class="d-flex align-items-right justify-content-end">
                            <span class="center">Date Range</span>
                            <span class="center">
                                <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                    <span>
                                        <input class="no-border" opens="left"type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                        [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                    </span>
                                </button>
                            </span>
                        </div>
                    </div>

                    <div class="col-auto" >

                        <div class="input-group">
                            <div class="form-outline searchBox">
                                <input type="search" id="searchInput2" class="form-control btn-light rr_search" placeholder="Search" [(ngModel)]="searchValue" />
                                <div class="search-icon">
                                    <i class="fas fa-search"></i>
                                </div>
                            </div>
                            <!--<button type="button" class="btn btn-light">
                                <i class="fas fa-search"></i>
                            </button>-->
                        </div>

                    </div>

                </div>

                <div class="row mb-4">
                    <div class="col-6">
                        <div class="row rr_pager">
                            <div class="col-auto">
                                View:
                            </div>
                            <div [ngClass]="itemsperpage === 25 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'"  (click)="itemsPerPageChange(25)">
                                25
                            </div>
                            <div [ngClass]="itemsperpage === 50 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'" (click)="itemsPerPageChange(50)">
                                50
                            </div>
                            <div [ngClass]="itemsperpage === 100 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'"  (click)="itemsPerPageChange(100)">
                                100
                            </div>
                        </div>
                    </div>

                    <div class="col-6">

                        <div class="d-flex align-items-right justify-content-end">
                            <div class="me-5">

                            </div>
                        </div>
                    </div>

                </div>

                <div class="row" style="height: 10px"></div>

                <!--<table class="table table-striped table-borderless table-hover">-->
                <table class="table table-responsive highlight-eo stickyths">

                    <thead>
                        <tr>
                            <td scope="col" class="col-2">Session Start</td>
                            <td scope="col" class="col-2">Session End</td>
                            <td scope="col" class="col-4">Page Visited</td>
                            <td scope="col" class="col-2">Source/Medium</td>
                            <!-- <td scope="col"></td> -->
                            <td scope="col" class="col-1">
                                User Journey
                            </td>
                            <td scope="col" class="col-1"></td>


                        </tr>
                    </thead>
                    <tbody>
                        <!--<tr
                            *ngFor="let lead of companyLeads.results | leadSort:'page_count' | paginate: { itemsPerPage: companyLeads.limit, currentPage: companyLeads.page, totalItems: companyLeads.count }"
                            [class.is-isp]="lead.isisp"
                            routerLink="/lead-breakdown"
                        >-->
                        <!--<tr *ngFor="let lead of dataService.companyLeads.results | paginate: { itemsPerPage: dataService.companyLeads.limit, currentPage: dataService.companyLeads.page, totalItems: dataService.companyLeads.count }" -->
                        <ng-container *ngFor="let session of dataService.sessions | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords } ; index as i" >
                            <tr (click)="toggleDisplay(session.session_id, session)" class="highlight-eo-tr" id={{session.session_id}}>
                                <td class="col-2"> {{ session.session_start | date: 'medium'}} </td>
                                <td class="col-2"> {{ session.session_end | date: 'medium' }} </td>
                                <td  class="col-3" style="text-align: left"> {{ session.start_page_title}}</td>
                                <td class="col-2">{{session.referral_source}}/{{session.referral_medium}} </td>
                                <!--<td  class="col-2" *ngIf="session.referral_source==='organic'">{{session.referral_source}}/{{session.referral_medium}}</td>
                                <td class="col-2" *ngIf="session.referral_source==='referral'">{{session.referral_medium}}</td>
                                <td class="col-2" *ngIf="session.referral_source==='same site'">{{session.referral_source}}</td>
                                <td class="col-2" *ngIf="session.referral_source==='error'"></td>
                                <td class="col-2" *ngIf="session.referral_source==='direct'">{{session.referral_source}}</td>-->
                                <td class="col-1"> {{ session.page_visits}} pages </td>
                                <td class="col-1"><div id="{{session.session_id}}_arrow" class="arrow-down"></div></td>

                            </tr>
                            <tr id="row{{session.session_id}}" class="highlight-eo-tr session-inner-table" style="display: none">


                            </tr>
                        </ng-container>


                        <!--<tr *ngFor="let leads of companyLeads.results | leadSort: 'page_count' | paginate: { itemsPerPage: companyLeads.limit, currentPage: companyLeads.page, totalItems: companyLeads.count, id: 'pn2' }"
                            [class.is-isp]="lead.isisp"
                            routerLink="/lead-breakdown"
                        >
                            <td> {{ lead.company_name }} </td>
                            <td> {{ lead.company_city }} </td>
                            <td> {{ lead.company_region }} </td>
                            <td> {{ lead.company_employees }} </td>
                            <td> {{ lead.company_revenue }} </td>
                            <td> {{ lead.session_count }} </td>
                            <td> {{ lead.page_count }} </td>
                            <td><img class="moreicon" src="/assets/icons/RR_Link_Icon_Light_BluePurple.svg" alt="view more"></td>
                        </tr>-->
                    </tbody>
                </table>
                <!--<div class="cardFoot">-->
                <div class="row mt-4 align-items-right justify-content-end">
                    <div class="col-2">
                        <div class="row rr_pager">
                            <div class="col-auto">
                                View:
                            </div>
                            <div [ngClass]="itemsperpage === 25 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'"  (click)="itemsPerPageChange(25)">
                                25
                            </div>
                            <div [ngClass]="itemsperpage === 50 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'" (click)="itemsPerPageChange(50)">
                                50
                            </div>
                            <div [ngClass]="itemsperpage === 100 ? 'col-auto rr_pager_active':'col-auto rr_pager_inactive'"  (click)="itemsPerPageChange(100)">
                                100
                            </div>
                        </div>
                    </div>
                    <div class="col-8"></div>
                    <div class="col-2">
                        <pagination-controls  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--<app-chart-card></app-chart-card>
<app-page-breakdown></app-page-breakdown>

<app-company-info-form [companyInfo]="companyInfo" [companyName]="dataService.lead.name"></app-company-info-form>-->

<!--<app-ga-auth *ngIf="!hasGAToken"></app-ga-auth>-->
