
<div class="card table-card">
  <div class="row align-items-center justify-content-between mb-5">
      <div class="col-lg-4">
          <img src="/assets/icons/RR_Linkedin_Icon.svg" alt="Linkedin Logo">
      </div>

      <!-- <div class="col-1"></div> -->
      <div class="col">
          <div class="d-flex align-items-center justify-content-end">
              <div class="d-flex align-items-center flex-wrap">
                  <span>Date range:</span>
                  <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">

                      <span><input id="snapDate1"  (rangeClicked)="rangeClicked($event)" [(ngModel)]="dataService.selectedSnapDates" class="no-border datePickerLeft" type="text" ngxDaterangepickerMd [ranges]="ranges" [showCustomRangeLabel]="true"
                          [alwaysShowCalendars]="true" opens="right" drops="down" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" [showClearButton]="true"  (datesUpdated)="datesUpdated($event)"/></span>

                  </button>
              </div>
              <div class="d-flex align-items-center flex-wrap ms-4">
                  <span>Compared to: </span>

                  <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                          <input [showClearButton]="false" class="no-border datePickerRight" (clearClicked)="clearClicked($event)" [(ngModel)]="dataService.selectedSnapCompareDates"  type="text" ngxDaterangepickerMd [ranges]="ranges" [showCustomRangeLabel]="true"
                              [alwaysShowCalendars]="true" opens="left" drops="down" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" [showClearButton]="true"  (datesUpdated)="compareDatesUpdated($event)"/>

                  </button>
                  <button class="btn" *ngIf="dataService.selectedSnapCompareDates"><mat-icon matDatepickerToggleIcon (click)="clearClicked($event)">clear</mat-icon></button>
              </div>
          </div>
      </div>
  </div>
  <div class="row" *ngIf="showAdvancedDatePicker">
      <div class="col-lg-12">
          <rr-advanceddatepicker></rr-advanceddatepicker>
      </div>
  </div>
  <div class="row gx-3 mb-4">
      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Impressions" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Clicks" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Average Click-Through-Rate" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Cost" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Average Cost Per Click" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>
  </div>

  <p class="sub-title">LINKEDIN TOP PERFORMING CAMPAIGNS</p>

  <table class="table table-yellow stickyths">
      <thead>
          <tr>
              <td scope="col" class="col-2">Page Name or Campaign Name</td>
              <td scope="col" class="col-1">Off/On</td>
              <td scope="col" class="col-1">Status</td>
              <td scope="col" class="col-1">Impressions</td>
              <td scope="col" class="col-1">Companines in Target</td>
              <td scope="col" class="col-1">Clicks</td>
              <td scope="col" class="col-1">Click Through Rate (CTR) %</td>
              <td scope="col" class="col-1">Spent (Cost)</td>
              <td scope="col" class="col-1">Avg. Cost Per Click</td>
              <td scope="col" class="col-1">End Date</td>
          </tr>
      </thead>
      <tbody>
          <ng-container>
              <tr>
                  <td class="col-2">Campaign name</td>
                  <td class="col-1">
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" checked>
                      </div>
                  </td>
                  <td class="col-1">
                      <div class="status">
                          <div class="status-active"></div>
                          <span>Active</span>
                      </div>
                  </td>
                  <td class="col-1">00,000</td>
                  <td class="col-1">000</td>
                  <td class="col-1">000</td>
                  <td class="col-1">000%</td>
                  <td class="col-1">$000.00</td>
                  <td class="col-1">$000.00</td>
                  <td class="col-1">00/00/0000</td>
              </tr>
              <tr>
                  <td class="col-1">Campaign name</td>
                  <td class="col-1">
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" checked>
                      </div>
                  </td>
                  <td class="col-1">
                      <div class="status">
                          <div class="status-active"></div>
                          <span>Active</span>
                      </div>
                  </td>
                  <td class="col-1">00,000</td>
                  <td class="col-1">000</td>
                  <td class="col-1">000</td>
                  <td class="col-1">000%</td>
                  <td class="col-1">$000.00</td>
                  <td class="col-1">$000.00</td>
                  <td class="col-1">00/00/0000</td>
              </tr>
              <tr>
                  <td class="col-1">Campaign name</td>
                  <td class="col-1">
                      <div class="form-check form-switch">
                          <input class="form-check-input" type="checkbox" checked>
                      </div>
                  </td>
                  <td class="col-1">
                      <div class="status">
                          <div class="status-active"></div>
                          <span>Active</span>
                      </div>
                  </td>
                  <td class="col-1">00,000</td>
                  <td class="col-1">000</td>
                  <td class="col-1">000</td>
                  <td class="col-1">000%</td>
                  <td class="col-1">$000.00</td>
                  <td class="col-1">$000.00</td>
                  <td class="col-1">00/00/0000</td>
              </tr>
          </ng-container>
      </tbody>
  </table>

  <div class="cardFoot mt-5 mb-0 pt-0" *ngIf="router.url === '/leads/dashboard'">
      <a class="btn-common btn-wide" routerLink="/leads/campaign-performance" >View All Linkedin Campaigns</a>
  </div>
</div>
