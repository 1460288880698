import { Role } from './role';

export class Account {
    id: string;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    role: Role;
    jwtToken?: string;
    jwtRefreshToken?: string;
    user: any;
    userorgs: any;
    currentorg: any;
}