import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { AccountService, LoadingService} from "./_services";
import { Account, Role } from "./_models";
import { DataService } from "@app/_services";
import { LeadsService } from "./_services/leads.service";
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";
import { takeUntil, map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
// import { DatePickerComponent } from './date-picker/date-picker.component';
import "../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import moment, { Moment } from "moment";
import { environment } from "@environments/environment";
import { RandomCompanyService } from "./random-company.service";

@Component({
    selector: "app",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
    providers: [LeadsService, RandomCompanyService]
})


export class AppComponent implements OnInit {
    collapsed: boolean = false;
    envMessage: string = "";
    Role = Role;
    account = this.accountService.accountValue;
    version = environment.version;
    loading: boolean = false;
    searchValue = "";
    queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

    isNotificationModalOpen = false;

    private leadSub = new Subject();
    opens = "left";
    ranges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month")
        ],
        "Last 3 Month": [
          moment()
            .subtract(3, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month")
        ]
      };


    constructor(private accountService: AccountService,
                private loadingService: LoadingService,
                public leadsService: LeadsService,
                public dataService: DataService,
                public randomService: RandomCompanyService,
                private route: ActivatedRoute,
                private router: Router) {
            this.queryOptions.page = 1;
            this.queryOptions.pageSize = 5;
            this.accountService.account.subscribe(x => this.account = x);
            this.envMessage = environment.message;
        }

    myAccount() {
        window.location.href = `${environment.salesdashboard}`;
    }

    showNotifications() {
        this.isNotificationModalOpen = true;
    }

    onNotificationOpenChange(isOpen: boolean) {
        this.isNotificationModalOpen = isOpen;
    }

    logout() {
        this.accountService.logout();
        window.location.href = `${environment.salesdashboard}`;
    }

    showDashboard() {
        this.router.navigate(["/leads/dashboard"], { relativeTo: this.route });
        return false;
    }

    openSettings() {
        this.router.navigate(["/admin/overview"], { relativeTo: this.route });
        return false;
    }

    openProfile() {
        this.router.navigate(["/profile/details"], { relativeTo: this.route });
        return false;
    }


    showLeadView() {
        this.router.navigate(["/leads/lead-view"], { relativeTo: this.route });
        return false;
    }


    showPerformance() {
        this.router.navigate(["/leads/website-performance"], { relativeTo: this.route });
        return false;
    }

    ngOnInit(): void {
        const salesdashboard = `${environment.salesdashboard}`;
        this.listenToLoading();


        // this.accountService.refreshToken()
          //      .subscribe();



        const tempOrg = JSON.parse(localStorage.getItem("currentorg"));

        if (tempOrg) {
            this.dataService.currentOrg = tempOrg;
        } else {
            if (this.account) {
                if (this.account.currentorg) {
                    this.dataService.currentOrg = this.account.currentorg;
                } else {
                    this.dataService.currentOrg = this.account.userorgs[0];
                }
            } else {
                this.dataService.currentOrg = "";
            }

        }

        // if no org, send bak to sale site
        if (!this.dataService.currentOrg) {
            // location.href = `${salesdashboard}`;

        }

        this.dataService.startDate = localStorage.getItem("startDate");
        this.dataService.endDate = localStorage.getItem("endDate");

        this.dataService.startAllDataDate = localStorage.getItem("startAllDataDate");
        this.dataService.endAllDataDate = localStorage.getItem("endAllDataDate");

        this.dataService.startSourceMediumDate = localStorage.getItem("startSourceMediumDate");
        this.dataService.endSourceMediumDate = localStorage.getItem("endSourceMediumDate");

        this.dataService.startReferralDate = localStorage.getItem("startReferralDate");
        this.dataService.endReferralDate = localStorage.getItem("endReferralDate");

        this.dataService.startPageReferralDate = localStorage.getItem("startPageReferralDate");
        this.dataService.endPageReferralDate = localStorage.getItem("endPageReferralDate");

        this.dataService.startSnapDate = localStorage.getItem("startSnapDate");
        this.dataService.endSnapDate = localStorage.getItem("endSnapDate");

        // this.selectedDates = { startDate: this.dataService.startDate, endDate: this.dataService.endDate};
        this.dataService.selectedDates = { startDate: moment(this.dataService.startDate), endDate: moment(this.dataService.endDate) };
        this.dataService.selectedReferralDates = { startDate: moment(this.dataService.startReferralDate), endDate: moment(this.dataService.endReferralDate) };
        this.dataService.selectedPageReferralDates = { startDate: moment(this.dataService.startPageReferralDate), endDate: moment(this.dataService.endPageReferralDate) };
        this.dataService.selectedSourceMediumDates = { startDate: moment(this.dataService.startSourceMediumDate), endDate: moment(this.dataService.endSourceMediumDate) };
        this.dataService.selectedSnapDates = { startDate: moment(this.dataService.startSnapDate), endDate: moment(this.dataService.endSnapDate) };

        // if compare dates are null
        if (localStorage.getItem("startSnapCompareDate") !== "undefined" && localStorage.getItem("startSnapCompareDate") !== "null") {
            this.dataService.startSnapCompareDate = localStorage.getItem("startSnapCompareDate");
            this.dataService.endSnapCompareDate = localStorage.getItem("endSnapCompareDate");
            this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) };
        } else {

            this.dataService.startSnapCompareDate = moment(this.dataService.startSnapDate).subtract(7, "days").toISOString().substring(0,10);
            this.dataService.endSnapCompareDate = moment(this.dataService.endSnapDate).subtract(7,"days").toISOString().substring(0,10);

            this.dataService.selectedSnapCompareDates = { startDate: moment(this.dataService.startSnapCompareDate), endDate: moment(this.dataService.endSnapCompareDate) };

            localStorage.setItem("startSnapCompareDate", this.dataService.startSnapCompareDate) ;
            localStorage.setItem("endSnapCompareDate", this.dataService.endSnapCompareDate) ;
            // this.dataService.selectedCompareDates = { startDate: null, endDate: null }
        }
    }


    listenToLoading(): void {
        this.loadingService.loadingSub
            .pipe()
            .subscribe((loading: boolean) => {
                this.loading = loading;
            });
    }

    changeOrg(org: any): void {
        this.dataService.currentOrg = org;
        localStorage.setItem("currentorg", JSON.stringify(org));

        window.location.reload();
    }

/*
        if (this.router.url === '/leads/lead-view') {
            this.changeOrgLeadView(org);
        } else if (this.router.url === '/leads/website-performance') {
            this.changeOrgWebsitePerformance(org);
        } else {
            this.changeOrgDashboard(org);
        }
    }
*/
    changeOrgDashboard(org: any): void {
        this.queryOptions.pageSize = 5;

        var sortOrder:any = "page_count";
        this.dataService.currentOrg = org;
        localStorage.setItem("currentorg", JSON.stringify(org));
        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);
        this.queryOptions.sortOrder = sortOrder;
        this.queryOptions.sortDirection = "desc";

        this.dataService.queryOptions.includeISPs = this.dataService.includeISPs;
        this.queryOptions.includeISPs = this.dataService.includeISPs;

        document.getElementById(`employees_sort`).classList.remove("btn-sort");
        document.getElementById(`revenue_sort`).classList.remove("btn-sort");
        document.getElementById(`page_count_sort`).classList.remove("btn-sort");
        document.getElementById(`session_count_sort`).classList.remove("btn-sort");

        document.getElementById(`employees_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`revenue_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`page_count_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`session_count_sort`).classList.remove("btn-sort-asc");

        document.getElementById(`employees_sort`).classList.add("btn-hollow");
        document.getElementById(`revenue_sort`).classList.add("btn-hollow");
        document.getElementById(`page_count_sort`).classList.add("btn-hollow");
        document.getElementById(`session_count_sort`).classList.add("btn-hollow");

        if (this.queryOptions.sortDirection === "desc") {
            document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort");
        } else {
          document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort-asc");
        }
        document.getElementById(`${sortOrder}_sort`).classList.remove("btn-hollow");

        this.leadsService.getLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
            map(leads => {
                    this.dataService.companyLeads = leads;
                }
            ),
            takeUntil(this.leadSub)
        ).subscribe();

        this.dataService.daterangetype = "Custom";
        this.dataService.compareto = "None";

        this.dataService.loadSnap();
        this.dataService.loadMetrics();

        /* Get the top 5 leads*/
       // this.leadService.getLeads(this.queryOptions, this.account.currentorg.org_id).subscribe(leads => this.companyLeads = leads);
    }

    changeOrgLeadView(org: any): void {
        this.dataService.currentOrg = org;
        localStorage.setItem("currentorg", JSON.stringify(org));
        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);

        this.queryOptions.pageSize = 10;
        this.queryOptions.sortOrder = "page_count";
        this.queryOptions.sortDirection = "desc";
        this.queryOptions.pageSize = 1000000;

        this.dataService.queryOptions.includeISPs = this.dataService.includeISPs;
        this.queryOptions.includeISPs = this.dataService.includeISPs;

        document.getElementById(`employees_sort`).classList.remove("btn-sort");
        document.getElementById(`revenue_sort`).classList.remove("btn-sort");
        document.getElementById(`page_count_sort`).classList.remove("btn-sort");
        document.getElementById(`session_count_sort`).classList.remove("btn-sort");

        document.getElementById(`employees_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`revenue_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`page_count_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`session_count_sort`).classList.remove("btn-sort-asc");

        document.getElementById(`employees_sort`).classList.add("btn-hollow");
        document.getElementById(`revenue_sort`).classList.add("btn-hollow");
        document.getElementById(`page_count_sort`).classList.add("btn-hollow");
        document.getElementById(`session_count_sort`).classList.add("btn-hollow");

        if (this.queryOptions.sortDirection === "desc") {
          document.getElementById(`${this.queryOptions.sortOrder}_sort`).classList.add("btn-sort");
        } else {
          document.getElementById(`${this.queryOptions.sortOrder}_sort`).classList.add("btn-sort");
        }
        document.getElementById(`${this.queryOptions.sortOrder}_sort`).classList.remove("btn-hollow");


        this.leadsService.getLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
            map(leads => {
                    this.dataService.companyLeads = leads;
                    this.dataService.companyLeadsOrig = Array.from(this.dataService.companyLeads.results);
                }
            ),
            takeUntil(this.leadSub)
        ).subscribe();

        this.dataService.loadMetrics();
        this.dataService.loadIndustryMetrics();

    }

    changeOrgWebsitePerformance(org: any): void {
        this.dataService.currentOrg = org;
        localStorage.setItem("currentorg", JSON.stringify(org));

        window.location.reload();





    }





    expand() {
        // debugger;

        var st: any;
        var mn: any;

        st = document.querySelector(".sidenav");
        mn = document.querySelector(".main");


          st.style.width = "15%";
          mn.style.width = "85%";
          mn.style["margin-left"] = "15%";
          this.collapsed = false;

          // localStorage.setItem('collapsed', 'false');
          // this.cookieService.set('collapsed', 'false');
          return false;
      }

      collapse() {
        // debugger;

        var st: any;
        var mn: any;

        st = document.querySelector(".sidenav");
        mn = document.querySelector(".main");

        st.style.width = "3%";
        mn.style.width = "97%";
        mn.style["margin-left"] = "3%";
        this.collapsed = true;

        // localStorage.setItem('collapsed', 'true');
        // this.cookieService.set('collapsed', 'true');

        return false;

      }


}
