import { Injectable, OnInit } from "@angular/core";

@Injectable({
    providedIn: "root",
})

export class LeadViewQueryOptions{
    pageSize = 10;
    page = 1;
    start?: Date;
    end?: Date;
    sortOrder?: string;
    sortDirection?: string;
    includeISPs: boolean;
}
