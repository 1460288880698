import { Action } from '@ngrx/store';
import { Setting } from '../models/setting.model';

export enum SettingActionType {
  ADD_ITEM = '[Setting] Add custom setting',
}

export class AddSettingAction implements Action {

  readonly type = SettingActionType.ADD_ITEM;
  constructor(public payload: Setting) {}

}

export type SettingAction = AddSettingAction;