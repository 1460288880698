import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Account } from '../_models';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RegisterService{

    private theUrl = '${baseUrl}/register';

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    constructor(private httpClient: HttpClient){}

    register(data): Observable<Account>{
        return this.httpClient
            .post<Account>(this.theUrl, data);
    }
}