export class CompanyInfo{
    company_id: number;
    name: string;
    number_of_employees: string;
    revenue: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_title: string;
    contact_department: string;
    contact_phone: string;
    contact_email: string;
    street: string;
    pobox: string;
    city: string;
    state: string;
    country: string;
    zipcode: string;
}