import { Injectable } from "@angular/core";
import { Lead } from "@app/_models";
import { Moment } from "moment";
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";
import { LeadsService } from "@app/_services/leads.service";
import { takeUntil, map } from "rxjs/operators";
import { Observable, Subject } from "rxjs";
import Highcharts from "highcharts/highmaps";
import worldMap from "@highcharts/map-collection/custom/world-continents.geo.json";
import highcharts3D from "highcharts/highcharts-3d";
import { RandomCompanyService } from "@app/random-company.service";




@Injectable()
export class DataService {

    public companyLeads: any;
    public companyLeadsOrig: any;
    public lead: Lead;
    public pageViews: any;
    public pageViewsOrig: any;
    public pageMetrics: any;
    public continentMetrics: any;
    public industryMetrics: any;
    public revenueMetrics: any;
    public employeeMetrics: any;
    public referralMetrics: any;
    public websiteMetrics: any;
    public sourceMediumResults: any;
    public sourceMediumResultsOrig: any;
    public userorgs: any;
    public snapdata: {
      /* bouncerate */
      bouncerate: any,
      bounces1: any,
      bounces2: any,
      validBounceValue: boolean,
      /* session duration */
      sessiondurationpercent: any,
      sessionduration2: any,
      sessionduration1: any,
      validSessionDurationValue: boolean,
      /* session count */
      session_count1: number,
      session_count2: number,
      sessionspercent: any,
      validSessionCountValue: boolean,
      /* pages per session */
      pagespersessionpercent: any,
      pagespersession1: any,
      pagespersession2: any,
      validPagesPerSessionValue: boolean,
      /* sessions per user */
      sessions_per_user1: any,
      sessions_per_user2: any,
      sessionsperuser: any,
      validSessionsPerUserValue: boolean,
      /* page count */
      pageviewspercent: any,
      page_count1: number,
      page_count2: number,
      validPageCountPercentValue: boolean,
      /* new users */
      newuserspercent: any,
      new_user_count1: any,
      new_user_count2: any,
      validNewUserPercentValue: boolean,
      /* users */
      userspercent: any,
      user_count1: any,
      user_count2: any,
      validUserPercentValue: boolean

    };
    public currentOrg: any;
    public startDate: any;
    public endDate: any;
    public startAllDataDate: any;
    public endAllDataDate: any;
    public startSnapDate: any;
    public endSnapDate: any;
    public startSnapCompareDate: any;
    public endSnapCompareDate: any;
    public startReferralDate: any;
    public endReferralDate: any;
    public startPageReferralDate: any;
    public endPageReferralDate: any;
    public startSourceMediumDate: any;
    public endSourceMediumDate: any;
    public daterangetype: any;
    public compareto: any;
    public showSimpleDatePicker: boolean;
    public selectedDates: { startDate: Moment, endDate: Moment };
    public selectedAllDataDates: { startDate: Moment, endDate: Moment };
    public selectedSessionDates: { startDate: Moment, endDate: Moment };
    public selectedSnapDates: { startDate: Moment, endDate: Moment };
    public selectedSnapCompareDates: { startDate: Moment, endDate: Moment };
    public selectedReferralDates: { startDate: Moment, endDate: Moment };
    public selectedPageReferralDates: { startDate: Moment, endDate: Moment };
    public selectedSourceMediumDates: { startDate: Moment, endDate: Moment };
    public chartData: any;
    public chartData2: any;
    public totalrecords: any;
    public referrals: any;
    public sessions: any;
    public sessionsOrig: any;
    public journey: any;
    public journies: any;
    public dialogRef: any;
    public pageTitle: any;
    public pageUrl: any;
    private leadSub = new Subject();

    public allLeads: any;
    public allLeadsOrig: any;

    public includeISPs = false;
    public top5only = false;
    public top5revenueonly = false;
    public top5employeeonly = false;
    public industryCount = 0;
    public employeeCount = 0;
    public revenueCount = 0;
    public referralsCount = 0;

    public currentCompanyJourney: any;
    public companyJourneyData: any;

    Highcharts: typeof Highcharts = Highcharts;

    chartOptions: any;
    chartOptionsIndustry: any;
    chartOptionsRevenue: any;
    chartOptionsEmployees: any;
    chartOptionsReferrals: any;

    chartConstructor = "mapChart";

    queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

    public constructor(
            private leadsService: LeadsService
          ) {

        this.startDate = localStorage.getItem("startDate");
        this.endDate = localStorage.getItem("endDate");

        this.startAllDataDate = localStorage.getItem("startAllDataDate");
        this.endAllDataDate = localStorage.getItem("endAllDataDate");

        this.startSnapDate = localStorage.getItem("startSnapDate");
        this.endSnapDate = localStorage.getItem("endSnapDate");

        this.startSourceMediumDate = localStorage.getItem("startSourceMediumDate");
        this.endSourceMediumDate = localStorage.getItem("endSourceMediumDate");

        this.startSnapCompareDate = localStorage.getItem("startSnapCompareDate");
        this.endSnapCompareDate = localStorage.getItem("endSnapCompareDate");

        this.startReferralDate = localStorage.getItem("startReferralDate");
        this.endReferralDate = localStorage.getItem("endReferralDate");

        this.startPageReferralDate = localStorage.getItem("startPageReferralDate");
        this.endPageReferralDate = localStorage.getItem("endPageReferralDate");
    }

    loadBreakdown() {
        this.queryOptions.sortOrder = "view_count";
        this.queryOptions.sortDirection = "asc";

        /* Get the top page views */
        let dataArr: { page: any; page_url: any; view_count: any; pages_per_visit: any; avg_duration: any; new_visits: any; }[] = [];

        this.queryOptions.start = new Date(this.startDate);
        this.queryOptions.end = new Date(this.endDate);

        this.leadsService.getLeadmetrics(this.currentOrg.org_id, this.lead.id, this.queryOptions).pipe(
          map(views => {
            this.totalrecords = views.count;
            views.metrics.forEach((element: { page_title: any; page_url: any; page_count: any; page_visits: any; avgduration: any; new_visits: any; }) => {
              dataArr.push({
                page: element.page_title,
                page_url: element.page_url,
                view_count: element.page_count,
                pages_per_visit: element.page_visits,
                avg_duration: element.avgduration,
                new_visits: element.new_visits,
              });
            });



            this.pageViews = dataArr;
            this.sortBreakdown("view_count");
          })
        ).subscribe();

      }


      sortBreakdown(sortOrder: any) {
        // is this a new sort order?
        if (this.queryOptions.sortOrder === sortOrder) {
            if (this.queryOptions.sortDirection === "asc") {
                this.queryOptions.sortDirection = "desc";
            } else {
                this.queryOptions.sortDirection = "asc";
            }
        } else {
            this.queryOptions.sortDirection = "desc";
        }
        this.queryOptions.sortOrder = sortOrder;

        document.getElementById(`view_count_sort`).classList.remove("btn-sort");
        document.getElementById(`pages_per_visit_sort`).classList.remove("btn-sort");
        document.getElementById(`avg_duration_sort`).classList.remove("btn-sort");
        document.getElementById(`new_visits_sort`).classList.remove("btn-sort");

        document.getElementById(`view_count_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`pages_per_visit_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`avg_duration_sort`).classList.remove("btn-sort-asc");
        document.getElementById(`new_visits_sort`).classList.remove("btn-sort-asc");

        document.getElementById(`.sort-active`).classList.remove("sort-active");


        if (this.queryOptions.sortDirection === "desc") {
          document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort");
          document.getElementById(`${sortOrder}_sort`).classList.add("sort-active");


            this.pageViews.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => (a[sortOrder] < b[sortOrder]) ? 1 : -1);


        } else {
          document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort-asc");
          document.getElementById(`${sortOrder}_sort`).classList.add("sort-active");
            this.pageViews.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => (a[sortOrder] > b[sortOrder]) ? 1 : -1);
        }

        document.getElementById(`${sortOrder}_sort`).classList.remove("btn-hollow");


    }

    loadSourceMedium() {
      this.queryOptions.sortOrder = "users";
      this.queryOptions.sortDirection = "desc";

      this.queryOptions.start = new Date(this.startReferralDate);
      this.queryOptions.end = new Date(this.endReferralDate);

      this.leadsService.getSourceMedium(this.currentOrg.org_id, this.queryOptions).pipe(
        map(results => {
          this.sourceMediumResults = results.stats;
          this.sourceMediumResultsOrig = Array.from(this.sourceMediumResults);
        })
      ).subscribe();

    }

    loadWebsiteMetrics() {
      this.queryOptions.sortOrder = "users";
      this.queryOptions.sortDirection = "desc";

      this.queryOptions.start = new Date(this.startReferralDate);
      this.queryOptions.end = new Date(this.endReferralDate);

      this.leadsService.getWebsiteMetrics(this.currentOrg.org_id, this.queryOptions).pipe(
        map(results => {
          this.websiteMetrics = results.stats[0];
        })
      ).subscribe();

    }

    sortSourceMedium(sortOrder: any) {
      // is this a new sort order?
      if (this.queryOptions.sortOrder === sortOrder) {
          if (this.queryOptions.sortDirection === "asc") {
              this.queryOptions.sortDirection = "desc";
          } else {
              this.queryOptions.sortDirection = "asc";
          }
      } else {
          this.queryOptions.sortDirection = "desc";
      }
      this.queryOptions.sortOrder = sortOrder;

      document.getElementById(`users_sort`).classList.remove("btn-sort");
      document.getElementById(`new_users_sort`).classList.remove("btn-sort");
      document.getElementById(`sessions_sort`).classList.remove("btn-sort");
      document.getElementById(`bounces_sort`).classList.remove("btn-sort");

      document.getElementById(`.sort-active`).classList.remove("sort-active");


      if (this.queryOptions.sortDirection === "desc") {
        document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort");
        document.getElementById(`${sortOrder}_sort`).classList.add("sort-active");
          this.sourceMediumResults.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => (a[sortOrder] < b[sortOrder]) ? 1 : -1);
      } else {
        document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort-asc");
        document.getElementById(`${sortOrder}_sort`).classList.add("sort-active");
        this.sourceMediumResults.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => (a[sortOrder] > b[sortOrder]) ? 1 : -1);
      }

    document.getElementById(`${sortOrder}_sort`).classList.remove("btn-hollow");

  }



    loadCompanyBreakdown() {
      this.queryOptions.sortOrder = "page_count";
      this.queryOptions.sortDirection = "asc";

      /* Get the top page views */
      let dataArr: { page: any; page2?: any; page_url2?: any; page_url: any; pageurl: any; pageurl2?: any; view_count: any; pages_per_visit: any; avg_duration: any; new_visits: any; id: any; }[] = [];

      this.queryOptions.start = new Date(this.startPageReferralDate);
      this.queryOptions.end = new Date(this.endPageReferralDate);

      this.leadsService.getOrgmetrics(this.currentOrg.org_id, this.queryOptions).pipe(
        map(views => {
          this.totalrecords = views.count;

          // dataArr = views.reports[0].data.rows;
          views.metrics.forEach((element: { page_title: any; page_title2: any; page_url: any; page_url2: any; pageurl: any; pageurl2: any; page_count: any; page_visits: any; avgduration: any; new_visits: any; id: any; }) => {
            if (this.currentOrg.org_id==="demo") {
              dataArr.push({
                page: element.page_title,
                page2: element.page_title2,
                page_url2: element.page_url,
                page_url: element.page_url2,
                pageurl: element.pageurl,
                pageurl2: element.pageurl2,
                view_count: element.page_count,
                pages_per_visit: element.page_visits,
                avg_duration: element.avgduration,
                new_visits: element.new_visits,
                id: element.id
              });
            } else {
              dataArr.push({
                page: element.page_title,
                page_url: element.page_url,
                pageurl: element.pageurl,
                view_count: element.page_count,
                pages_per_visit: element.page_visits,
                avg_duration: element.avgduration,
                new_visits: element.new_visits,
                id: element.id
              });
            }
          });


          this.pageViews = dataArr;
          this.pageViewsOrig = Array.from(this.pageViews);
          this.sortCompanyBreakdown("view_count");
        })
      ).subscribe();

    }


    sortCompanyBreakdown(sortOrder: string | undefined) {
      // is this a new sort order?
      if (this.queryOptions.sortOrder === sortOrder) {
          if (this.queryOptions.sortDirection === "asc") {
              this.queryOptions.sortDirection = "desc";
          } else {
              this.queryOptions.sortDirection = "asc";
          }
      } else {
          this.queryOptions.sortDirection = "desc";
      }
      this.queryOptions.sortOrder = sortOrder;

      document.getElementById(`page_sort`).classList.remove("btn-sort");
      document.getElementById(`new_users_sort`).classList.remove("btn-sort");
      document.getElementById(`avgduration_sort`).classList.remove("btn-sort");
      document.getElementById(`new_vists_sort`).classList.remove("btn-sortc");

      document.getElementById(`page_sort`).classList.remove("btn-sort-asc");
      document.getElementById(`new_users_sort`).classList.remove("btn-sort-asc");
      document.getElementById(`avgduration_sort`).classList.remove("btn-sort-asc");
      document.getElementById(`new_vists_sort`).classList.remove("btn-sort-asc");

      document.getElementById(`page_sort`).classList.add("btn-hollow");
      document.getElementById(`new_users_sort`).classList.add("btn-hollow");
      document.getElementById(`avgduration_sort`).classList.add("btn-hollow");
      document.getElementById(`new_visits_sort`).classList.add("btn-hollow");

      if (this.queryOptions.sortDirection === "desc") {
          document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort");

          this.pageViews.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => (a[sortOrder] < b[sortOrder]) ? 1 : -1);
      } else {
          document.getElementById(`${sortOrder}_sort`).classList.add("btn-sort-asc");
          this.pageViews.sort((a: { [x: string]: number; }, b: { [x: string]: number; }) => (a[sortOrder] > b[sortOrder]) ? 1 : -1);
      }
//      $(`#${sortOrder}_sort`).removeClass("btn-hollow");
      document.getElementById(`${sortOrder}_sort`).classList.remove("btn-hollow");



  }


    loadSnap() {
        // alert("Loading Snap");
        /* Get the snapshot */
        var startD = new Date(this.startSnapDate).toISOString().substring(0,10);
        // var endD = new Date(this.endSnapDate).toISOString().substring(0,10);
        var endD = new Date(new Date(this.endSnapDate).toDateString()).toISOString().substring(0,10);

        var startD2;
        var endD2;

        startD2 = new Date(this.startSnapCompareDate).toISOString().substring(0,10);
        endD2 = new Date(new Date(this.endSnapCompareDate).toDateString()).toISOString().substring(0,10);
        // new Date(this.endSnapCompareDate).toISOString().substring(0,10);

        this.leadsService.getSnapshot(this.currentOrg.org_id, endD, startD, endD2, startD2, this.includeISPs).pipe(
            map(leads => {
                    this.snapdata = leads.results[0];

                    this.snapdata.userspercent = (leads.results[0].user_count2-leads.results[0].user_count1)/leads.results[0].user_count2*100;
                    this.snapdata.userspercent = parseFloat(this.snapdata.userspercent).toFixed(2);
                    this.snapdata.validUserPercentValue = isFinite(Number(this.snapdata.userspercent));

                    if (leads.results[0].new_user_count2> 0) {
                      this.snapdata.newuserspercent = (leads.results[0].new_user_count2-leads.results[0].new_user_count1)/leads.results[0].new_user_count2*100;
                      this.snapdata.newuserspercent = parseFloat(this.snapdata.newuserspercent).toFixed(2);
                      this.snapdata.validNewUserPercentValue = isFinite(Number(this.snapdata.newuserspercent));
                    }

                    this.snapdata.sessionspercent = (leads.results[0].session_count2-leads.results[0].session_count1)/leads.results[0].session_count2*100;
                    this.snapdata.sessionspercent = parseFloat(this.snapdata.sessionspercent).toFixed(2);
                    this.snapdata.validSessionCountValue = isFinite(Number(this.snapdata.sessionspercent));

                    this.snapdata.sessions_per_user2 = ((leads.results[0].session_count2/leads.results[0].user_count2).toFixed(2));
                    this.snapdata.sessions_per_user1 = ((leads.results[0].session_count1/leads.results[0].user_count1).toFixed(2));
                    this.snapdata.sessionsperuser = (this.snapdata.sessions_per_user2-this.snapdata.sessions_per_user1)/this.snapdata.sessions_per_user2*100;
                    this.snapdata.sessionsperuser = parseFloat(this.snapdata.sessionsperuser).toFixed(2);
                    this.snapdata.validSessionsPerUserValue = isFinite(Number(this.snapdata.sessionsperuser));

                    this.snapdata.pageviewspercent = (leads.results[0].page_count2-leads.results[0].page_count1)/leads.results[0].page_count2*100;
                    this.snapdata.pageviewspercent = parseFloat(this.snapdata.pageviewspercent).toFixed(2);
                    this.snapdata.validPageCountPercentValue = isFinite(Number(this.snapdata.pageviewspercent));

                    this.snapdata.pagespersession2 = this.snapdata.page_count2/this.snapdata.session_count2;
                    this.snapdata.pagespersession2 = parseFloat(this.snapdata.pagespersession2).toFixed(2);
                    this.snapdata.pagespersession1 = this.snapdata.page_count1/this.snapdata.session_count1;
                    this.snapdata.pagespersession1 = parseFloat(this.snapdata.pagespersession1).toFixed(2);
                    this.snapdata.pagespersessionpercent = (this.snapdata.pagespersession2-this.snapdata.pagespersession1)/this.snapdata.pagespersession2*100;
                    this.snapdata.pagespersessionpercent = parseFloat(this.snapdata.pagespersessionpercent).toFixed(2);
                    this.snapdata.validPagesPerSessionValue = isFinite(Number(this.snapdata.pagespersessionpercent));

                    this.snapdata.sessionduration2 = leads.results[0].duration2/this.snapdata.session_count2;
                    this.snapdata.sessionduration2 = parseFloat(this.snapdata.sessionduration2).toFixed(2);

                    this.snapdata.sessionduration1 = leads.results[0].duration1/this.snapdata.session_count1;
                    this.snapdata.sessionduration1 = parseFloat(this.snapdata.sessionduration1).toFixed(2);

                    if (this.snapdata.sessionduration2 > 0) {
                      this.snapdata.sessiondurationpercent = (this.snapdata.sessionduration2-this.snapdata.sessionduration1)/this.snapdata.sessionduration2*100;
                      this.snapdata.sessiondurationpercent = parseFloat(this.snapdata.sessiondurationpercent).toFixed(2);
                      this.snapdata.validSessionDurationValue = isFinite(Number(this.snapdata.sessiondurationpercent));
                    }

                    // this.snapdata.bouncerate = (this.snapdata.bounces2-this.snapdata.bounces1)/this.snapdata.bounces2*100;
                    // this.snapdata.bouncerate = parseFloat(this.snapdata.bouncerate).toFixed(2);


                    this.snapdata.bounces2 = (this.snapdata.bounces2/leads.results[0].session_count2*100).toFixed(2);
                    this.snapdata.bounces1 = (this.snapdata.bounces1/leads.results[0].session_count1*100).toFixed(2);
                    this.snapdata.bouncerate = (this.snapdata.bounces2-this.snapdata.bounces1).toFixed(2);

                    this.snapdata.validBounceValue = isFinite(Number(this.snapdata.bouncerate));
            })
        ).subscribe();


      }


      loadLeads() {
        this.queryOptions.sortOrder = "page_count";
        this.queryOptions.sortDirection = "desc";
        this.queryOptions.pageSize = 1000000;
        this.queryOptions.includeISPs = this.includeISPs;

        /* Get the top 5 leads*/
        this.leadsService.getLeads(this.queryOptions, this.currentOrg.org_id).pipe(
            // map(leads => this.companyLeads = leads),
            map(leads => {
                    this.companyLeads = leads;
                    this.companyLeadsOrig = Array.from(this.companyLeads.results);
            }),
            takeUntil(this.leadSub)
        ).subscribe();

        // console.log(JSON.stringify(this.companyLeads));

        this.queryOptions.pageSize = 5;
      }

      loadReferrals() {
        /* Get the top page views */
        let dataArr = [];

        this.queryOptions.start = new Date(this.startDate);
        this.queryOptions.end = new Date(this.endDate);
        this.leadsService.getReferralsByDate(this.currentOrg.org_id, this.queryOptions).pipe(
          map(metrics => {
//            this.totalrecords = metrics.count;

            this.referrals = metrics.metrics;

          })
        ).subscribe();

      }





      loadMetrics() {
        this.queryOptions.sortOrder = "view_count";
        this.queryOptions.sortDirection = "asc";

        /* Get the top page views */
        let dataArr: any[][] = [];

        this.queryOptions.start = new Date(this.startDate);
        this.queryOptions.end = new Date(this.endDate);
        if (!this.queryOptions.includeISPs) {
          this.queryOptions.includeISPs = false;
        }



        this.leadsService.getContinentsByDate(this.currentOrg.org_id, this.queryOptions).pipe(
          map(metrics => {


            metrics.metrics.forEach((element: { continent: any; count: any; }) => {
              dataArr.push(
                [ element.continent, element.count]
              );
            });

            // console.log(dataArr);

            this.continentMetrics = dataArr;
            this.getChart();
          })
        ).subscribe();

      }

      getChart() {

        this.chartOptions = {
          chart: {
            map: worldMap
          },
          title: {
            text: ""
          },
          subtitle: {
            text:
              ""
          },
          mapNavigation: {
            enabled: true,
            buttonOptions: {
              alignTo: "spacingBox"
            }
          },
          legend: {
            enabled: true
          },
          colorAxis: {
            min: 0
          },
          series: [
            {
              type: "map",
              name: "Users",
              states: {
                hover: {
                  color: "#BADA55"
                }
              },
              dataLabels: {
                enabled: true,
                format: "{point.name}"
              },
              allAreas: true,
              data : this.continentMetrics,
              /*
              data : [
                ['eu', 0],
                ['oc', 1],
                ['af', 0],
                ['as', 3],
                ['na', 123],
                ['sa', 5]
              ]*/
            }
          ]
        };
      }

      loadReferralChart() {
        this.queryOptions.sortOrder = "view_count";
        this.queryOptions.sortDirection = "asc";

        /* Get the top page views */
        let dataArr: { name: any, counter: any; }[] = [];

        this.queryOptions.start = new Date(this.startSourceMediumDate);
        this.queryOptions.end = new Date(this.endSourceMediumDate);

//       if (this.queryOptions.includeISPs) {
 //         this.queryOptions.includeISPs = this.queryOptions.includeISPs;
        // } else {
//          this.queryOptions.includeISPs = false;
        // }

        var searchTypes = [];

        dataArr.push({name: "Organic", counter: 0});
        dataArr.push({name: "Direct", counter: 0});
        dataArr.push({name: "Referral", counter: 0});
        dataArr.push({name: "Other", counter: 0});

        this.leadsService.getAllReferralsByDate(this.currentOrg.org_id, this.queryOptions).pipe(
          map(metrics => {

            this.referralsCount = metrics.metrics.length;

            metrics.metrics.forEach((element: { referral_source: string; users: any; }) => {

              if (element.referral_source === "direct") {
                dataArr[1].counter = dataArr[1].counter + element.users;
              } else if (element.referral_source === "organic") {
                dataArr[0].counter = dataArr[0].counter + element.users;
              } else if (element.referral_source === "referral") {
                dataArr[2].counter = dataArr[2].counter + element.users;
              } else if (element.referral_source === "error") {

              } else  {
                dataArr[3].counter = dataArr[3].counter + element.users;
              }


            });


            this.referralMetrics = dataArr;

            this.getReferralChart();
          })
        ).subscribe();

      }

      getReferralChart() {
        highcharts3D(Highcharts);
        // highcharts.setOptions({
        //   //colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
        //   //colors: ['#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
        //   colors: ['#2952CC', '#95A8E0', '#2B2C42', '#213C6E', '#616771', '#000000']
        // });

        var tSeries = [];

        var total = 0;



        // this.referralMetrics.forEach(element => {

          // tSeries.push([
            // element[0] + "(" + (element[1]/total*100).toFixed(2) + "%)",
            // element[1]
            // element
          // ]);
        // });

        total = this.referralMetrics[0].counter + this.referralMetrics[1].counter + this.referralMetrics[2].counter + this.referralMetrics[3].counter;

        // tslint:disable-next-line:max-line-length
        tSeries.push([`${this.referralMetrics[0].counter} ${this.referralMetrics[0].name} (${(this.referralMetrics[0].counter/total*100).toFixed(2)}%)` , this.referralMetrics[0].counter]);
        tSeries.push([`${this.referralMetrics[1].counter} ${this.referralMetrics[1].name}   (${(this.referralMetrics[1].counter/total*100).toFixed(2)}%)` , this.referralMetrics[1].counter]);
        tSeries.push([`${this.referralMetrics[2].counter} ${this.referralMetrics[2].name} (${(this.referralMetrics[2].counter/total*100).toFixed(2)}%)` , this.referralMetrics[2].counter]);
        tSeries.push([`${this.referralMetrics[3].counter} ${this.referralMetrics[3].name} (${(this.referralMetrics[3].counter/total*100).toFixed(2)}%)` , this.referralMetrics[3].counter]);
        // tSeries.push(this.referralMetrics[1]);
        // tSeries.push(this.referralMetrics[2]);
        // tSeries.push(this.referralMetrics[3]);

        console.log(tSeries);

        this.chartOptionsReferrals = {
          chart: {
            type: "pie",
            height: "70%",
            spacingLeft: 0,
            spacingTop: 20,
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            },
          },

          legend: {
            align: "right",
            itemMarginBottom: 5,
            itemStyle: {
              lineHeight: "12px",
              fontSize: 12
            },

            verticalAlign: "top",
            layout: "vertical",
            x: 0,
            y: 20,
            title: {
              text: `${total} Total Users<br/>`,
              style: {
                  fontStyle: "bold",
                  fontSize: 16
              }
            }
          },



          // colors: ['#2952CC', '#95A8E0', '#2B2C42', '#213C6E', '#616771', '#000000'],
          colors: ["#2952CC", "#282C42", "#FFB23E", "283C6A"],
          title: undefined,
          plotOptions: {
            pie: {
              innerSize: 0,
              depth: 5,
              allowPointSelect: true,
              cursor: "pointer",
              dataLabels: {
                enabled: false,
              },
              showInLegend: true
            },
          },
          series: [{
            name: "Referrals",
            data: tSeries
          }]
        };

      }

      loadReferralsList() {
        this.queryOptions.sortOrder = "view_count";
        this.queryOptions.sortDirection = "asc";

        let dataArr: { counter: number; }[] = [];

        this.queryOptions.start = new Date(this.startReferralDate);
        this.queryOptions.end = new Date(this.endReferralDate);

        // get all the referrals
        this.leadsService.getAllReferralsByDate(this.currentOrg.org_id, this.queryOptions).pipe(
          map(metrics => {

            this.referralsCount = metrics.metrics.length;

            metrics.metrics.forEach((element: { referrer: string | any[] | null; page_url: string | string[]; }) => {

              /*
                1 - determine the search type for each record
                2 - check if the dataArr already contains the key (source~medium)
                3 - If not add it
                {
                  [
                    source: 'direct',
                    medium: 'none',
                    users: 0,
                    newusers: 0,
                    usersarr: {},
                    sessions: 0,
                    sessionsarray: {},
                    bounces: 0,
                    pages: 0

                  ]
                }
                4 - Users - check for user within usersarr, add if not existing
                5 - New users - check if new users and user within usersarr, add if not existing (only use user_start)
                6 - Sessions - check for session within sessionsarr, add if not existing (only use session_start)

                order for checking source/medium (in the URL)
                1 - ?UTM_SOURCE/?UTM_MEDIUM
                2 - (direct)/(none) if the referrer is empty
                3 - Don't show - if same site
                4 - {referrer domain}/organic - referrer search engines
                5 - Referral - everything else

                Group by seesion_id

                If the referral is from a different website, need to consider this a new session

              */
              if (element.referrer.includes(this.currentOrg.domain)) {
                dataArr[3].counter++;
              } else if (element.referrer===null) {
                dataArr[1].counter++;
              } else if (element.referrer==="") {
                dataArr[1].counter++;
              } else if (!element.referrer) {
                dataArr[1].counter++;
              } else if (element.page_url.includes("&utm_")) {
                dataArr[2].counter++;
              } else  {
                dataArr[0].counter++;
              }


            });


            this.referralMetrics = dataArr;

            this.getReferralChart();
          })
        ).subscribe();

      }

      loadIndustryMetrics() {
        this.queryOptions.sortOrder = "view_count";
        this.queryOptions.sortDirection = "asc";

        /* Get the top page views */
        let dataArr: any[][] = [];

        this.queryOptions.start = new Date(this.startDate);
        this.queryOptions.end = new Date(this.endDate);


        if (this.queryOptions.includeISPs) {
          this.queryOptions.includeISPs = this.queryOptions.includeISPs;
        } else {
          this.queryOptions.includeISPs = false;
        }

        this.leadsService.getIndustriesByDate(this.currentOrg.org_id, this.queryOptions).pipe(
          map(metrics => {

            this.industryCount = metrics.metrics.length;

            metrics.metrics.forEach((element: { category: any; count: any; }) => {
              dataArr.push(
                [ element.category, element.count]
              );
            });

            // console.log(dataArr);
            if (this.top5only) {
              this.industryMetrics = dataArr.slice(0, 5);
            } else {
              this.industryMetrics = dataArr;
            }
            this.getIndustryChart();
          })
        ).subscribe();


      }

      getIndustryChart() {
        highcharts3D(Highcharts);
        // highcharts.setOptions({
        //   //colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
        //   //colors: ['#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
        //   colors: ['#2952CC', '#95A8E0', '#2B2C42', '#213C6E', '#616771', '#000000']
        // });

        var tSeries: any[][] = [];

        var total = 0;

        this.industryMetrics.forEach((element: number[]) => {
          total = total + element[1];
        });

        this.industryMetrics.forEach((element: any[]) => {

          tSeries.push([
            element[0] + "(" + (element[1]/total*100).toFixed(2) + "%)",
            element[1]
          ]);
        });

        this.chartOptionsIndustry = {
          chart: {
            type: "pie",
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            },
          },
          colors: ["#2952CC", "#95A8E0", "#2B2C42", "#213C6E", "#616771", "#000000"],
          title: undefined,
          plotOptions: {
            pie: {
              innerSize: 60,
              depth: 75
            }
          },
          series: [{
            name: "Industries",
            data: tSeries
          }]
        };

      }


      loadRevenueMetrics() {
        this.queryOptions.sortOrder = "view_count";
        this.queryOptions.sortDirection = "asc";

        /* Get the top page views */
        let dataArr: any[][] = [];

        this.queryOptions.start = new Date(this.startDate);
        this.queryOptions.end = new Date(this.endDate);


        if (this.queryOptions.includeISPs) {
          this.queryOptions.includeISPs = this.queryOptions.includeISPs;
        } else {
          this.queryOptions.includeISPs = false;
        }

        this.leadsService.getRevenueByDate(this.currentOrg.org_id, this.queryOptions).pipe(
          map(metrics => {

            this.revenueCount = metrics.metrics.length;

            metrics.metrics.forEach((element: { revenue: any; count: any; }) => {
              dataArr.push(
                [ element.revenue, element.count]
              );
            });

            // console.log(dataArr);
            if (this.top5revenueonly) {
              this.revenueMetrics = dataArr.slice(0, 5);
            } else {
              this.revenueMetrics = dataArr;
            }
            this.getRevenueChart();
          })
        ).subscribe();


      }

      getRevenueChart() {
        highcharts3D(Highcharts);
        Highcharts.setOptions({
          // colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
          colors: ["#50B432", "#058DC7", "#ED561B", "#DDDF00", "#24CBE5", "#64E572", "#FF9655", "#FFF263", "#6AF9C4"]
        });

        var tSeries: any[][] = [];
        var total = 0;

        this.revenueMetrics.forEach((element: number[]) => {
          total = total + element[1];
        });

        this.revenueMetrics.forEach((element: any[]) => {

          tSeries.push([
            element[0] + "(" + (element[1]/total*100).toFixed(2) + "%)",
            element[1]
          ]);
        });

        this.chartOptionsRevenue = {
          chart: {
            type: "pie",
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            },
          },
          colors: ["#2952CC", "#95A8E0", "#2B2C42", "#213C6E", "#616771", "#000000"],
          title: {
            text: ""
          },
          plotOptions: {
            pie: {
              innerSize: 80,
              depth: 75
            }
          },
          series: [{
            name: "Revenue",
            data: tSeries
          }]
        };

      }

      loadEmployeeMetrics() {
        this.queryOptions.sortOrder = "view_count";
        this.queryOptions.sortDirection = "asc";

        /* Get the top page views */
        let dataArr: any[][] = [];

        this.queryOptions.start = new Date(this.startDate);
        this.queryOptions.end = new Date(this.endDate);


        if (this.queryOptions.includeISPs) {
          this.queryOptions.includeISPs = this.queryOptions.includeISPs;
        } else {
          this.queryOptions.includeISPs = false;
        }

        this.leadsService.getEmployeesByDate(this.currentOrg.org_id, this.queryOptions).pipe(
          map(metrics => {

            this.employeeCount = metrics.metrics.length;

            metrics.metrics.forEach((element: { employees: any; count: any; }) => {
              dataArr.push(
                [ element.employees, element.count]
              );
            });

            // console.log(dataArr);
            if (this.top5employeeonly) {
              this.employeeMetrics = dataArr.slice(0, 5);
            } else {
              this.employeeMetrics = dataArr;
            }
            this.getEmployeeChart();
          })
        ).subscribe();


      }

      getEmployeeChart() {
        highcharts3D(Highcharts);
        // highcharts.setOptions({
        //   //colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
        //   colors: ['#50B432', '#058DC7', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
        // });


        var tSeries: any[][] = [];

        var total = 0;

        this.industryMetrics.forEach((element: number[]) => {
          total = total + element[1];
        });

        this.employeeMetrics.forEach((element: any[]) => {

          tSeries.push([
            element[0] + "(" + (element[1]/total*100).toFixed(2) + "%)",
            element[1]
          ]);
        });

        this.chartOptionsEmployees = {
          chart: {
            type: "pie",
            options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
            },
          },
          colors: ["#2952CC", "#95A8E0", "#2B2C42", "#213C6E", "#616771", "#000000"],
          title: {
            text: ""
          },
          plotOptions: {
            pie: {
              innerSize: 80,
              depth: 75
            }
          },
          series: [{
            name: "Employees",
            data: tSeries
          }]
        };

      }

      getIndustryChartOLD() {
        Highcharts.setOptions({
          // colors: ['#058DC7', '#50B432', '#ED561B', '#DDDF00', '#24CBE5', '#64E572', '#FF9655', '#FFF263', '#6AF9C4']
          colors: ["#50B432", "#058DC7", "#ED561B", "#DDDF00", "#24CBE5", "#64E572", "#FF9655", "#FFF263", "#6AF9C4"]
        });


        var tSeries: { name: any; data: any[]; }[] = [];

        this.industryMetrics.forEach((element: any[]) => {
          tSeries.push({
            name: element[0],
            data: [element[1]],
          });
        });




        this.chartOptionsIndustry = {
          chart: {
            type: "column",
            inverted: true,
            polar: true,
            backgroundColor: "",
            plotShadow: false,
            plotBorderWidth: null,
            plotBackgroundColor: null,

          },
          pane: { size: "105%", innerSize: "70%", endAngle: 360 },
          xAxis: { categories: [] },
          title: "Leads by Industry",
          plotOptions: {
            column: {
              stacking: "normal",
              borderWidth: 0,
              pointPadding: 0,
              groupPadding: 0.15
            }
          },
          series: tSeries
        };
      }



      setSourceMediumDates(event) {
        this.startSourceMediumDate = event.startDate;
        this.endSourceMediumDate = event.endDate;


        this.selectedSourceMediumDates = { startDate: this.startSourceMediumDate, endDate: this.endSourceMediumDate };

        localStorage.setItem("startSourceMediumDate", this.startSourceMediumDate);
        localStorage.setItem("endSourceMediumDate", this.endSourceMediumDate);
      }


      setSnapDates(event, setSelected) {
        this.startSnapDate = event.startDate;
        this.endSnapDate = event.endDate;

        localStorage.setItem('startSnapDate', event.startDate) ;
        localStorage.setItem('endSnapDate', event.endDate);

        if (setSelected) {
          this.selectedSnapDates = { startDate: this.startSnapDate, endDate: this.endSnapDate };
        }

        this.loadSnap();
      }

      setReferralDates(event) {
        this.startReferralDate = event.startDate;
        this.endReferralDate = event.endDate;

        localStorage.setItem("startReferralDate", event.startDate) ;
        localStorage.setItem("endReferralDate", event.endDate) ;

        this.selectedReferralDates = { startDate: this.startReferralDate, endDate: this.endReferralDate };
      }

      setPageReferralDates(event) {
        this.startPageReferralDate = event.startDate;
        this.endPageReferralDate = event.endDate;

        localStorage.setItem("startPageReferralDate", event.startDate) ;
        localStorage.setItem("endPageReferralDate", event.endDate) ;

        this.selectedPageReferralDates = { startDate: this.startPageReferralDate, endDate: this.endPageReferralDate };
      }

      setDates() {
        this.startDate = this.startSnapDate;
        this.endDate = this.endSnapDate;

        localStorage.setItem("startDate", this.startDate);
        localStorage.setItem("endDate", this.endDate);
      }

}


