import { NgModule } from "@angular/core";
import { ReferralPipe } from '@app/pipes/referral.pipe';

@NgModule({
    imports: [],
    exports: [ReferralPipe],
    declarations: [
        ReferralPipe
    ]
})
export class ReferralPipeModule {

}