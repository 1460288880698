import { NgModule } from "@angular/core";
import { LeadSortPipe } from "../lead-sort.pipe";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgxPaginationModule } from "ngx-pagination";
import { LeadsService } from "@app/_services";
import { CommonModule } from "@angular/common";
import { LeadsRoutingModule } from "./leads-routing.module";
//import { KeyLoggerComponent } from "@app/key-logger/key-logger.component";
import { DashIntroComponent } from "./dash-intro/dash-intro.component";
import { ChartCardComponent } from "./chart-card/chart-card.component";
import { CompanyChartComponent } from "./companychart/company-chart.component";
import { CircleChartComponent } from "./circlechart/circle-chart.component";
import { ReferralChartComponent } from "@app/_components/referral-chart.component";
import { OrgMapComponent } from "./orgmap/org-map.component";
import { DashboardComponent } from "./dashboard";
import { LeadBreakdownComponent } from "./lead-breakdown";
import { SimpleDownloadComponent } from "./download";
import { GridAllModule } from "@syncfusion/ej2-angular-grids";
import { WebsitePerformanceComponent, CompanyBreakdownComponent, PageBreakdownComponent } from "./websiteperformance";
import { LeadViewComponent } from "./lead-view";
import { LeadsComponent } from "./leads.component";


import { SnapshotComponent } from "@app/leads/dashboard/snapshot/snapshot.component";
import { SnaptileComponent } from "@app/_components/snaptile/snaptile.component";
import { MatSliderModule } from "@angular/material/slider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatNativeDateModule } from "@angular/material/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from "@angular/material/select";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { HighchartsChartModule } from "highcharts-angular";
import { DurationPipeModule} from "@app/pipes/durationpipe.module";
import { DurationPipe2Module} from "@app/pipes/durationpipe2.module";
import { RandomCompanyService } from "@app/random-company.service";
import { CleanReferralPipeModule } from "@app/pipes/cleanreferralpipe.module";
import { ReferralPipeModule } from "@app/pipes/referralpipe.module";
import { ReferralSourcePipeModule } from "@app/pipes/referralsourcepipe.module";
import { AdperformanceComponent } from './adperformance/adperformance.component';
import { CampaignCreatorComponent } from './campaign-creator/campaign-creator.component';
import { AuthorizationModalComponent } from './campaign-creator/authorization-modal/authorization-modal.component';
import { AdCreator1Component } from './campaign-creator/ad-creator1/ad-creator1.component';
import { FileUploadModalComponent } from './campaign-creator/file-upload-modal/file-upload-modal.component';
import { AdCreator2Component } from './campaign-creator/ad-creator2/ad-creator2.component';
import { AdCreatorCompleteComponent } from './campaign-creator/ad-creator-complete/ad-creator-complete.component';
import { AdpreviewComponent } from './dashboard/adpreview/adpreview.component';
import { LinkedinperformanceComponent } from './dashboard/adpreview/linkedinperformance/linkedinperformance.component';
import { MetaperformanceComponent } from './dashboard/adpreview/metaperformance/metaperformance.component';
import { GoogleperformanceComponent } from './dashboard/adpreview/googleperformance/googleperformance.component';
import { BudgetModalComponent } from './campaign-creator/budget-modal/budget-modal.component';



@NgModule({
  imports: [

    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    LeadsRoutingModule,
        // datePickerModule,
    //NgxChartsModule,
    MatSliderModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSelectModule,
    MatProgressBarModule,
    GridAllModule,
    NgxDaterangepickerMd.forRoot(),
    NgxMatSelectSearchModule,
    HighchartsChartModule,
    DurationPipeModule,
    DurationPipe2Module,
    ReferralPipeModule,
    CleanReferralPipeModule,
    ReferralSourcePipeModule


  ],
  declarations: [
    LeadSortPipe,
    DashIntroComponent,
    DashboardComponent,
    LeadBreakdownComponent,
    LeadViewComponent,
    WebsitePerformanceComponent,
    LeadsComponent,
    PageBreakdownComponent,
    CompanyBreakdownComponent,
    ChartCardComponent,
    CircleChartComponent,
    ReferralChartComponent,
    CompanyChartComponent,
    OrgMapComponent,
    SnapshotComponent,
    SnaptileComponent,
    SimpleDownloadComponent,
    AdperformanceComponent,
    CampaignCreatorComponent,
    AuthorizationModalComponent,
    AdCreator1Component,
    FileUploadModalComponent,
    AdCreator2Component,
    AdCreatorCompleteComponent,
    AdpreviewComponent,
    LinkedinperformanceComponent,
    MetaperformanceComponent,
    GoogleperformanceComponent,
    BudgetModalComponent

    // pageBreakdownComponent,
    // companyInfoEditorComponent
  ],
  exports: [
    //FormsModule,
    //ReactiveFormsModule,
    // datePickerComponent
  ],
  // exports: [
  // pageBreakdownComponent,
  // dashIntroComponent,
  // chartCardComponent,
  // datePickerComponent,
  // companyInfoEditorComponent,
  // ngxPaginationModule
  // ],
  providers: [LeadsService, RandomCompanyService, LeadSortPipe]/*,
  bootstrap: [AppComponent]*/
})
export class LeadsModule { }
