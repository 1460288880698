import { NgModule } from "@angular/core";
import { ReferralSourcePipe } from '@app/pipes/referralsource.pipe';

@NgModule({
    imports: [],
    exports: [ReferralSourcePipe],
    declarations: [
        ReferralSourcePipe
    ]
})
export class ReferralSourcePipeModule {

}