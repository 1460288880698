import { Component, OnInit,  AfterViewInit, ViewChild, ElementRef } from "@angular/core";
import { PageVisits } from "@app/_models";
import { LeadsService } from "@app/_services/leads.service";
import { DataService } from "@app/_services/data.service";
import { AccountService } from "@app/_services/account.service";
import { map } from "rxjs/operators";
import { CookieService } from "ngx-cookie-service";
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import { fromEvent } from "rxjs";

@Component({
  selector: "app-wspage-breakdown",
  templateUrl: "./page-breakdown.component.html"
})

export class PageBreakdownComponent implements OnInit, AfterViewInit {
  loading = false;
  pageVisits: PageVisits[];
  itemsperpage: number;
  currentpage: number;
  totalrecords: number;
  pageTitle: string;
  referrals: any;
  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();
  searchValue = "";

  @ViewChild("searchInput") searchElementRef: ElementRef;

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment()
        .subtract(1, "month")
        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ],
    "Last 3 Month": [
      moment()
        .subtract(3, "month")        .startOf("month"),
      moment()
        .subtract(1, "month")
        .endOf("month")
    ]
};

  constructor(
    private leadsService: LeadsService,
    public dataService: DataService,
    private accountService: AccountService,
    private cookieService: CookieService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private elRef: ElementRef
  ) {

    this.queryOptions.page = 1;
    this.queryOptions.pageSize = 10000;
    this.queryOptions.sortDirection = "desc";

  }

  ngAfterViewInit(): void {
    var that = this;
    let term: string;
    let objValue: string;
    fromEvent(this.searchElementRef.nativeElement, "input").subscribe(
      (x) => {
        this.dataService.pageViews = Array.from(this.dataService.pageViewsOrig);
        this.dataService.pageViews = this.dataService.pageViews.filter((obj)=> {
            return Object.keys(obj).reduce((acc, curr)=> {
                term = this.searchValue.toLowerCase();

                if (!obj[curr]) {
                  obj[curr] = "";
                }

                if (curr === "view_count" || curr === "avg_duration" || curr === "id") {
                    objValue = obj[curr].toString();
                    return acc || objValue.includes(term);
                } else {
                    objValue = obj[curr].toLowerCase();
                    return acc || objValue.includes(term);
                }

            }, false);
        });
      }
    );
  }




  ngOnInit(): void {
    this.itemsperpage = 10;
    this.currentpage = 1;
    this.queryOptions.sortOrder = "view_count";


    if (!this.dataService.startPageReferralDate) {

      this.dataService.startPageReferralDate = moment().subtract(6, "days");
      this.dataService.endPageReferralDate = moment();

      this.dataService.selectedPageReferralDates = { startDate: this.dataService.startPageReferralDate, endDate: this.dataService.endPageReferralDate };
    }


    this.queryOptions.sortDirection = "desc";
    this.queryOptions.start = new Date(this.dataService.startPageReferralDate);
    this.queryOptions.end = new Date(this.dataService.endPageReferralDate);

    this.dataService.loadCompanyBreakdown();

    this.queryOptions.sortDirection = "desc";

  }


  delay(ms: number) {
      return new Promise(resolve => setTimeout(resolve, ms));
  }

  handlePageChange(event) {
   // this.queryOptions.page = event;
    // this.queryOptions.pageSize = this.pageSize;

    // this.refreshLeads(this.queryOptions);
    this.currentpage = event;
  }

  /*
  oldshowReferrals(pageTitle) {

    this.pageTitle = pageTitle;
    this.referrals = this.dataService.referrals.filter(this.isPageReferral, pageTitle);


   var x = document.getElementById("referralSpan"+pageTitle);
   if (x.style.display === "none") {
     x.style.display = "block";
   } else {
     x.style.display = "none";
   }
  }

  isPageReferral(element, index, array) {
    return (element.page_title === this);
 }
*/

/*
 async showCompaniesJourney(page) {
  //debugger;
  this.loading = true;

  if (this.dataService.currentOrg.org_id==='demo') {
    this.dataService.pageUrl = page.pageurl2;
  } else {
    this.dataService.pageUrl = page.pageurl;
  }

  this.queryOptions.start = new Date(this.dataService.startDate);
  this.queryOptions.end = new Date(this.dataService.endDate);

  await this.leadsService.getCompaniesJourney(this.dataService.currentOrg.org_id, page.pageurl, this.queryOptions).pipe(
    map(metrics => {
      this.dataService.journies = metrics.metrics;
      this.loading = false;
      this.dataService.dialogRef = this.dialog.open(CompanyJourneyComponent, {
          height: '90%',
          width: '90%',
      });
    })
  ).subscribe()


  this.dataService.dialogRef.afterClosed().subscribe(result => {

  });



 }
*/
 datesUpdated(event) {

      if (event.startDate) {

        this.dataService.setPageReferralDates(event);

        this.queryOptions.start = new Date(this.dataService.startPageReferralDate);
        this.queryOptions.end = new Date(this.dataService.endPageReferralDate);

        this.queryOptions.sortOrder = "page_count";
        this.queryOptions.sortDirection = "desc";

        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 1000;

        this.dataService.loadCompanyBreakdown();

        // update source/medium
        this.dataService.setReferralDates(event);

        this.queryOptions.start = new Date(this.dataService.startReferralDate);
        this.queryOptions.end = new Date(this.dataService.endReferralDate);

        this.queryOptions.sortOrder = "users";
        this.queryOptions.sortDirection = "desc";

        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 1000;

        this.dataService.loadSourceMedium();
        this.dataService.loadWebsiteMetrics();

        // set referral chart
        this.dataService.setSourceMediumDates(event);

        this.queryOptions.start = new Date(this.dataService.startSourceMediumDate);
        this.queryOptions.end = new Date(this.dataService.endSourceMediumDate);

        this.dataService.loadReferralChart();

        this.dataService.setSnapDates(event, true);
        this.dataService.setDates();

      }


  }



  toggleDisplay(i, page) {

    debugger;
    const ele = document.getElementById(`row${i}`) as HTMLInputElement;
    const ele2 = document.getElementById(`${i}_arrow`) as HTMLInputElement;
    if (ele.style.display === "") {
    ele.style.display = "none";
    ele2.className = "arrow-down-2";
    } else {
    ele.style.display = "";
    ele2.className = "arrow-up-2";
    this.getPageDetails(this.dataService.currentOrg.org_id, ele, page);

  }

  }





  getPageDetails(org, ele, page) {
    // ele.innerHTML = `<table border=1><tr><td>${i}</td></tr></table>`
    var retVal = "";
    var allData;
    this.leadsService.getOrgmetricsByPage(org, page.page, this.queryOptions).pipe(
      map(data => {

        allData = data;

        retVal =  `<td colspan=5><table class="table table-yellow" cellspacing="0"><thead>`;
        retVal =  retVal + `<tr class="session-journey-sub"><td>Companies</td><td>View Count</td><td>New Users</td><td>Last Activity</td><td></td></tr></thead>`;

        var counter = 0;
        var date1;
        var date2;
        data.metrics.forEach(element => {


          var value2 = element.avgduration*1000;

          var hoursamt = moment.utc(value2).format("HH");
          var hours = moment.utc(value2).format("H") + " hrs";

          var minutesamt = moment.utc(value2).format("mm");
          var minutes = moment.utc(value2).format("m") + " mins";

          var secondsamt = moment.utc(value2).format("ss");
          var seconds = moment.utc(value2).format("s") + " secs";

          element.avgduration = `${hoursamt!=="00"?hours:""} ${minutesamt!=="00"?minutes:""} ${secondsamt!=="00"?seconds:""}`;


          const tdate = new Date(element.timestamp);

          element.timestamp =  moment(tdate).format("MMM D, YYYY, h:mm:ss A");

          var obj = JSON.stringify(element);

          retVal = retVal + `<tr><td class="col-4">${element.name}</td><td class="col-1">${element.page_count}</td><td class="col-2" style="text-align: left">${element.new_visits}</td><td class="col-2">${element.timestamp}</td><td class="col-4"><div class="text-decoration-underline cursorPointer" style="position: relative;" id="${element.company_id}" pageid="${element.company_id}">View Company's User Journey</button></td></tr>`;
        });


        retVal = retVal + `</table></td>`;




        ele.innerHTML = retVal;


        allData.metrics.forEach(element => {
          // this.elRef.nativeElement.querySelector('div').addEventListener('click', (e) => {
          document.getElementById(`${element.company_id}`).addEventListener("click", (e) => {
            // this.navigate();//your typescript function
            //var id = e.srcElement.id;
            var id = e.target['id'];
            id = id * 1;
            this.leadsService.getLead(id).subscribe(fulllead => {
              this.dataService.lead = fulllead.results;

              localStorage.setItem("lead", JSON.stringify(this.dataService.lead));
              this.router.navigate(["/leads/lead-breakdown"], { relativeTo: this.route });
            });

          });
        });

      }),



      // takeUntil(this.leadSub)
    ).subscribe();



  }




}
