import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import moment from 'moment';
import { LeadsService } from '@app/_services/leads.service';
import { DataService } from '@app/_services/data.service';
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";

@Component({
  selector: 'app-linkedinperformance',
  templateUrl: './linkedinperformance.component.html',
  styleUrls: ['./linkedinperformance.component.scss']
})
export class LinkedinperformanceComponent {
  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();
  lastRange1;
  lastRange2;
  snapUsers: string;
  showAdvancedDatePicker: boolean;
  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 3 Month': [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };

  constructor(
    public leadsService: LeadsService,
    public dataService: DataService,
    public router: Router,
    public cd: ChangeDetectorRef,
  ) {
  }

  rangeClicked(event) {
    this.lastRange1 = event.label;
  }

  datesUpdated(event) {

  }

  clearClicked(event) {
    this.dataService.selectedSnapCompareDates = null;
    localStorage.removeItem('startSnapCompareDate');
    localStorage.removeItem('endSnapCompareDate');
  }

  compareDatesUpdated(event) {

  }
}
