import { Component, OnInit } from '@angular/core';
import { DataService } from '@app/_services';
import { RandomCompanyService } from '@app/random-company.service';
import { CookieService } from 'ngx-cookie-service';
import { ThemePalette } from '@angular/material/core';
import { AccountService } from '../../_services';
import { LeadViewQueryOptions } from '../../_models';

import moment from 'moment';

@Component({
  selector: 'app-adperformance',
  templateUrl: './adperformance.component.html',
  styleUrls: ['./adperformance.component.scss'],
})
export class AdperformanceComponent implements OnInit {
  constructor(
    public dataService: DataService,
    private cookieService: CookieService,
    public randomService: RandomCompanyService,
    private accountService: AccountService,
  ) {}

  color: ThemePalette = 'primary';
  loading = false;
  itemsperpage: number;
  currentpage: number;
  totalrecords: number;

  account = this.accountService.accountValue;
  pageSize = 25;
  page = 1;
  totalPages: number;
  offset: number;

  searchValue = '';

  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

  ranges: any = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment()
        .subtract(1, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ],
    'Last 3 Month': [
      moment()
        .subtract(3, 'month')
        .startOf('month'),
      moment()
        .subtract(1, 'month')
        .endOf('month')
    ]
  };

  isCompanyActive = true;

  ngOnInit(): void {
    this.currentpage = 1;
    this.itemsperpage = 25;

    this.queryOptions.start = new Date(this.dataService.startDate);
    this.queryOptions.end = new Date(this.dataService.endDate);

    let term: string;
    let objValue: string;

    // check localStorage
    this.dataService.lead = JSON.parse(localStorage.getItem('lead'));

    // document.getElementById('searchInput2').addEventListener('input', (e) => {
    //   this.dataService.sessions = Array.from(this.dataService.sessionsOrig);
    //   this.dataService.sessions = this.dataService.sessions.filter((obj)=>{
    //     return Object.keys(obj).reduce((acc, curr)=>{
    //         term = e.currentTarget['value'].toLowerCase();

    //         if (!obj[curr]) {
    //           obj[curr] = '';
    //         }

    //         if (curr === 'session_start' || curr === 'session_end' || curr==='page_visits') {
    //             objValue = obj[curr].toString();
    //             return acc || objValue.includes(term);
    //         } else {
    //             objValue = obj[curr].toLowerCase();
    //             return acc || objValue.includes(term);
    //         }

    //     }, false);
    //   });
    // });
  }

  handlePageChange(event) {
    this.queryOptions.page = event;
    this.queryOptions.pageSize = this.pageSize;
    this.currentpage = event;
  }

  itemsPerPageChange(event) {
    this.itemsperpage = event;
    this.queryOptions.page = 1;
  }

  datesUpdated(event) {
    if (event.startDate) {
      this.dataService.startDate = event.startDate;
      this.dataService.endDate = event.endDate;

      localStorage.setItem('startDate', event.startDate) ;
      localStorage.setItem('endDate', event.endDate) ;

      this.queryOptions.start = new Date(this.dataService.startDate);
      this.queryOptions.end = new Date(this.dataService.endDate);

      this.queryOptions.sortOrder = 'page_count';
      this.queryOptions.sortDirection = 'desc';

      this.queryOptions.page = 1;
      this.queryOptions.pageSize = 10;
    }
  }

  toggleDisplay(i) {
    const parent = document.getElementById(`parent${i}`) as HTMLInputElement;
    const ele = document.getElementById(`row${i}`) as HTMLInputElement;
    const ele2 = document.getElementById(`${i}_arrow`) as HTMLInputElement;

    if (ele.style.display === '') {
      parent.classList.remove('active');
      ele.style.display = 'none';
      ele2.className = 'arrow-down';
    } else {
      parent.classList.add('active');
      ele.style.display = '';
      ele2.className = 'arrow-up';
      this.getCampaignDetails(i, ele);
    }
  }

  getCampaignDetails(i, ele) {
    var retVal = '';

    retVal = `<td colspan=11>
      <table class="table table-yellow ad-table" id="ad-table${i}" cellspacing="0">` +
        `<tr>
          <td class="col-1">
            Date Added
          </td>
          <td class="col-1">
            Company Name
          </td>
          <td class="col-10">
          </td>
        </tr>
        <tr class="font-normal">
          <td class="col-1">
            00/00/0000
          </td>
          <td class="col-1">
            Company Name
          </td>
          <td class="col-10"></td>
        </tr>`+
      `</table>` +
      `<div class="row mt-4 align-items-center">
        <div class="col-3">
          <div class="row rr_pager">
            <div class="col-auto">
              View:
            </div>
            <div class="col-auto rr_pager_active">
              25
            </div>
            <div class="col-auto rr_pager_inactive">
              50
            </div>
            <div class="col-auto rr_pager_inactive">
              100
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="d-flex align-items-center justify-content-center">
            <button class="btn-common btn-add">View More...</button>
          </div>
        </div>
        <div class="col-3">
          <div class="d-flex align-items-right justify-content-end">
            <a href="javascript:void(0)" class="ad-download"><span>Export all to a CSV </span><i class="fas fa-download"></i></a>
          </div>
        </div>
      </div>
      </td>`;

    ele.innerHTML = retVal;

    const downloadLink = ele.querySelector('.ad-download');
    downloadLink.addEventListener('click', () => {
      this.downloadTableAsCSV(`ad-table${i}`);
    });
  }

  downloadTableAsCSV(tableId: string) {
    const table = document.getElementById(tableId);
    if (!table) return;

    let csvContent = '';
    const rows = table.querySelectorAll('tr');

    rows.forEach(row => {
      const cols = row.querySelectorAll('td, th');
      let rowContent = [];
      cols.forEach(col => {
        rowContent.push(col.innerHTML.replace(/(\r\n|\n|\r)/gm, "").trim());
      });
      csvContent += rowContent.join(",") + "\r\n";
    });

    // Create a downloadable link
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('href', url);
    a.setAttribute('download', `campaign_performance_${tableId}.csv`);
    a.click();
  }
}
