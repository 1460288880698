<div >

  <div class="row">
    <div class="col-10" >
      <mat-form-field style="width:90%" autocomplete="off" *ngIf="!showSession">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" autocomplete="off" type="search">
      </mat-form-field>
    </div>
    <div class="col-2" *ngIf="!showSession">
      <div class="cursorPointer" (click)="close()">
        <i class='fas fa-window-close' style='font-size:24px;color:red; float: right;'></i>
      </div>
    </div>

    <div class="col-2" *ngIf="showSession">
      <div class="row">
        <div class="col-4">
          <div class="cursorPointer" (click)="showTheDetails()" *ngIf="!showDetails">
            <i class='fa fa-eye' style='font-size:16px;color:gray; float: right'></i>
          </div>
          <div class="cursorPointer" (click)="showTheDetails()" *ngIf="showDetails">
            <i class='fa fa-eye' style='font-size:16px;color:blue; float: right'></i>
          </div>
        </div>     
        <div class="col-4">
          <div class="cursorPointer" (click)="refresh()">
            <i class='fa fa-refresh' style='font-size:16px;color:green; float: right'></i>
          </div>
        </div>
        <div class="col-4">
          <div class="cursorPointer" (click)="back()">
            <i class='fa fa-arrow-left' style='font-size:16px;color:red; float: right'></i>
          </div>
        </div>
      </div>

  </div>

  </div>

  <div class="row" *ngIf="!showSession">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h2 *ngIf="dataService.currentOrg.org_id !== 'demo'" mat-dialog-title>User Journey - {{dataService.lead.company_name}}</h2>
        <h2 *ngIf="dataService.currentOrg.org_id === 'demo'" mat-dialog-title>User Journey - {{randomService.lastSelectedCompany.name}}</h2>
        <mat-selection-list class="text-center" #items [multiple]="false">
          <div class="row justify-content-between px-5 gx-5">
            <div class="col-2 d-none">Session</div>
            
            <div class="col-3">Page Visits</div>
            <div class="col-3">Session Start</div>
            <div class="col-3">Session End</div>
            <div class="col-3">Journey Details</div>
          </div>
          <hr>
          <mat-list-option class="popRow text-center" *ngFor="let item of $search|async"  [value]="item" [selected]="itemsControl.value &&itemsControl.value?.indexOf(item)>=0" style="height: auto">
            <div class="row justify-content-between">
              <div class="col-2 d-none">{{item['session_id']}}</div>
              
              <div class="col-3">{{item['page_visits']}}</div>
              <div class="col-3">{{item['session_start'] | date: 'M/dd/yyyy h:mm:ss a'}}</div>
              <div class="col-3">{{item['session_end'] | date: 'M/dd/yyyy h:mm:ss a'}}</div>
              <div class="col-3">
                <a (click)="itemSelected(item)" class="content_img" data-toggle="tooltip" title="Session Journey">
                  <i class='fa fa-search' style='font-size:14px;color:gray' ></i>
                </a> 
              </div>
            </div>


          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>
  </div>

  <div class="row" *ngIf="showSession">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h2 *ngIf="dataService.currentOrg.org_id !== 'demo'" mat-dialog-title>User Journey - {{dataService.lead.company_name}} - {{session}}</h2>
        <h2 *ngIf="dataService.currentOrg.org_id === 'demo'" mat-dialog-title>User Journey - {{randomService.lastSelectedCompany.name}} - (Session ID)</h2>
        <mat-selection-list #items2 [multiple]="false">
          <div class="row">
            <div class="col">Page</div>
            <!--<div class="col">URL</div>-->
            <div class="col">Medium Source</div>
            <div class="col">Timestamp</div>

          </div>
          <hr>
          <mat-list-option class="popRow" *ngFor="let item2 of dataService.journey" (click)="itemSelected2(item2)" [value]="item2" [selected]="itemsControl2.value &&itemsControl2.value?.indexOf(item2)>=0" style="height: auto">
            <div class="row mb-3">

              
              <div class="col" *ngIf="dataService.currentOrg.org_id!=='demo'"><i class="fas fa-level-up-alt fa-rotate-90 spacer-r-10"></i>{{item2['page_title']}}</div>
              <!-- <div class="col" *ngIf="dataService.currentOrg.org_id==='demo'"><i class="fas fa-level-up-alt fa-rotate-90 spacer-r-10"></i>Company {{item2['random']}}</div> -->
              <div class="col" *ngIf="dataService.currentOrg.org_id==='demo'"><i class="fas fa-level-up-alt fa-rotate-90 spacer-r-10"></i>{{ randomService.lastSelectedCompany.name }}</div>

              <div class="col">{{item2 | referral}}</div>
              <div class="col">{{item2['timestamp'] | date: 'M/dd/yyyy h:mm:ss a'}}</div>
            </div>
            <div class="row" style="height: auto; min-height: 30px;" *ngIf="showDetails">
              <div class="col spacer-l-20" style="color: gray"><strong>Page:</strong> {{item2['page_url']}}</div>
            </div>  
            <div class="row" style="height: auto; min-height: 30px;" *ngIf="showDetails">
              <div class="col spacer-l-20" style="color: gray"><strong>Referrer:</strong> {{item2.referrer}}</div>
            </div>


          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>
  </div>


</div>
