import { Pipe, PipeTransform } from '@angular/core';
import moment, { Moment } from 'moment';

@Pipe({
  name: 'cleanreferral'
})
export class CleanReferralPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    debugger;
    if (value) {
        var retVal = value.referral_source;
        retVal = retVal.replace('https://','');
        retVal = retVal.replace('http://','');
        retVal = retVal.replace(/\/+$/, '');
        retVal = retVal + "/" + value.referral_medium;
        return retVal;
    }
  }

}
