import { Component, OnInit } from '@angular/core';
import { PageVisits } from '@app/_models';
import { LeadsService } from '@app/_services/leads.service';
import { DataService } from '@app/_services/data.service';
import { AccountService } from '@app/_services/account.service';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { LeadViewQueryOptions } from '@app/_models/lead-view-query-opts';

@Component({
  selector: 'app-page-breakdown',
  templateUrl: './page-breakdown.component.html',
  styleUrls: ['./page-breakdown.component.scss']
})
export class PageBreakdownComponent implements OnInit {

  pageVisits: PageVisits[];
  itemsperpage: number;
  currentpage: number;
  totalrecords: number;

  queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

  constructor(
    private leadsService: LeadsService,
    public dataService: DataService,
    private accountService: AccountService,
    private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    this.itemsperpage = 10;
    this.currentpage = 1;

    this.queryOptions.sortDirection = 'desc';

    this.dataService.loadBreakdown();
//    this.totalrecords = this.dataService.pageViews.length;
    this.dataService.sortBreakdown('view_count');
    

  }

  /*
  loadLeads() {
    this.queryOptions.sortOrder = 'view_count';
    this.queryOptions.sortDirection = 'asc';


    let dataArr = [];

    this.queryOptions.start = new Date(this.dataService.startDate);
    this.queryOptions.end = new Date(this.dataService.endDate);

    this.leadsService.getLeadmetrics(this.dataService.currentOrg.org_id, this.dataService.lead.id, this.queryOptions).pipe(
      map(views => {
        this.totalrecords = views.count;

        //dataArr = views.reports[0].data.rows;
        views.metrics.forEach(element => {
          dataArr.push({
            page: element.page_title, 
            view_count: element.page_count,
            pages_per_visit: element.page_visits,
            avg_duration: element.avgduration,
            new_visits: element.new_visits,
          });
        });


        this.dataService.pageViews = dataArr;
        this.dataService.sortLeads('view_count');
      })
    ).subscribe();

  }


  sortLeads(sortOrder) {
    // is this a new sort order?
    if (this.queryOptions.sortOrder === sortOrder) {
        if (this.queryOptions.sortDirection === 'asc') {
            this.queryOptions.sortDirection = 'desc';
        } else {
            this.queryOptions.sortDirection = 'asc';
        }
    } else {
        this.queryOptions.sortDirection = 'desc';
    }
    this.queryOptions.sortOrder = sortOrder;

    $(`#view_count_sort`).removeClass('btn-sort');
    $(`#pages_per_visit_sort`).removeClass('btn-sort');
    $(`#avg_duration_sort`).removeClass('btn-sort');
    $(`#new_visits_sort`).removeClass('btn-sort');

    $(`#view_count_sort`).removeClass('btn-sort-asc');
    $(`#pages_per_visit_sort`).removeClass('btn-sort-asc');
    $(`#avg_duration_sort`).removeClass('btn-sort-asc');
    $(`#new_vists_sort`).removeClass('btn-sort-asc');

    $(`#view_count_sort`).addClass('btn-hollow');
    $(`#pages_per_visit_sort`).addClass('btn-hollow');
    $(`#avg_duration_sort`).addClass('btn-hollow');
    $(`#new_visits_sort`).addClass('btn-hollow');

    if (this.queryOptions.sortDirection === 'desc') {
        $(`#${sortOrder}_sort`).addClass('btn-sort');
        this.dataService.pageViews.sort((a, b) => (a[sortOrder] < b[sortOrder]) ? 1 : -1)


    } else {
        $(`#${sortOrder}_sort`).addClass('btn-sort-asc');
        this.dataService.pageViews.sort((a, b) => (a[sortOrder] > b[sortOrder]) ? 1 : -1)
    }
    $(`#${sortOrder}_sort`).removeClass('btn-hollow');



}

*/
  handlePageChange(event) {
   // this.queryOptions.page = event;
    //this.queryOptions.pageSize = this.pageSize;

    //this.refreshLeads(this.queryOptions);
    this.currentpage = event;
  }

}
