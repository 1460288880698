import { Action } from '@ngrx/store';
import { Campaign } from '../models/campaign.model';

export enum CampaignActionType {
  ADD_ITEM = '[Campaign] Add campaign',
}

export class AddCampaignAction implements Action {

  readonly type = CampaignActionType.ADD_ITEM;
  constructor(public payload: Campaign) {}

}

export type CampaignAction = AddCampaignAction;