<div class="row">
    <div class="col-lg-12">  
        <div class="card table-card">
            <div id="container"></div>
            <div class="">
                <div class="row">
                    <div class="col">
                        <h3 class="card-title mb-4">LEADS HEAT MAP</h3> 
                    </div>
                    <div class="col-12">
                        <div class="areachart-wrapper dark">
                            <highcharts-chart
                                id="container" 
                                [Highcharts]="dataService.Highcharts"
                                [constructorType]="dataService.chartConstructor"
                                [options]="dataService.chartOptions"
                                style="width: 100%; height: 45vh; display: block;">
                            </highcharts-chart>

                            <!--    <ngx-charts-line-chart
                            [scheme]="colorScheme"
                            [legend]="legend"
                            [legendPosition]="legendPosition"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [gradient]="gradient"
                            [xAxis]="xAxis"
                            [yAxis]="yAxis"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [timeline]="timeline"
                            [results]="multi"
                            (select)="onSelect($event)"
                            (activate)="onActivate($event)"
                            (deactivate)="onDeactivate($event)"
                            
                            >
                            </ngx-charts-line-chart>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


