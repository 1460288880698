
            <div class="row align-items-center mb-4 pt-3 justify-content-between">
                <div class="col-7">
                    <h3 class="card-title">PAGES VIEWED BY COMPANY</h3>
                </div>


                <div class="col-auto">

                    <div class="d-flex align-items-right justify-content-end">
                        <span class="center">Date Range</span>
                        <span class="center">
                            <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                <span>
                                    <input class="no-border" opens="left" type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedPageReferralDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                    [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                </span>
                            </button>
                        </span>
                    </div>
                </div>

                <div class="col-auto" >

                    <div class="input-group">
                        <div class="form-outline">
                            <div class="searchBox">
                                <input type="search" #searchInput id="searchInput" class="form-control btn-light rr_search" placeholder="Search" [(ngModel)]="searchValue" />
                                <div class="search-icon">
                                    <i class="fa fa-search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <table class="table table-borderless"  *ngIf="dataService.pageViews.length > 0">
                <thead>
                    <tr>
                        <td scope="col-4">
                            <a class="sort-btn btn-sort" id="page_sort" (click)="dataService.sortCompanyBreakdown('page')">
                                <span>Page Viewed</span>
                            </a>
                        </td>
                        <td scope="col-2">
                            <a class="sort-btn btn-sort sort-active" id="page_count_sort" (click)="dataService.sortCompanyBreakdown('page_count')">
                                <span>View Count</span>
                            </a>
                        </td>
                        <td scope="col-2">
                            <a class="sort-btn btn-sort" id="avgduration_sort" (click)="dataService.sortCompanyBreakdown('avgduration')">
                                <span>Avg. Visit Duration</span>
                            </a>
                        </td>
                        <td scope="col-2">
                            <a class="sort-btn btn-sort" id="new_visits_sort" (click)="dataService.sortCompanyBreakdown('new_visits')">
                                <span>New Users</span>
                            </a>
                        </td>
                        <td scope="col-2">
                        </td>

                    </tr>
                    <!--<tr>
                        <td scope="col-4">Source/Medium</td>
                        <td scope="col-2" style='font-size:1.6em'>{{dataService.websiteMetrics.users}}</td>
                        <td scope="col-2" style='font-size:1.6em'>{{dataService.websiteMetrics.newusers}}</td>
                        <td scope="col-2" style='font-size:1.6em'>{{dataService.websiteMetrics.sessions}}</td>
                        <td scope="col-2" style='font-size:1.6em'>{{dataService.websiteMetrics.bounces / dataService.websiteMetrics.sessions | percent}}</td>

                    </tr>-->
                </thead>

                <tbody>
                    <ng-container *ngFor="let page of dataService.pageViews | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }; index as i" >
                        <tr (click)="toggleDisplay(page.id, page)" class="highlight-eo-tr" id={{page.id}} >
                            <td *ngIf="page.page" class="col-4">{{page.page}}</td>
                            <td *ngIf="!page.page" class="col-4" style="color: red">- no title -</td>

                            <td class="col-2">
                                {{page.view_count}}
                            </td>

                            <td class="col-2">
                                {{page.avg_duration | duration}}
                            </td>
                            <td class="col-2">
                                {{page.new_visits}}
                            </td>

                            <td class="col-2"><div id="{{page.id}}_arrow" class="arrow-down-2"></div></td>

                        </tr>
                        <tr id="row{{page.id}}" class="session-inner-table" style="display: none">


                        </tr>
                    </ng-container>


                </tbody>

            </table>

            <table class="table table-striped table-borderless"  *ngIf="dataService.pageViews.length === 0">
                <thead>
                    <tr>
                        <td scope="col-12">
                            No data available for those dates
                        </td>

                    </tr>

                </thead>


            </table>

            <div class="cardFoot" *ngIf="dataService.pageViews.length > 0">
                <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
            </div>



