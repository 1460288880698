
<div class="card table-card"  style="height: 100%">
    <div class="row align-items-center justify-content-between mb-5">
        <div class="col-lg-4">
            <h3 class="card-title mb-0">WEBSITE PERFORMANCE SNAPSHOT</h3>
        </div>

        <!-- <div class="col-1"></div> -->
        <div class="col">
            <div class="d-flex align-items-center justify-content-end">
                <div class="d-flex align-items-center flex-wrap">
                    <span>Date range:</span>
                    <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">

                        <span><input id="snapDate1"  (rangeClicked)="rangeClicked($event)" [(ngModel)]="dataService.selectedSnapDates" class="no-border datePickerLeft" type="text" ngxDaterangepickerMd [ranges]="ranges" [showCustomRangeLabel]="true"
                            [alwaysShowCalendars]="true" opens="right" drops="down" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" [showClearButton]="true"  (datesUpdated)="datesUpdated($event)"/></span>

                    </button>
                </div>
                <div class="d-flex align-items-center flex-wrap ms-4">
                    <span>Compared to: </span>

                    <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                            <input [showClearButton]="false" class="no-border datePickerRight" (clearClicked)="clearClicked($event)" [(ngModel)]="dataService.selectedSnapCompareDates"  type="text" ngxDaterangepickerMd [ranges]="ranges" [showCustomRangeLabel]="true"
                                [alwaysShowCalendars]="true" opens="left" drops="down" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" [showClearButton]="true"  (datesUpdated)="compareDatesUpdated($event)"/>

                    </button>
                    <button class="btn" *ngIf="dataService.selectedSnapCompareDates"><mat-icon matDatepickerToggleIcon (click)="clearClicked($event)">clear</mat-icon></button>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="showAdvancedDatePicker">
        <div class="col-lg-12">
            <rr-advanceddatepicker></rr-advanceddatepicker>
        </div>
    </div>
    <div class="row gx-3 mb-4">
        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validUserPercentValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Users" class="{{dataService.snapdata.userspercent>=0 ? 'up' : 'down'}}" body="{{dataService.snapdata.userspercent}}%" footer="{{dataService.snapdata.user_count2 | number}} v {{dataService.snapdata.user_count1 | number}}" footer2="{{dataService.snapdata.user_count2 | number}}" tooltip="Users are website visitors who have initiated one or more sessions in your selected date range. The percentage represents a change in number of users."></app-snaptile>
        </div>
        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validNewUserPercentValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="New Users" class="{{dataService.snapdata.newuserspercent>=0 ? 'up' : 'down'}}" body="{{dataService.snapdata.newuserspercent}}%" footer2="{{dataService.snapdata.new_user_count2 | number}}" footer="{{dataService.snapdata.new_user_count2 | number}} v {{dataService.snapdata.new_user_count1 | number}}" tooltip="New users are first-time visitors to your website in your selected date range. The percentage represents a change in the number of new users."></app-snaptile>
        </div>

        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validSessionCountValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Sessions" class="{{dataService.snapdata.sessionspercent>=0 ? 'up' : 'down'}}" body="{{dataService.snapdata.sessionspercent}}%" footer2="{{dataService.snapdata.session_count2 | number}}" footer="{{dataService.snapdata.session_count2 | number}} v {{dataService.snapdata.session_count1 | number}}" tooltip="A session is a period of time that a user is actively engaging with your website during the selected date range. The percentage represents a change in the number of sessions."></app-snaptile>
        </div>
        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validSessionsPerUserValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Sessions / User" class="{{dataService.snapdata.sessionsperuser>=0 ? 'up' : 'down'}}" body="{{dataService.snapdata.sessionsperuser}}%" footer2="{{dataService.snapdata.sessions_per_user2}}" footer="{{dataService.snapdata.sessions_per_user2}} v {{dataService.snapdata.sessions_per_user1}}" tooltip="Sessions per user is the average session count that a user initiates to your website during the selected date range. The percentage represents a change in the number of sessions per user."></app-snaptile>
        </div>
    </div>

    <div class="row gx-3">
        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validPageCountPercentValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Page Views" class="{{dataService.snapdata.pageviewspercent>=0 ? 'up' : 'down'}}" body="{{dataService.snapdata.pageviewspercent}}%" footer2="{{dataService.snapdata.page_count2  | number}}" footer="{{dataService.snapdata.page_count2 | number}} v {{dataService.snapdata.page_count1 | number}}" tooltip="A page view is an instance of a user visiting one particular page on your website. The percentage represents a change in number of page views during your selected date range."></app-snaptile>
        </div>
        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validPagesPerSessionValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Avg. Pages / Session" class="{{dataService.snapdata.pagespersessionpercent>=0 ? 'up' : 'down'}}" body="{{dataService.snapdata.pagespersessionpercent}}%" footer2="{{dataService.snapdata.pagespersession2}}" footer="{{dataService.snapdata.pagespersession2}} v {{dataService.snapdata.pagespersession1}}" tooltip="Pages per session is the average number of pages a user visits during a session. The percentage represents the change in number of pages per session during your selected date range."></app-snaptile>
        </div>
        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validSessionDurationValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Avg. Session Duration" class="{{dataService.snapdata.sessiondurationpercent>=0 ? 'up' : 'down'}}" body="{{dataService.snapdata.sessiondurationpercent}}%" footer2="{{dataService.snapdata.sessionduration2 | duration2}}" footer="{{dataService.snapdata.sessionduration2 | duration2}} v {{dataService.snapdata.sessionduration1 | duration2}}" tooltip="The average duration represents the average length of time of a session. The percentage is the change in this time."></app-snaptile>
        </div>
        <div class="col-lg-3 dateBoxCol {{ dataService.snapdata.validBounceValue ? 'valid' : 'invalid' }}">
            <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Bounce Rate" class="{{dataService.snapdata.bouncerate>=0 ? 'upred' : 'downgreen'}}" body="{{dataService.snapdata.bouncerate}}%" footer2="{{dataService.snapdata.bounces2}}%" footer="{{dataService.snapdata.bounces2}}% v {{dataService.snapdata.bounces1}}%" tooltip='A "bounce" is a session in which the user does not navigate to other pages outside the page they landed on. The bounce rate is calculated by dividing the number of sessions per page, by the number of total sessions on the website. The percentage represents the change during your selected date range.'></app-snaptile>
        </div>
    </div>

    <div class="cardFoot mt-5 mb-0 pt-0" *ngIf="router.url === '/leads/dashboard'">
        <a class="btn-common btn-wide" routerLink="/leads/website-performance" >View My Website's Performance</a>
    </div>
</div>


