<div class="dashintro">  <!-- Move this to component -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				Campaign Creator
                <p class="campaign-name-title">{{ "Linkedin: " }}</p>
			</div>
		</div>
	</div>
</div>

<div class="card">
    <div class="progress-bar">
        <div class="label-pane font-gray font-italic">
            <span>Linkedin Campaign</span>
            <span>Complete</span>
        </div>
    </div>
    <mat-progress-bar class="setting-step" mode="determinate" value="100"></mat-progress-bar>

    <h4 class="card-title">Campaign Complete Message</h4>

    <label class="font-bold">All set!</label>
    <p class="font-gray">
        You’ve completed your Facebook campaign setup… Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.
    </p>

    <div class="complete-list">
        <div class="item">
            <i class="fas fa-check icon"></i>
            <span>Campaign timeline set</span>
        </div>
        <div class="item">
            <i class="fas fa-check icon"></i>
            <span>Budget added</span>
        </div>
        <div class="item">
            <i class="fas fa-check icon"></i>
            <span>Target company criteria established</span>
        </div>
        <div class="item">
            <i class="fas fa-check icon"></i>
            <span>Ad creative set</span>
        </div>
    </div>

    <label class="font-bold">Next steps...</label>
    <p class="font-gray">
        Once a company that matches the criteria… Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam.
    </p>

    <div class="pending-view">
        <i class="fas fa-triangle-exclamation icon"></i>
        <span>Ad is pending</span>
    </div>

    <div class="btn-container">
        <button class="btn-common btn-save btn-primary" (click)="backToCampaign()">Return</button>
    </div>
</div>
