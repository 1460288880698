import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { environment } from "@environments/environment";
import { AccountService } from "@app/_services";

const saleshomepage = `${environment.saleshomepage}`;

@Injectable({ providedIn: "root" })
export class AuthGuard  {
    constructor(
        private router: Router,
        private accountService: AccountService
    ) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const account = this.accountService.accountValue;
        // debugger;
        if (account) {

            // check if route is restricted by role
            if (route.data.roles && !route.data.roles.includes(account.role)) {
                this.router.navigate(["/"]);
                return false;
            }

            // check if restricted by company admin
            if (route.data.companyadmin && !account.user.companyadmin) {
                this.router.navigate(["/"]);
                return false;
            }

            // authorized so return true
            return true;
        }

        // not logged in so redirect to login page with the return url

        // check if env is dev
        if (environment.production) {
            window.location.href = `${saleshomepage}/login`;
        } else {
            this.router.navigate(["/account/login"], { queryParams: { returnUrl: state.url }});
        }



        // this.router.navigate(["/account/login"], { queryParams: { returnUrl: state.url }});
        // window.location.href = `${saleshomepage}/login`;

        return false;
    }
}
