// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiUrl: 'https://reportroverapi.carrollsolutions.com', //(was localhost:4000)
  //apiUrl: 'https://apidev.reportrover.com',
  apiUrl: 'https://reportroverapi.carrollsolutions.com',
  //apiUrl: 'https://api.reportrover.com',
  // apiUrl: 'http://localhost:8087',
  baseDevUrl: 'https://reportroverapi.carrollsolutions.com',
  baseLeadUrlPrefix: 'https://reportroverapi.carrollsolutions.com/tracker/org/',
  //baseLeadUrlSuffix: '/reports/leads'*/
  version: 'Version 1.1',
  message: 'You are currently in the development environment',
  homepage: 'https://fcdev.reportrover.com',
  saleshomepage: 'http://salesdev.reportrover.com',
  salesdashboard: 'http://salesdev.reportrover.com/dashboard',
  saleslogin: 'http://salesdev.reportrover.com/login',
  // cookiedomain: 'http://localhost:4200',
  cookiedomain: 'https://carrollsolutions.com',
  googleClientId: '243130902856-d63ul82ea4u3n3ikldd0mkortedm9341.apps.googleusercontent.com',
  linkedinClientId: '86ae4ncabs6cqi',
  linkedinClientSecret: 'FYPUYJboXxvPTYZZ',
};

//export const GOOGLE_APPLICATION_CREDENTIALS = "../../../credentials.json";

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
