<div class="modal fade notification-modal" tabindex="-1" role="dialog" [ngClass]="{'show': isNotificationOpen}" *ngIf="isNotificationOpen">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <a class="close-btn" aria-label="Close" (click)="closeModal()">
                <span>Close</span><i class="icon-close fas fa-xmark"></i>
            </a>
            <div class="modal-title">
                <span class="title">Notifications</span>
                <div class="mark-all-btn" (click)="markAllAsRead()">Mark all as read</div>
            </div>
            <div class="modal-info">
                <div class="notification-card" [ngClass]="{'unread': !item.isRead}" *ngFor="let item of notifications" (click)="markAsRead(item)">
                    <div class="main-pane">
                        <div class="date-label">{{ item.date }}</div>
                        <div class="content">{{ item.content }}</div>
                        <b class="type">{{ item.type }}</b>
                    </div>
                    <div class="unread-pane" *ngIf="!item.isRead">
                        <div class="unread-mark"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>