import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { CampaignService } from '@app/_services';
import { Observable } from 'rxjs';
import { take, takeUntil, first, map } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { LinkedinAd } from '@app/store/models/linkedinAd.model';
import { AppState } from '@app/store/models/state.model';
import { AddLinkedinAdAction } from '@app/store/actions/linkedinAds.actions';
import { Campaign } from '@app/store/models/campaign.model';

@Component({
  selector: 'app-authorization-modal',
  templateUrl: './authorization-modal.component.html',
  styleUrls: ['./authorization-modal.component.scss']
})

export class AuthorizationModalComponent {
  @Input() isAuthOpen: boolean;
  @Input() isStartOpen: boolean;
  @Input() platformId: string;

  @Output() isAuthOpenChange = new EventEmitter<boolean>();
  @Output() isStartOpenChange = new EventEmitter<boolean>();

  linkedinAds$: Observable<LinkedinAd>;

  isStartModalOpen: boolean = false;

  pages: Array<string> = [
    'https://fifthcolor.com/',
    'https://reportrover.com/'
  ];
  selectedPage: string = this.pages[0];

  timeOptions: Array<number> = [30, 60, 90, 120, 0];
  selectedTime: number = 0;

  promoteSettings: Array<string> = ['Credit', 'Employment', 'Housing', 'Social issues, elections, or politics', 'None'];
  selectedPromoteSetting: string = this.promoteSettings[0];

  sendSettings: Array<number> = [0,1,2];
  selectedSetting: number = 0;

  campaignName: string = '';
  pageUrl: string = '';

  color: ThemePalette = "primary";

  isNameRequired: boolean = false;

  dailyBudget: number = 0;

  constructor(
    private campaignService: CampaignService,
    private store: Store<AppState>
  ) {
    store.pipe(select('linkedinAds')).subscribe((data: LinkedinAd) => {
      this.campaignName = data.name;
      this.pageUrl = data.pageUrl;
      this.selectedTime = data.timeframe;
      this.selectedSetting = data.adPreference;
      this.platformId = data.platformid;
    });

    store.pipe(select('campaigns')).subscribe((data: Campaign) => {
      this.dailyBudget = data.dailyBudget;
    });
  }

  closeModal() {
    this.isAuthOpenChange.emit(false);
    this.isStartOpenChange.emit(false);
  }

  openStartModal() {
    this.isAuthOpenChange.emit(false);
    this.isStartOpenChange.emit(true);
  }

  startCampaign() {
    const campaignName = document.getElementById('campaign_name') as HTMLInputElement;

    if (campaignName.value === '') {
      this.isNameRequired = true;
      campaignName.focus();
    } else {
      this.campaignService.startCampaign(this.platformId, this.campaignName, this.pageUrl, 'active', this.selectedTime, this.selectedSetting)
        .pipe(first())
        .subscribe({
          next: (res: string) => {
            const formData = {
              "accessCode": localStorage.getItem('linkedin-access-code'),
              "id": res['insertId'],
              "timeframe": this.selectedTime,
              "dailyBudget": this.dailyBudget,
              "name": this.campaignName,
              "platformId": this.platformId
            };

            this.campaignService.createLinkedinCampaign(formData)
              .pipe(first())
              .subscribe({
                next: (res) => {
                },
                error: () => {
                  console.log('Error');
                }
              });

            this.store.dispatch(new AddLinkedinAdAction({
              id: res['insertId'],
              name: this.campaignName,
              pageUrl: this.pageUrl,
              timeframe: this.selectedTime,
              adPreference: this.selectedSetting,
              platformid: this.platformId
            }));
            this.isStartOpenChange.emit(false);
          },
          error: () => {
            console.log('Error');
          }
        });
    }
  }

  changePage(page: string) {
    this.selectedPage = page;
  }

  changeTimeOption(option: number) {
    this.selectedTime = option;
  }

  changePromoteSetting(option: string) {
    this.selectedPromoteSetting = option;
  }

  changeSetting(option: number) {
    this.selectedSetting = option;
  }

  onChangeHandler(event) {
    if (event.target.value === '') {
      this.isNameRequired = true;
    } else {
      this.isNameRequired = false;
    }
  }
}
