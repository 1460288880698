import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap, retry } from "rxjs/operators";
import { Observable, of, throwError } from "rxjs";
import { CompanyLeads, LeadBreakdown } from "@app/_models";
import { LeadViewQueryOptions } from "@app/_models";
import { DateRangeService } from "./daterange.service";
import { CompanyInfo } from "@app/_models";
import { environment } from "@environments/environment";

const baseUrl = `${environment.apiUrl}`;

@Injectable({
    providedIn: "root",
})


export class LeadsService {

    private url = "";// ${baseUrl}/tracker/org/quasius_construction/reports/individualleads';

    httpOptions = {
        headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    constructor(private httpClient: HttpClient, private dateRangeService: DateRangeService) { }

    getLeadBreakdown(id: number): Observable<LeadBreakdown> {
        // get the details of the selected lead

        return null;
    }

    getLeads(queryOpts: LeadViewQueryOptions, org: string): Observable<CompanyLeads> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/individualleads?`);

        this.url = (this.url + "limit=" + queryOpts.pageSize + "&" + "page=" + queryOpts.page + "&sortorder=" + queryOpts.sortOrder + "&sortdirection=" + queryOpts.sortDirection + "&includeisps=" + queryOpts.includeISPs);

        // this.url = (this.url + 'page=' + queryOpts.page + '&sortorder=' + queryOpts.sortOrder + '&sortdirection=' + queryOpts.sortDirection);

        /* if we have a start and end date then append to url*/
        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }

        // console.log('getLeads(): ' + this.url);

        return this.httpClient
            .get<CompanyLeads>(this.url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    // console.error(error);
                    return throwError(error);
                    // ensures that we complete gracefully to avoid memory leaks
                    // should probably go to a helper method for more solid logging
                })
            );
    }

    getAllLeads(queryOpts: LeadViewQueryOptions, org: string): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/allleaddata?`);

        /* if we have a start and end date then append to url*/
        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }

        // console.log('getLeads(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe(
                retry(2),
                catchError((error: HttpErrorResponse) => {
                    // console.error(error);
                    return throwError(error);
                    // ensures that we complete gracefully to avoid memory leaks
                    // should probably go to a helper method for more solid logging
                })
            );
    }


    getLead(companyid: number): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/company/${companyid}`);

        // console.log('getLeads(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

     private resetUrl(): void {
         this.url = `${baseUrl}`;
     }

     saveCompanyInfo(companyId: number, companyInfo: any): any {
        // console.log(JSON.stringify(companyInfo));

        this.url = (`${baseUrl}/tracker/company/${companyId}`);

        // console.log('updateLead(): ' + this.url);

        return this.httpClient
            .put<any>(this.url, companyInfo)
            .pipe();
    }


    getSnapshot(org: string, startdate1, enddate1, startdate2, enddate2, includeISPs): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/snapshot?startdate1=${startdate1}&enddate1=${enddate1}&startdate2=${startdate2}&enddate2=${enddate2}&includeisps=${includeISPs}`);

        // console.log('getSnapshot(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getLeadmetrics(org: string, companyid, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/leadmetrics?companyid=${companyid}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getLeadMetrics(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getOrgmetrics(org: string,  queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/orgmetrics?`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getOrgMetrics(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getOrgmetricsByPage(org: string,  page: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/orgmetricsbypage?`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }

        this.url = this.url + "&pagetitle=" + page;




        // console.log('getOrgMetrics(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getLeadmetricsByDate(org: string, companyid, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/leadmetricsbydate?companyid=${companyid}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getLeadMetricsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getOrgmetricsByDate(org: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/orgmetricsbydate?`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getOrgMetricsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    // sessons for date range and company
    getSessionsJourney(org, company, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/sessionsjourney?companyid=${company}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }


    // sessons for date range and company
    getSourceMedium(org, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/sourcemedium?`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }

        // console.log('getReferralsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    // sessons for date range and company
    getWebsiteMetrics(org, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/websitemetrics?`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }

        // console.log('getReferralsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    // sessons for date range and company
    getEventsBySession(sessionId): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/any/reports/eventsbysession/session/${sessionId}`);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getReferralsByDate(org: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/referralsbydate?`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getReferralsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getAllReferralsByDate(org: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/allreferralsbydate?`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getReferralsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getReferralsByDateAndPage(org: string, page:string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/referralsbydateandpage?pagetitle=${page}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getReferralsByDateAndPage(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getCompaniesJourney(org: string, pageurl:string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/companyjourney?pageurl=${pageurl}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getCompaniesJourney(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getCompanyJourneyBreakdown(org: string, pageurl:string, companyid: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/companyjourneybreakdown?pageurl=${pageurl}&companyid=${companyid}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getCompanyJourneyBreakdown(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }


    getSessionsByDate(org, companyid, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/sessionsbydate?companyid=${companyid}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getSessionsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }


    getJourney(org, sessionid): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/sessionjourney?sessionid=${sessionid}`);


        // console.log('getJourney(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }


    getContinentsByDate(org: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/continentsreport?includeisps=${queryOpts.includeISPs}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getContinentsByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getIndustriesByDate(org: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/industriesreport?includeisps=${queryOpts.includeISPs}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getIndustriesByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }


    getRevenueByDate(org: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/revenuereport?includeisps=${queryOpts.includeISPs}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getRevenueByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }

    getEmployeesByDate(org: string, queryOpts: LeadViewQueryOptions): Observable<any> {

        this.resetUrl();

        this.url = (`${baseUrl}/tracker/org/${org}/reports/employeesreport?includeisps=${queryOpts.includeISPs}`);

        if(typeof queryOpts.start !== "undefined") {
            if(typeof queryOpts.end === "undefined") {
                // require an end date
                return;
            } else {
                this.url =
                (
                    this.url +
                        "&startdate=" +
                    this.dateRangeService.parseDate(queryOpts.start) +
                        "&enddate=" +
                    this.dateRangeService.parseDate(queryOpts.end)
                );
            }
        }




        // console.log('getEmployeesByDate(): ' + this.url);

        return this.httpClient
            .get<any>(this.url)
            .pipe();
    }
}
