import { LinkedinAd } from '../models/linkedinAd.model';
import { LinkedinAdAction, LinkedinAdActionType } from '../actions/linkedinAds.actions';

const initialState: LinkedinAd = {
    id: 0,
    name: '',
    pageUrl: '',
    timeframe: 0,
    adPreference: 0,
    platformid: ''
};

export function LinkedinAdReducer(
    state: LinkedinAd = initialState,
    action: LinkedinAdAction
) {
    switch (action.type) {
        case LinkedinAdActionType.ADD_ITEM:
            return action.payload;
        case LinkedinAdActionType.REMOVE_ITEM:
            return initialState;
        default:
            return state;
    }
}