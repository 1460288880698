import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { VirtualScrollService, GridComponent, EditService, ToolbarService, ExcelExportProperties } from '@syncfusion/ej2-angular-grids';
//import { datasource, virtualData } from './data';
import { LeadViewQueryOptions } from '../../_models';
import { LeadsService } from '../../_services/leads.service';
import { DataService } from '@app/_services';
import { takeUntil, map } from 'rxjs/operators';
import moment, { Moment } from 'moment';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';

@Component({
    selector: 'ej2-gridvirtual',
    templateUrl: 'simpledownload.html',
    styleUrls: ['simpledownload.style.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [VirtualScrollService, EditService, ToolbarService]
})
export class SimpleDownloadComponent implements OnInit {
    queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();



    //public vData: Object[] = virtualData;
    public vData: Object[] = [];
    @ViewChild('grid') public grid: GridComponent;
    public date1: number;
    public date2: number;
    public flag: boolean = true;
    public toolbar: string[] = [];

    public editSettings: Object = { allowEditing: false, allowDeleting: false , newRowPosition: 'Top' };;

    ranges: any = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [
        moment()
          .subtract(1, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ],
      'Last 3 Month': [
        moment()
          .subtract(3, 'month')
          .startOf('month'),
        moment()
          .subtract(1, 'month')
          .endOf('month')
      ]
  };

    constructor(
      private leadsService: LeadsService,
      public dataService: DataService
    ) {


  }

    public ngOnInit(): void {
      this.toolbar = ['ExcelExport'];
      debugger;
      //if (!this.dataService.startAllDataDate) {

        this.dataService.startAllDataDate = moment().subtract(6, 'days');
        this.dataService.endAllDataDate = moment();

        this.dataService.selectedAllDataDates = { startDate: this.dataService.startAllDataDate, endDate: this.dataService.endAllDataDate };
      //}


      this.queryOptions.page = 1;
      this.queryOptions.pageSize = 10000;
      this.queryOptions.sortDirection = 'desc';
      this.queryOptions.includeISPs = false;

      //this.queryOptions.start = new Date(this.dataService.startDate);
      //this.queryOptions.start = new Date(2020,1,1);
      //this.queryOptions.end = new Date(2030,1,1);

      this.loadLeads();

    }






    onClick = (args: any) => {
        this.show();
        if (!this.vData.length) {
             //datasource();
            this.grid.dataSource = this.dataService.allLeads;
            //this.grid.dataSource = this.vData;
            this.grid.editSettings.allowAdding= true;
        }
        this.flag = true;
        this.date1 = new Date().getTime();
        this.grid.refresh();
    }
    show(): void {
        document.getElementById('popup').style.display = 'inline-block';
    }
    hide(): void {
        if (this.flag && this.date1) {
            this.date2 = new Date().getTime();
            //document.getElementById('performanceTime').innerHTML = 'Time Taken: ' + (this.date2 - this.date1) + 'ms';
            this.flag = false;
        }
        document.getElementById('popup').style.display = 'none';
    }

    toolbarClick(args: ClickEventArgs): void {
      debugger;
      if (args.item.id === 'Grid_excelexport') { // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
          const excelExportProperties: ExcelExportProperties = {
              exportType: 'AllPages'
          };
          this.grid.excelExport(excelExportProperties);
      }
  }

    loadLeads() {

      this.queryOptions.start = new Date(this.dataService.startAllDataDate);
      this.queryOptions.end = new Date(this.dataService.endAllDataDate);


      this.leadsService.getAllLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
        map(all => {
          console.log(all);


          var counter = 0;
          var date1;
          var date2;
          var oldSession = "";

          // loop through all items to determine the duration
          all.report.forEach(element => {
              counter++;

              if (oldSession === '') {
                oldSession = element.session_id;
              }

              const tdate = new Date(element.timestamp);

              element.timestamp =  moment(tdate).format('MMM D, YYYY, h:mm:ss A');

              var value2: any;

              if (all.report[counter] && all.report[counter].session_id === oldSession)  {

                date1 = new Date(all.report[counter].timestamp);
                date2 = new Date(element.timestamp);

                value2 = (date1- date2)/1000;
              }  else {
                oldSession = "";
                date1 = new Date(element.session_end);
                date2 = new Date(element.timestamp);

                value2 = (date1- date2)/1000;
              }

              value2 = value2*1000;

              var hoursamt = moment.utc(value2).format("HH");
              var hours = moment.utc(value2).format("H") + " hrs";

              var minutesamt = moment.utc(value2).format("mm");
              var minutes = moment.utc(value2).format("m") + " mins";

              var secondsamt = moment.utc(value2).format("ss");
              var seconds = moment.utc(value2).format("s") + " secs";

              element.duration = `${hoursamt!='00'?hours:''} ${minutesamt!='00'?minutes:''} ${secondsamt!='00'?seconds:''}`

              element.timestamp = new Date(element.timestamp);

          });

          this.dataService.allLeads = all.report;

       //   datasource();
          this.grid.dataSource = this.dataService.allLeads;
          //this.grid.dataSource = this.vData;
          this.grid.editSettings.allowAdding= true;
          //this.dataService.allLeadsOrig = Array.from(this.dataService.allLeads);
          //this.dataService.allLeads.sort((a, b) => (a.sort > b.sort) ? 1 : -1)
        }),


      ).subscribe();


    }


    datesUpdated(event) {
      //console.log({ event });
      debugger;
      if (!event.startDate) {

//            this.dataService.startDate = moment().subtract(6, 'days');
//          this.dataService.endDate = moment();

      } else {
          this.dataService.startAllDataDate = event.startDate;
          this.dataService.endAllDataDate = event.endDate;

      }

      this.dataService.selectedAllDataDates = { startDate: this.dataService.startAllDataDate, endDate: this.dataService.endAllDataDate };

      localStorage.setItem('startAllDataDate', this.dataService.startAllDataDate);
      localStorage.setItem('endAllDataDate', this.dataService.endAllDataDate);


      this.queryOptions.start = new Date(this.dataService.startAllDataDate);
      this.queryOptions.end = new Date(this.dataService.endAllDataDate);

      this.queryOptions.sortOrder = 'page_count';
      this.queryOptions.sortDirection = 'desc';

      this.queryOptions.page = 1;
      this.queryOptions.pageSize = 5;



      this.loadLeads();

//        localStorage.setItem('selectedDates', this.selectedDates);

  }
}
