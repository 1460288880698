<div class="dashintro">  <!-- Move this to component -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				Campaign Creator
                <p class="campaign-name-title">{{ "Linkedin: " }}</p>
			</div>
		</div>
	</div>
</div>

<div class="card">
    <div class="progress-bar">
        <div class="label-pane font-gray font-italic">
            <span>Linkedin Campaign</span>
            <span>Steps: 2 of 3</span>
        </div>
    </div>
    <mat-progress-bar class="setting-step" mode="determinate" value="67"></mat-progress-bar>

    <h4 class="card-title">Review & Save</h4>

    <div class="ad-preview review-pane">
        <label class="font-bold">Ad Preview</label>
        <div class="preview-container">
            <div class="logo-panel">
                <img [src]="logo ? logo : '/assets/images/default_logo.png'" alt="Your Logo">
                <div class="company-info">
                    <p class="company-name font-bold">{{companyName ? companyName : 'Company name'}}</p>
                    <p class="follower-count font-gray">{{followerCount}} followers</p>
                    <p class="status font-gray">Promoted</p>
                </div>
            </div>
            <p class="intro-text-preview font-bold">{{introText ? introText : 'Intro text and caption goes here!'}}</p>
            <img [src]="imageSrc" alt="Preview Image" class="content-image">
            <div class="headline-pane">
                <div>
                    <p class="headline-preview font-bold">{{headlineText ? headlineText : 'Headline Goes Here'}}</p>
                    <p class="destination-preview font-gray">{{destinationUrl}}</p>
                </div>
                <a class="btn-common btn-default btn-learn-more">Learn more</a>
            </div>
        </div>
    </div>

    <div class="campaign-preview review-pane">
        <div class="icon-title">
            <label class="font-bold font-gray">Campaign Criteria</label>
            <button (click)="editCustomSetting()" style="border: unset;">
                <i class="fas fa-gear font-gray"></i>
            </button>
        </div>

        <div class="sub-pane">
            <label class="title font-bold">Company Revenue</label>
            <p *ngFor="let item of revenues">{{ item }}</p>
        </div>

        <div class="sub-pane">
            <label class="title font-bold">Number of Employees</label>
            <p *ngFor="let item of employees">{{ item }}</p>
        </div>

        <div class="sub-pane">
            <label class="title font-bold">Industry</label>
            <p *ngFor="let item of selectedIndustries">{{ item['name'] }}</p>
        </div>
    </div>

    <div class="budget-preview review-pane">
        <div class="icon-title">
            <label class="font-bold font-gray">Budget</label>
            <i class="fas fa-gear font-gray"></i>
        </div>

        <div class="sub-pane">
            <label class="title font-bold">LinkedIn maximum daily budget</label>
            <p>{{ dailyBudget }}</p>
        </div>

        <div class="sub-pane">
            <label class="title font-bold">Threshold amount</label>
            <p>{{ threshold }}</p>
        </div>

        <div class="sub-pane">
            <label class="title font-bold">Campaign timeline</label>
            <p>{{ timeframe === 0 ? 'Continuous' : timeframe + ' days' }}</p>
        </div>
    </div>

    <div class="review-footer">
        <p>Send ad for approval?</p>
        <div class="btn-container">
            <button class="btn-common btn-back btn-default" (click)="backToFirstStep()">Edit</button>
            <button class="btn-common btn-save btn-primary" (click)="createLinkedinAd()">Send</button>
        </div>
        <p class="font-italic font-light-gray">Disclaimer</p>
    </div>
</div>
