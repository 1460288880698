import { Component, OnInit , Input} from "@angular/core";
import { LeadsService } from "../../_services/leads.service";
import { AccountService } from "@app/_services";
import { DataService } from "@app/_services";
import { CompanyLeads } from "../../_models";
import { LeadViewQueryOptions } from "@app/_models/lead-view-query-opts";
import { MatDialog } from "@angular/material/dialog";
import { LeadSortPipe } from "@app/lead-sort.pipe";
import { Observable, Subject } from "rxjs";
import { takeUntil, map } from "rxjs/operators";
import { Router, ActivatedRoute } from "@angular/router";
import moment, { Moment } from "moment";
import { MatCheckboxChange} from "@angular/material/checkbox";
import { JourneyComponent } from "@app/_components/journey.component";
import { RandomCompanyService } from "@app/random-company.service";
import { RandomCompany } from "@app/random-company";

@Component({
    templateUrl: "dashboard.component.html",
    providers: [LeadsService, LeadSortPipe]
})


export class DashboardComponent implements OnInit {
    loading = false;
    account = this.accountService.accountValue;
    // currentOrg = Observable<this.accountService.accountValue.currentorg>;
    companyLeads: CompanyLeads;
    title: string;
    timestamp: string;
    datestamp: string;
    companyLeads$: Observable<CompanyLeads>;
    lastSelectedCompany: RandomCompany;
    randomCompanies: RandomCompany[];
    rCompany: RandomCompany;

    queryOptions: LeadViewQueryOptions = new LeadViewQueryOptions();

    private leadSub = new Subject();

    ranges: any = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month")
        ],
        "Last 3 Month": [
          moment()
            .subtract(3, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month")
        ]
    };

    constructor(private accountService: AccountService,
            public leadsService: LeadsService,
            public dataService: DataService,
            private route: ActivatedRoute,
            public randomService: RandomCompanyService,
            private router: Router,
            public dialog: MatDialog
        ) {
        this.queryOptions.page = 1;
        this.queryOptions.pageSize = 5;
        this.queryOptions.sortDirection = "desc";


    }

    ngOnInit(): void {
      this.dataService.startDate = localStorage.getItem("startDate");
      this.dataService.endDate =   localStorage.getItem("endDate");

        if (!this.dataService.startDate) {

            this.dataService.startDate = moment().subtract(6, "days");
            this.dataService.endDate = moment();

            this.dataService.selectedDates = { startDate: this.dataService.startDate, endDate: this.dataService.endDate };
        }

        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);
        this.dataService.queryOptions.includeISPs = false;

        var term: string;
        var objValue: string;

        this.dataService.loadLeads();

        /* async */
/*
        document.getElementById('searchInput').addEventListener('input', (e) => {
                this.dataService.companyLeads.results = Array.from(this.dataService.companyLeadsOrig);
                this.dataService.companyLeads.results = this.dataService.companyLeads.results.filter((obj)=>{
                    return Object.keys(obj).reduce((acc, curr)=>{
                        term = e.currentTarget['value'].toLowerCase();

                        if (!obj[curr]) {
                            obj[curr] = '';
                        }

                        if (curr === 'page_count' || curr === 'session_count') {
                            objValue = obj[curr].toString();
                            return acc || objValue.includes(term);
                        } else if (curr === 'isisp' || curr === 'company_id') {
                            return acc;
                        } else {
                            objValue = obj[curr].toLowerCase();
                            return acc || objValue.includes(term);
                        }

                    }, false);
                });


       })
       */

    }

    ngOnDestroy(): void {        this.leadSub.next();
        this.leadSub.complete();
    }

    sortLeads(sortOrder) {
        // is this a new sort order?
        if (this.queryOptions.sortOrder === sortOrder) {
            if (this.queryOptions.sortDirection === "asc") {
                this.queryOptions.sortDirection = "desc";
            } else {
                this.queryOptions.sortDirection = "asc";
            }
        } else {
            this.queryOptions.sortDirection = "desc";
        }
        this.queryOptions.sortOrder = sortOrder;
/*
        $(`#company_employees_sort`).removeClass('btn-sort');
        $(`#company_revenue_sort`).removeClass('btn-sort');
        $(`#page_count_sort`).removeClass('btn-sort');
        $(`#session_count_sort`).removeClass('btn-sort');

        $(`#company_employees_sort`).removeClass('btn-sort-asc');
        $(`#company_revenue_sort`).removeClass('btn-sort-asc');
        $(`#page_count_sort`).removeClass('btn-sort-asc');
        $(`#session_count_sort`).removeClass('btn-sort-asc');

        // $(`#company_employees_sort`).addClass('btn-hollow');
        // $(`#company_revenue_sort`).addClass('btn-hollow');
        // $(`#page_count_sort`).addClass('btn-hollow');
        // $(`#session_count_sort`).addClass('btn-hollow');
        $('.sort-active').removeClass('sort-active');

        if (this.queryOptions.sortDirection === 'desc') {
            $(`#${sortOrder}_sort`).addClass('btn-sort');
            $(`#${sortOrder}_sort`).addClass('sort-active');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) < parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] < b[sortOrder]) ? 1 : -1)
            }

        } else {
            $(`#${sortOrder}_sort`).addClass('btn-sort-asc');
            $(`#${sortOrder}_sort`).addClass('sort-active');
            if (sortOrder==='company_employees' || sortOrder==='company_revenue') {
              this.dataService.companyLeads.results.sort((a, b) => (parseInt(a[sortOrder].split(" ")[0].replace(",","")) > parseInt(b[sortOrder].split(" ")[0].replace(",",""))) ? 1 : -1)
            } else {
              this.dataService.companyLeads.results.sort((a, b) => (a[sortOrder] > b[sortOrder]) ? 1 : -1)
            }
        }
        $(`#${sortOrder}_sort`).removeClass('btn-hollow');

*/

    }

    async leadBreakdown(lead) {
        /* Get the full lead */
        this.leadsService.getLead(lead.company_id).subscribe(fulllead => {
            this.dataService.lead = fulllead.results;

            localStorage.setItem("lead", JSON.stringify(this.dataService.lead));
            this.router.navigate(["/leads/lead-breakdown"], { relativeTo: this.route });
        });
    }

    async leadBreakdownDemo(lead, cName) {
        this.leadsService.getLead(lead.company_id).subscribe(fulllead => {
            this.dataService.lead = fulllead.results;
            // this.dataService.lead.company_id = cName;
            this.dataService.lead.name = cName;
            this.router.navigate(["/leads/lead-breakdown"], { relativeTo: this.route });
        });
    }

    datesUpdated(event) {
        // console.log({ event });

        if (!event.startDate) {

        } else {
            this.dataService.startDate = event.startDate;
            this.dataService.endDate = event.endDate;

          this.dataService.selectedDates = { startDate: this.dataService.startDate, endDate: this.dataService.endDate };

          localStorage.setItem("startDate", this.dataService.startDate);
          localStorage.setItem("endDate", this.dataService.endDate);


          this.queryOptions.start = new Date(this.dataService.startDate);
          this.queryOptions.end = new Date(this.dataService.endDate);

          this.queryOptions.sortOrder = "page_count";
          this.queryOptions.sortDirection = "desc";

          this.queryOptions.page = 1;
          this.queryOptions.pageSize = 5;

          this.queryOptions.includeISPs = this.dataService.includeISPs;

          this.leadsService.getLeads(this.queryOptions, this.dataService.currentOrg.org_id).pipe(
              map(leads => {
                      this.dataService.companyLeads = leads;

                  }
              ),
              takeUntil(this.leadSub)
          ).subscribe();


          this.dataService.loadMetrics();

          this.dataService.setPageReferralDates(event);


          // update source/medium
          this.dataService.setReferralDates(event);



          // set referral chart
          this.dataService.setSourceMediumDates(event);


          this.dataService.setSnapDates(event, true);
      }


//        localStorage.setItem('selectedDates', this.selectedDates);

    }

    markISP(obj: MatCheckboxChange) {
        if (obj.checked) {
            this.dataService.includeISPs = true;
        } else {
            this.dataService.includeISPs = false;
        }

        this.dataService.loadLeads();
        this.dataService.loadIndustryMetrics();
        this.dataService.loadSnap();


      }

      async showJourney(lead, lastRandomCompany) {
        this.loading = true;
        this.dataService.lead = lead;

        this.rCompany = lastRandomCompany;
        console.log(lastRandomCompany);

        let dataArr = [];

        this.queryOptions.start = new Date(this.dataService.startDate);
        this.queryOptions.end = new Date(this.dataService.endDate);

        await this.leadsService.getSessionsByDate(this.dataService.currentOrg.org_id, lead.company_id, this.queryOptions).pipe(
          map(results => {

            this.dataService.sessions = results.sessions;

            this.loading = false;
            this.dataService.dialogRef = this.dialog.open(JourneyComponent, {
                height: "90%",
                width: "98%",
            });

          })
        ).subscribe();




          this.dataService.dialogRef.afterClosed().subscribe(result => {

          });

        }

}
