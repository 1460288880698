
<div class="card table-card"  style="height: 100%">
  <div class="row align-items-center justify-content-between mb-5">
      <div class="col-lg-4">
          <h3 class="card-title mb-0">ADS ACCOUNT PREVIEW</h3>
      </div>

      <!-- <div class="col-1"></div> -->
      <div class="col">
          <div class="d-flex align-items-center justify-content-end">
              <div class="d-flex align-items-center flex-wrap">
                  <span>Date range:</span>
                  <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">

                      <span><input id="snapDate1"  (rangeClicked)="rangeClicked($event)" [(ngModel)]="dataService.selectedSnapDates" class="no-border datePickerLeft" type="text" ngxDaterangepickerMd [ranges]="ranges" [showCustomRangeLabel]="true"
                          [alwaysShowCalendars]="true" opens="right" drops="down" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" [showClearButton]="true"  (datesUpdated)="datesUpdated($event)"/></span>

                  </button>
              </div>
              <div class="d-flex align-items-center flex-wrap ms-4">
                  <span>Compared to: </span>

                  <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                          <input [showClearButton]="false" class="no-border datePickerRight" (clearClicked)="clearClicked($event)" [(ngModel)]="dataService.selectedSnapCompareDates"  type="text" ngxDaterangepickerMd [ranges]="ranges" [showCustomRangeLabel]="true"
                              [alwaysShowCalendars]="true" opens="left" drops="down" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" [showClearButton]="true"  (datesUpdated)="compareDatesUpdated($event)"/>

                  </button>
                  <button class="btn" *ngIf="dataService.selectedSnapCompareDates"><mat-icon matDatepickerToggleIcon (click)="clearClicked($event)">clear</mat-icon></button>
              </div>
          </div>
      </div>
  </div>
  <div class="row" *ngIf="showAdvancedDatePicker">
      <div class="col-lg-12">
          <rr-advanceddatepicker></rr-advanceddatepicker>
      </div>
  </div>
  <div class="row gx-3 mb-4">
      <p class="sub-title">DATA ACROSS ACCOUNTS</p>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Impressions" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Clicks" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Average Click-Through-Rate" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Cost" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>

      <div class="col dateBoxCol valid">
          <app-snaptile [compareDates]="dataService.selectedSnapCompareDates" title="Average Cost Per Click" class="up" body="0.00%" footer="000 v 000" footer2="000" tooltip="tooltip here..."></app-snaptile>
      </div>
  </div>

  <div class="cardFoot mb-0 pt-0" *ngIf="router.url === '/leads/dashboard'">
      <a class="btn-common btn-wide" routerLink="/leads/campaign-performance" >View Campaign Performance</a>
  </div>

  <div class="mb-4">
      <app-linkedinperformance></app-linkedinperformance>
      <app-metaperformance></app-metaperformance>
      <app-googleperformance></app-googleperformance>
  </div>
</div>
