<div >

  <div class="row">
    <div class="col-8">
      <mat-form-field style="width:90%" autocomplete="off">
        <mat-label>Search</mat-label>
        <input matInput [formControl]="search" autocomplete="off" type="search">
      </mat-form-field>
    </div>
    <div class="col-4">
      <i class='fas fa-window-close' style='font-size:24px;color:red; float: right;' (click)="close()"></i>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <mat-dialog-content class="mat-typography">
        <h2 mat-dialog-title>Referrals - {{dataService.pageTitle}}</h2>
        <mat-selection-list #items [multiple]="false" (selectionChange)="selectionChange($event.option)">
          <div class="row">
            <div class="col-6">Company</div>
            <div class="col-3">Duration</div>
            <div class="col-3">Date/Time</div>
          </div>
          <hr>
          <mat-list-option *ngFor="let item of $search|async" (click)="itemSelected(item)" [value]="item" [selected]="itemsControl.value &&itemsControl.value?.indexOf(item)>=0" style="height: auto">
            <div class="row" >
              <!--<div class="col-6">
                  {{item['page_url'] | slice:0:75}}{{item['page_url'].length > 75 ? "..." : ""}} 
                  <br>
                  <b>Referer - </b> {{item['referrer'] | slice:0:75}}{{item['referrer'].length > 75 ? "..." : ""}}
              </div>-->
              <div class="col-6">{{item['name']}}</div>
              <div class="col-3">{{item['duration'] | duration}}</div>
              <div class="col-3">{{item['timestamp'] | date: 'M/dd/yyyy h:mm:ss a'}}</div>
            </div>
            <div class="row" >
              <div class="col-12">
              <b>Page: </b> {{item['page_url']}}
              </div>
            </div>
            <div class="row" >
              <div class="col-12">
              <b>Referer: </b> {{item['referrer']}}
              </div>
            </div>
            <div class="row" style="height: 20px">
              
            </div>
            <div class="row" >
              <hr>
            </div>
          </mat-list-option>
        </mat-selection-list>
      </mat-dialog-content>
    </div>
  </div>

</div>
