import { Lead } from './';

export class CompanyLeads{
    count: number;
    limit: number;
    page: number;
    totalPages: number;
    offset: number;
    results: [
        Lead
    ]
}