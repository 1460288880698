<div class="row">
    <div class="col-lg-12">
        <div class="card table-card">
            <table class="table table-striped table-borderless">
                <thead>
                    <tr>
                        <td scope="col">Page</td>
                        <td scope="col">
                            <a class="sort-btn btn-sort" id="view_count_sort" (click)="dataService.sortBreakdown('view_count')">
                                <span>View Count</span>
                            </a>
                        </td>
                        <td scope="col">
                            <a class="sort-btn btn-sort" id="pages_per_visit_sort" (click)="dataService.sortBreakdown('pages_per_visit')">
                                <span>Pages Per Visit</span>
                            </a>
                        </td>
                        <td scope="col">
                            <a class="sort-btn btn-sort" id="avg_duration_sort" (click)="dataService.sortBreakdown('avg_duration')">
                                <span>Avg. Visit Duration</span>
                            </a>
                        </td>
                        <td scope="col">
                            <a class="sort-btn btn-sort" id="new_visits_sort" (click)="dataService.sortBreakdown('new_visits')">
                                <span>New Visits</span>
                            </a>
                        </td>
                        <td scope="col">Go To Page</td>
                    </tr>
                </thead>
                
                <tbody>
                    <tr *ngFor="let page of dataService.pageViews | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords }">
                        <td>
                            {{page.page}}
                        </td>
                        <td>
                            {{page.view_count}}
                        </td>
                        <td>
                            {{page.pages_per_visit}}
                        </td>
                        <td>
                            {{page.avg_duration | duration}}
                        </td>
                        <td>
                            {{page.new_visits}}
                        </td>
                        <td>
                            <a href="{{page.page_url}}" target="_blank"><img class="moreicon" src="/assets/icons/RR_Link_Icon_Light_BluePurple.svg" alt="view more"></a>
                        </td>
                    </tr>
                    
                <!--<tr>
                    <td>
                        Page
                    </td>
                    <td>
                        Count
                    </td>
                    <td>
                        PPV
                    </td>
                    <td>
                        Duration
                    </td>
                    <td>
                        New
                    </td>
                    <td>
                        Go To
                    </td>
                </tr>-->
                </tbody>
            </table>
           <!-- <div class="cardFoot">
                <pagination-controls previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
            </div>-->
        </div>
    </div>
</div>