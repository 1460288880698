import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';

import { RandomCompany } from './random-company';
import { RandomCompanies } from './random-companies';


@Injectable({
  providedIn: 'root'
})
export class RandomCompanyService {

companyNames: RandomCompany[] = RandomCompanies;

  lastSelectedCompany: RandomCompany;

  constructor() {
    this.pickRandomCompany();
  }
  
  pickRandomCompany(){
    //generate a random number from 0 - companyNameFinalIndex
    //set lastSelectedCompany = the selected company
    //Math.Random() * (max - min) + min; --> Generates a number >= min and < max 
    let min = Math.ceil(0);
    let max = Math.floor(this.companyNames.length);
    let pickedNum = Math.floor(Math.random() * (max - min) + min); //0's here for illustrative purposes
    this.lastSelectedCompany = this.companyNames[pickedNum];
  }

  getRandomCompany(): RandomCompany{
    return this.lastSelectedCompany;
  }

}
