import { AccountService } from '@app/_services';

export function appInitializer(accountService: AccountService) {
    return () => new Promise(resolve => {
        // attempt to refresh token on app start up to auto authenticate

        // need to check the route in case this is a reset password link
        if (location.hash.split('?')[0] ===  "#/account/reset-password") {
            resolve(null);
        } else if (location.hash.split('?')[0].startsWith("#/account/verify-email")) {
            resolve(null);            
        } else if (location.hash.split('?')[0].startsWith("#/account/accept-invite")) {
                resolve(null);
        } else {


            accountService.refreshToken()
                .subscribe()
                .add(resolve);
        }
    });
}