<div class="row h-100">
        <div class="col-lg-12">
            <div class="card table-card"  style="height: 100%">
                <div id="container"></div>
                <div class="row align-items-right justify-content-between">
                        <div class="col-auto">
                                <h3 class="card-title mb-4">REFERRAL SOURCE</h3>
                        </div>
                </div>
                <div class="row align-items-center justify-content-between">
                        <div class="col-auto">
                                Date Range:
                        </div>
                        <div class="col-9">
                                <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                <span>
                                        <input class="no-border" opens="left" type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedSourceMediumDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                        [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                </span>
                                </button>
                        </div>
                </div>
                <div class="row align-items-right justify-content-between">
                        <div class="col-lg-12 center spacer-t-10 wp-referralchart">
                                <highcharts-chart [Highcharts]="Highcharts" [options]="dataService.chartOptionsReferrals"  [oneToOne]="true"
                                                style="margin-top:0;overflow: hidden; width: 100%;">
                                </highcharts-chart>
                        </div>
                </div>
        </div>
</div>


