<div class="dashintro">  <!-- Move this to component -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				Campaign Performance
			</div>
		</div>
	</div>
</div>

<div class="container-fluid">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="row align-items-center mb-4 justify-content-between">
                    <div class="col-3">
                        <h3 class="card-title mb-0">CAMPAIGNS</h3>
                    </div>

                    <div class="col-9">
                        <div class="d-flex align-items justify-content-end">
                            <div class="input-group search-input">
                                <div class="form-outline searchBox">
                                    <input type="search" id="searchInput2" class="form-control btn-light rr_search" placeholder="Search Campaign or Company..." [(ngModel)]="searchValue" />
                                    <div class="search-icon">
                                        <i class="fas fa-search"></i>
                                    </div>
                                </div>
                            </div>

                            <button class="btn-common btn-add ms-4">+ Add a campaign</button>
                        </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-between mt-4">
                        <div class="d-flex align-items-center justify-content-left logo-top">
                            <div class="logo-item">
                                <img src="/assets/icons/RR_Linkedin_Icon.svg" alt="Linkedin Logo" class="linkedin-logo">
                            </div>
                            <div class="logo-item">
                                <img src="/assets/icons/RR_Meta_Icon.svg" alt="Meta Logo" class="meta-logo">
                            </div>
                            <div class="logo-item">
                                <img src="/assets/icons/Google.svg" alt="Google Logo" class="google-logo">
                            </div>
                        </div>
                        <div class="d-flex justify-content-end top-option">
                            <div class="d-flex align-items-center status-campaign-pane">
                                <span class="center">Status of campaign</span>
                                <span class="center ms-3">
                                    <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                        <mat-select placeholder="Select" class="status-select">
                                            <mat-option value="all">
                                                <mat-radio-button class="radio-button" [color]="color">
                                                    All campaigns
                                                </mat-radio-button>
                                            </mat-option>
                                            <mat-option value="active">
                                                <mat-radio-button class="radio-button" [color]="color">
                                                    Active
                                                </mat-radio-button>
                                            </mat-option>
                                            <mat-option value="needs">
                                                <mat-radio-button class="radio-button" [color]="color">
                                                    Needs Attention
                                                </mat-radio-button>
                                            </mat-option>
                                            <mat-option value="completed">
                                                <mat-radio-button class="radio-button" [color]="color">
                                                    Completed
                                                </mat-radio-button>
                                            </mat-option>
                                            <mat-option value="off">
                                                <mat-radio-button class="radio-button" [color]="color">
                                                    Off
                                                </mat-radio-button>
                                            </mat-option>
                                        </mat-select>
                                    </button>
                                </span>
                            </div>
                            <div class="d-flex align-items-center">
                                <span class="center">Date Range</span>
                                <span class="center">
                                    <button class="aDDbtn btn btn-common btn-white  btn-simple-date" type="button" id="dropdownMenuButton1">
                                        <span>
                                            <input class="no-border" opens="left"type="text" ngxDaterangepickerMd [(ngModel)]="dataService.selectedDates" (datesUpdated)="datesUpdated($event)" [ranges]="ranges" [showCustomRangeLabel]="true"
                                            [alwaysShowCalendars]="true" [keepCalendarOpeningWithRange]="true" [autoApply]="false" [showCancel]="true" />
                                        </span>
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
			<div class="card table-card">
                <div class="row mb-4">
                    <div class="col-6">
                        <img src="/assets/icons/RR_Linkedin_Icon.svg" alt="Linkedin Logo" />
                    </div>

                    <div class="col-6">
                        <div class="d-flex align-items-right justify-content-end">
                            <button class="btn-common btn-add ms-4">+ Add a campaign</button>
                        </div>
                    </div>

                </div>

                <div class="row" style="height: 10px"></div>

                <table class="table table-responsive highlight-eo stickyths">

                    <thead>
                        <tr>
                            <td scope="col" class="col-3">
                                <div class="d-flex align-items-center justify-content-between">
                                    <span>Campaign or Name of Page</span>
                                    <span>On/Off Status</span>
                                </div>
                            </td>
                            <td scope="col" class="col-1">Status</td>
                            <td scope="col" class="col-1">Impressions</td>
                            <td scope="col" class="col-1">Companies in Target</td>
                            <td scope="col" class="col-1">Clicks</td>
                            <td scope="col" class="col-1">Click Through Rate (CTR) %</td>
                            <td scope="col" class="col-1">Daily Budget</td>
                            <td scope="col" class="col-1">Spent (Cost)</td>
                            <td scope="col" class="col-1">Avg. Cost Per Click</td>
                            <td scope="col" class="col-1">End Date</td>
                            <td scope="col" class="col-1"></td>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <ng-container *ngFor="let session of dataService.sessions | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords } ; index as i" > -->
                        <ng-container>
                            <tr (click)="toggleDisplay(1)" id="parent1" class="highlight-eo-tr">
                                <td class="col-3">
                                    <div class="company-name">
                                        <span>Page name/campaign name</span>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" id="switch-1" type="checkbox" [(ngModel)]="isCompanyActive">
                                        </div>
                                    </div>
                                </td>
                                <td class="col-1">
                                    <div class="status">
                                        <div class="{{isCompanyActive ? 'status-active' : 'status-off'}}"></div>
                                        <span>{{isCompanyActive ? 'Active' : 'Off'}}</span>
                                    </div>
                                </td>
                                <td class="col-1">00,000</td>
                                <td class="col-1">000</td>
                                <td class="col-1">00,000</td>
                                <td class="col-1">00%</td>
                                <td class="col-1 font-bold">$000.00</td>
                                <td class="col-1">
                                    <span class="font-bold">$000.00</span>
                                    <br/>
                                    <span class="font-small">of $000.00</span>
                                </td>
                                <td class="col-1 font-bold">$000.00</td>
                                <td class="col-1">00/00/0000</td>
                                <td class="col-1 text-right">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <i class="fas fa-gear font-grey me-3"></i>
                                        <i class="fas fa-arrow-up-right-from-square  font-grey me-3"></i>
                                        <div id="1_arrow" class="arrow-down"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="row1" class="highlight-eo-tr session-inner-table" style="display: none">
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div class="row mt-4 align-items-right justify-content-end">
                    <div class="col-12">
                        <pagination-controls  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="card table-card">
                <div class="row mb-4">
                    <div class="col-6">
                        <img src="/assets/icons/RR_Meta_Icon.svg" alt="Meta Logo" />
                    </div>

                    <div class="col-6">
                        <div class="d-flex align-items-right justify-content-end">
                            <button class="btn-common btn-add ms-4">+ Add a campaign</button>
                        </div>
                    </div>

                </div>

                <div class="row" style="height: 10px"></div>

                <table class="table table-responsive highlight-eo stickyths">

                    <thead>
                        <tr>
                            <td scope="col" class="col-3">
                                <div class="d-flex align-items-center justify-content-between">
                                    <span>Campaign or Name of Page</span>
                                    <span>On/Off Status</span>
                                </div>
                            </td>
                            <td scope="col" class="col-1">Status</td>
                            <td scope="col" class="col-1">Impressions</td>
                            <td scope="col" class="col-1">Companies in Target</td>
                            <td scope="col" class="col-1">Clicks</td>
                            <td scope="col" class="col-1">Click Through Rate (CTR) %</td>
                            <td scope="col" class="col-1">Daily Budget</td>
                            <td scope="col" class="col-1">Spent (Cost)</td>
                            <td scope="col" class="col-1">Avg. Cost Per Click</td>
                            <td scope="col" class="col-1">End Date</td>
                            <td scope="col" class="col-1"></td>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <ng-container *ngFor="let session of dataService.sessions | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords } ; index as i" > -->
                        <ng-container>
                            <tr (click)="toggleDisplay(2)" id="parent2" class="highlight-eo-tr">
                                <td class="col-3">
                                    <div class="company-name">
                                        <span>Page name/campaign name</span>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" id="switch-2" type="checkbox" [(ngModel)]="isCompanyActive">
                                        </div>
                                    </div>
                                </td>
                                <td class="col-1">
                                    <div class="status">
                                        <div class="{{isCompanyActive ? 'status-active' : 'status-off'}}"></div>
                                        <span>{{isCompanyActive ? 'Active' : 'Off'}}</span>
                                    </div>
                                </td>
                                <td class="col-1">00,000</td>
                                <td class="col-1">000</td>
                                <td class="col-1">00,000</td>
                                <td class="col-1">00%</td>
                                <td class="col-1 font-bold">$000.00</td>
                                <td class="col-1">
                                    <span class="font-bold">$000.00</span>
                                    <br/>
                                    <span class="font-small">of $000.00</span>
                                </td>
                                <td class="col-1 font-bold">$000.00</td>
                                <td class="col-1">00/00/0000</td>
                                <td class="col-1 text-right">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <i class="fas fa-gear font-grey me-3"></i>
                                        <i class="fas fa-arrow-up-right-from-square  font-grey me-3"></i>
                                        <div id="2_arrow" class="arrow-down"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="row2" class="highlight-eo-tr session-inner-table" style="display: none">
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div class="row mt-4 align-items-right justify-content-end">
                    <div class="col-12">
                        <pagination-controls  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                    </div>
                </div>
            </div>
            <div class="card table-card">
                <div class="row mb-4">
                    <div class="col-6">
                        <img src="/assets/icons/Google.svg" alt="Google Logo" />
                    </div>

                    <div class="col-6">
                        <div class="d-flex align-items-right justify-content-end">
                            <button class="btn-common btn-add ms-4">+ Add a campaign</button>
                        </div>
                    </div>

                </div>

                <div class="row" style="height: 10px"></div>

                <table class="table table-responsive highlight-eo stickyths">

                    <thead>
                        <tr>
                            <td scope="col" class="col-3">
                                <div class="d-flex align-items-center justify-content-between">
                                    <span>Campaign or Name of Page</span>
                                    <span>On/Off Status</span>
                                </div>
                            </td>
                            <td scope="col" class="col-1">Status</td>
                            <td scope="col" class="col-1">Impressions</td>
                            <td scope="col" class="col-1">Companies in Target</td>
                            <td scope="col" class="col-1">Clicks</td>
                            <td scope="col" class="col-1">Click Through Rate (CTR) %</td>
                            <td scope="col" class="col-1">Daily Budget</td>
                            <td scope="col" class="col-1">Spent (Cost)</td>
                            <td scope="col" class="col-1">Avg. Cost Per Click</td>
                            <td scope="col" class="col-1">End Date</td>
                            <td scope="col" class="col-1"></td>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <ng-container *ngFor="let session of dataService.sessions | paginate: { itemsPerPage: this.itemsperpage, currentPage: currentpage, totalItems: totalrecords } ; index as i" > -->
                        <ng-container>
                            <tr (click)="toggleDisplay(3)" id="parent3" class="highlight-eo-tr">
                                <td class="col-3">
                                    <div class="company-name">
                                        <span>Page name/campaign name</span>
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" id="switch-3" type="checkbox" [(ngModel)]="isCompanyActive">
                                        </div>
                                    </div>
                                </td>
                                <td class="col-1">
                                    <div class="status">
                                        <div class="{{isCompanyActive ? 'status-active' : 'status-off'}}"></div>
                                        <span>{{isCompanyActive ? 'Active' : 'Off'}}</span>
                                    </div>
                                </td>
                                <td class="col-1">00,000</td>
                                <td class="col-1">000</td>
                                <td class="col-1">00,000</td>
                                <td class="col-1">00%</td>
                                <td class="col-1 font-bold">$000.00</td>
                                <td class="col-1">
                                    <span class="font-bold">$000.00</span>
                                    <br/>
                                    <span class="font-small">of $000.00</span>
                                </td>
                                <td class="col-1 font-bold">$000.00</td>
                                <td class="col-1">00/00/0000</td>
                                <td class="col-1 text-right">
                                    <div class="d-flex align-items-center justify-content-end">
                                        <i class="fas fa-gear font-grey me-3"></i>
                                        <i class="fas fa-arrow-up-right-from-square  font-grey me-3"></i>
                                        <div id="3_arrow" class="arrow-down"></div>
                                    </div>
                                </td>
                            </tr>
                            <tr id="row3" class="highlight-eo-tr session-inner-table" style="display: none">
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <div class="row mt-4 align-items-right justify-content-end">
                    <div class="col-12">
                        <pagination-controls  previousLabel="Prev" nextLabel="Next" responsive="true" (pageChange)="handlePageChange($event)"></pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
