import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-budget-modal',
  templateUrl: './budget-modal.component.html',
  styleUrls: ['./budget-modal.component.scss']
})
export class BudgetModalComponent {
  @Input() isBudgetModalOpen: boolean;
  @Output() isBudgetModalOpenChange = new EventEmitter<boolean>();

  constructor() {}

  closeModal() {
    this.isBudgetModalOpenChange.emit(false);
  }
}
