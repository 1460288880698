import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LinkedinAd } from '@app/store/models/linkedinAd.model';
import { AppState } from '@app/store/models/state.model';
import { RemoveLinkedinAdAction } from '@app/store/actions/linkedinAds.actions';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ad-creator-complete',
  templateUrl: './ad-creator-complete.component.html',
  styleUrls: ['./ad-creator-complete.component.scss']
})
export class AdCreatorCompleteComponent {
  linkedinAds$: Observable<LinkedinAd>;

  constructor (
    private router: Router,
    private store: Store<AppState>
  ) {}

  backToCampaign() {
    this.store.dispatch(new RemoveLinkedinAdAction());

    this.router.navigate(["/leads/campaign-creator"]);
  }
}
