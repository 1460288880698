<div class="row">
        <div class="col-lg-12">  
            <div class="card table-card"  style="height:100%">
                <div id="container"></div>
                <div class="row align-items-right justify-content-between">
                        <div class="col-auto">
                                <h3 class="card-title">LEADS BY {{chartType}}</h3> 
                        </div>
                        <div class="col-auto">
                                <mat-checkbox *ngIf="tab === 0" [checked]="dataService.top5only" (change)="top5()" disabled="{{dataService.industryCount<6}}">Show top 5 only</mat-checkbox>
                                <mat-checkbox *ngIf="tab === 1" [checked]="dataService.top5revenueonly" (change)="top5revenue()" disabled="{{dataService.revenueCount<6}}">Show top 5 only</mat-checkbox>
                                <mat-checkbox *ngIf="tab === 2" [checked]="dataService.top5employeeonly" (change)="top5employee()" disabled="{{dataService.employeeCount<6}}">Show top 5 only</mat-checkbox>
                        </div>
                </div>  

                <div class="row align-items-right justify-content-between">
                        <div class="col-lg-12 center spacer-t-10">
                                <mat-tab-group mat-align-tabs="center" (selectedTabChange)="selectedTabValue($event)">
                                        <mat-tab label="Industry Breakdown">
                                                <highcharts-chart [Highcharts]="Highcharts" [options]="dataService.chartOptionsIndustry"  [oneToOne]="true"  
                                                                style="height: 42vh; margin-top:0;overflow: hidden !important;">
                                                                </highcharts-chart>
                                        </mat-tab>      
                                        <mat-tab label="Revenue Breakdown">
                                                <highcharts-chart [Highcharts]="Highcharts" [options]="dataService.chartOptionsRevenue"  [oneToOne]="true"  
                                                style="height: 42vh; margin-top:0;overflow: hidden !important;">
                                                </highcharts-chart>

                                        </mat-tab>
                                        <mat-tab label="Employees Breakdown">
                                                <highcharts-chart [Highcharts]="Highcharts" [options]="dataService.chartOptionsEmployees"  [oneToOne]="true"  
                                                style="height: 42vh; margin-top:0;overflow: hidden !important;">
                                                </highcharts-chart>
                                        </mat-tab>
                                </mat-tab-group>
                        </div>
                </div>
        </div>
</div>

<!--
                <mat-tab-group mat-align-tabs="center">
                        <mat-tab label="Industry Breakdown">
                                <div class="row">
                                        <div class="col-lg-12 center spacer-t-10">
                                                <mat-checkbox [checked]="dataService.top5only" (change)="top5()" disabled="{{dataService.industryCount<6}}">Show top 5 only</mat-checkbox>
                                        </div>
                                </div>
                                <div class="row">
                                        <div class="col" style="margin-bottom: 10px;">
                                                <highcharts-chart [Highcharts]="Highcharts" [options]="dataService.chartOptionsIndustry"  [oneToOne]="true"  
                                                style="width: calc(100% ); height: calc(100% - 52px); display: block;margin-top:0;overflow: hidden !important;">
                                                </highcharts-chart>
                                        </div>
                                </div>
                        </mat-tab>
                        <mat-tab label="Revenue Breakdown">
                                <div class="col-lg-12 center spacer-t-10">
                                        <mat-checkbox [checked]="dataService.top5revenueonly" (change)="top5revenue()" disabled="{{dataService.revenueCount<6}}">Show top 5 only</mat-checkbox>
                                </div>                
                                <div class="row" style="margin-bottom: 10px;">
                                        <highcharts-chart [Highcharts]="Highcharts" [options]="dataService.chartOptionsRevenue"  [oneToOne]="true"  
                                                style="width: calc(100% ); height: calc(100% - 52px); display: block;margin-top:0;overflow: auto !important;">
                                        </highcharts-chart>
                                </div>
                        </mat-tab>
                        <mat-tab label="Employees Breakdown">
                                <div class="col-lg-12 center spacer-t-10">
                                        <mat-checkbox [checked]="dataService.top5employeeonly" (change)="top5employee()" disabled="{{dataService.employeeCount<6}}">Show top 5 only</mat-checkbox>
                                </div>                
                                <div class="row" style="margin-bottom: 10px;">
                                        <highcharts-chart [Highcharts]="Highcharts" [options]="dataService.chartOptionsEmployees"  [oneToOne]="true"  
                                                style="width: calc(100% ); height: calc(100% - 52px); display: block;margin-top:0;overflow: auto !important;">
                                        </highcharts-chart>
                                </div>
                        </mat-tab>
                </mat-tab-group>
        </div>
</div>

-->

