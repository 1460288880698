import { Component, OnInit, Input } from "@angular/core";
import { CompanyInfo } from "@app/_models";
import { DataService } from "@app/_services";
import { CookieService } from "ngx-cookie-service";
import { AccountService } from "@app/_services";

@Component({
  selector: "app-website-performance",
  templateUrl: "./website-performance.component.html"
})
export class WebsitePerformanceComponent implements OnInit {
  public companyInfo: CompanyInfo;
  account = this.accountService.accountValue;


  constructor(
    private accountService: AccountService,
    public dataService: DataService,
    private cookieService: CookieService

  ) {


  }

  ngOnInit(): void {
  }

}
